import React from 'react';
import {Flex, Form, Input, Spin, Tag, Col, Row} from "antd";
import MessageMethods from "../methods/message.methods";
import dayjs from "dayjs";
import {useLocation} from "react-router-dom";
import {dateFormat} from "./messages.component";
import T from "./common/t";
import {observer} from "mobx-react-lite";
import ReactQuill from "react-quill";
import {toolbarOptions} from "./web/oneWeb.component";
import {UserMethods} from "../methods/user.methods";

const MessagesComponent = () => {
    const [loading, setLoading] = React.useState(true);
    const [isRead, setIsRead] = React.useState(false);
    const location = useLocation();
    const [form] = Form.useForm();

    React.useEffect(() => {
        MessageMethods.readMessages(location.pathname.split('/').at(-1)).then(
            (res) => {
                if (res) {
                    MessageMethods.getMessage(location.pathname.split('/').at(-1))
                        .then(res => {

                            form.setFieldsValue({
                                ...res.data,
                                createdAt: dayjs(res.data.createdAt).format(dateFormat),
                                updatedAt: dayjs(res.data.updatedAt).format(dateFormat)
                            });
                            UserMethods.getUserById(res.data.fromId).then(res => {
                                form.setFieldsValue({
                                    fromId: res.data.email,
                                });
                                setIsRead(() => res.data.isRead)
                            })
                            UserMethods.getUserById(res.data.toId).then(res => {
                                form.setFieldsValue({
                                    toId: res.data.email,
                                });
                                setIsRead(() => res.data.isRead)
                            })
                            setIsRead(() => res.data.isRead)
                        })
                }
            }
        ).finally(() => setLoading(false));
    }, [form, location.pathname])


    return (
        loading
            ?
            <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                <Spin size="large" />
            </Flex>
            :
            <div style={{padding: '16px'}}>
                <Form layout="vertical" form={form}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label={<T>Отправлено</T>} key={"createdAt"} name={"createdAt"}>
                                <Input style={{color: "#242424"}} disabled size={"large"}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={<T>Прочитано</T>} name={"createdAt"}>
                                <Input style={{color: "#242424"}} disabled size={"large"}/>
                            </Form.Item>
                        </Col>
                        <Col span={8} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Tag color={isRead ? "green" : "volcano"}>
                                {isRead ? <T>ПРОЧИТАНО</T> : <T>НЕ ПРОЧИТАНО</T>}
                            </Tag>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label={<T>От кого</T>} name={"fromId"}>
                                <Input style={{color: "#242424"}} disabled size={"large"}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={<T>Кому</T>} name={"toId"}>
                                <Input style={{color: "#242424"}} disabled size={"large"}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={<T>Тема</T>} name={"theme"}>
                                <Input style={{color: "#242424"}} disabled size={"large"}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="message" label={<T>Сообщение</T>}>
                        <ReactQuill
                            style={{width: '100%', maxWidth: '100%', marginBottom: 0, marginTop: '1rem'}}
                            theme="snow"
                            modules={
                                {
                                    toolbar: toolbarOptions
                                }
                            }
                        />
                    </Form.Item>
                </Form>
            </div>

    );
};

export default observer(MessagesComponent);