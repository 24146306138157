import React from 'react';
import MainComponent from "../components/common/main.component";
import DevicesComponent from "../components/devices.component";
import DeviceMethods from "../methods/device.methods";
import {Flex, notification, Spin} from "antd";
import {useAuth} from "../hooks/useAuth";
import T from "../components/common/t";
import {observer} from "mobx-react-lite";
import AllComponent from "../components/common/all.component";

const DevicesPage = () => {
    const [services, setServices] = React.useState([]);
    const [servicesTuya, setServicesTuya] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [api, contextHolder] = notification.useNotification();
    const user = useAuth();

    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    const syncDevices = async () => {
        setLoading(true);
        if (user) {
            const [ajax, tuya] = await Promise.allSettled([
                DeviceMethods.getServices('ajax'),
                DeviceMethods.getServices('tuya')
            ])
            if (ajax.status === 'fulfilled' && tuya.status === 'fulfilled') {
                setServices(Object.values(ajax.value.data));
                setServicesTuya(Object.values(tuya.value.data));
            } else {
                openNotificationWithIcon('error', ajax.reason || tuya.reason, [ajax.status || tuya.status])
            }
        }
    }

    React.useEffect(() => {
        if (user) {
            for (const service of services) {
                DeviceMethods.syncDevices('ajax', {userId: service.userId})
                    .then(() => {
                        openNotificationWithIcon('success', <T>Devices synced successfully</T>, null)
                    })
                    .catch((error) => {
                        openNotificationWithIcon('error', error?.response?.data?.message, error?.response?.data?.errors)
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            }
            for (const serviceTuya of servicesTuya) {
                DeviceMethods.syncDevices('tuya', {clientId: serviceTuya.clientId})
                    .then(() => {
                        openNotificationWithIcon('success', <T>Devices synced successfully</T>, null)
                    })
                    .catch((error) => {
                        openNotificationWithIcon('error', error?.response?.data?.message, error?.response?.data?.errors)
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            }
        }
    }, [services, servicesTuya, user]);

    return (
        <AllComponent>
            {contextHolder}
            <MainComponent title={<T>Devices</T>} showModal={syncDevices} showButton={true}>
                {
                    loading
                        ?
                        <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                            <Spin size="large" />
                        </Flex>
                        :
                        <DevicesComponent />
                }

            </MainComponent>
        </AllComponent>
    );
};

export default observer(DevicesPage);