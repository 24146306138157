import React from 'react';
import MainComponent from "../components/common/main.component";
import DashboardComponent from "../components/dashboard.component";
import T from "../components/common/t";
import {observer} from "mobx-react-lite";
import AllComponent from "../components/common/all.component";

const DashboardPage = () => {
    return (
        <AllComponent>
            <MainComponent title={<T>Dashboard</T>} showButton={false}>
                <DashboardComponent />
            </MainComponent>
        </AllComponent>
    );
};

export default observer(DashboardPage);