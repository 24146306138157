import {$host} from "../http/axios";

export default class SliderMethods {
    static addSlider = async (formData) => {
        const {data} = await $host.post(`/slider/add`, formData);
        return data;
    }
    static getOneSlider = async (id) => {
        const {data} = await $host.post(`/slider/get/${id}`);
        return data;
    }

    static getAll = async () => {
        const {data} = await $host.post(`/slider/all?limit=1000000000`);
        return data;
    }

    static updateSlider= async (id, formData) => {
        const {data} = await $host.post(`/slider/update/${id}`, formData);
        return data;
    }

    static deleteSlider = async (id) => {
        const {data} = await $host.post(`/slider/delete/${id}`);
        return data;
    }
}