import React from 'react';
import RoomsComponent from "../components/rooms.component";
import MainComponent from "../components/common/main.component";
import {Card, Checkbox, DatePicker, Divider, Flex, Form, Input, Modal, notification, Select, Space, Spin} from "antd";
import SectorMethods from "../methods/sector.methods";
import {UserMethods} from "../methods/user.methods";
import RoomMethods from "../methods/room.methods";
import AllComponent from "../components/common/all.component";

const RoomsPage = () => {
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [sectors, setSectors] = React.useState({});
    const [users, setUsers] = React.useState({});
    const [checkbox, setCheckbox] = React.useState(false);
    const [dateRent, setDateRent] = React.useState("");
    const [userId, setUserId] = React.useState("");

    const [form] = Form.useForm();

    React.useEffect(() => {
        (async () => {
            const [sectors, users] = await Promise.allSettled([
                SectorMethods.getSectors(),
                UserMethods.getUsers()
            ])
            if (sectors.status === "fulfilled" && users.status === "fulfilled") {
                setSectors(sectors.value.data);
                setUsers(users.value.data);
            }
        })()
    }, [])

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} is required!',
        types: {
            // eslint-disable-next-line
            email: '${label} is not a valid email!',
            // eslint-disable-next-line
            tel: '${label} is not a valid phone number!',
        },
    };

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setLoadingForm(true);
        form.setFieldsValue("");
        RoomMethods.addRoom({...form.getFieldsValue(), isRented: checkbox})
            .then(async (response) => {
                if (checkbox) {
                    await RoomMethods.startRent(Object.values(response.data)[0].id, {date: dateRent, userId: userId})
                }
                form.resetFields();
                openNotificationWithIcon('success', 'Данные успешно добавлены', []);
                setTimeout(() => {
                    setIsModalOpen(false);
                    setLoadingForm(false);
                }, 1000);
            })
            .catch(error => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
        setCheckbox(false);
    };

    const toggleCheckbox = () => {
        if (checkbox) {
            setCheckbox(false);
        } else {
            setCheckbox(true);
        }
    }

    const handleDateChange = (e) => {
        setDateRent(() => new Date(e));
    }

    const toggleSelect = (e) => {
        setUserId(() => e);
    }

    return (
        <AllComponent>
            <MainComponent title={"Rooms"} showModal={showModal} showButton={true}>
                <RoomsComponent />
                <Modal
                    title={'Add new room'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                >
                    {contextHolder}
                    {
                        loadingForm
                            ?
                            <Flex justify="center" align="center" style={{height: '176px'}}>
                                <Spin size="large" />
                            </Flex>
                            :

                            <Form
                                style={{ width: '100%'}}
                                form={form}
                                multipart={"form-data"}
                                layout="vertical"
                                validateMessages={validateMessages}
                            >
                                <Form.Item
                                    hasFeedback
                                    label="Название"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input size={"large"} />
                                </Form.Item>
                                <Form.Item
                                    hasFeedback
                                    label="Статус"
                                    name="isRented"
                                >
                                    <Card>
                                        <Space size={"middle"} direction={"vertical"} style={{width: '100%'}}>
                                            <Checkbox size={"large"} checked={checkbox} onChange={toggleCheckbox}>{checkbox ? 'Арендовано' : 'Свободно'}</Checkbox>
                                            {
                                                checkbox &&
                                                <>
                                                    <Divider>Данные по аренде</Divider>
                                                    <Space size={"middle"} align="start">
                                                        <Form.Item
                                                            hasFeedback
                                                            style={{marginBottom: 0}}
                                                            label="Начало аренды"
                                                            name="date"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}
                                                        >
                                                            <DatePicker size={"large"} onChange={handleDateChange}/>
                                                        </Form.Item>

                                                        <Form.Item
                                                            style={{marginBottom: 0}}
                                                            hasFeedback
                                                            label="Арендатор"
                                                            name="userId"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                size={"large"}
                                                                placeholder="Выберите арендатора"
                                                                options={
                                                                    Object.values(users).map(user => ({
                                                                        label: user.name,
                                                                        value: user.id
                                                                    }))
                                                                }
                                                                onChange={toggleSelect}
                                                            />
                                                        </Form.Item>
                                                    </Space>
                                                </>
                                            }
                                        </Space>
                                    </Card>
                                </Form.Item>
                                <Form.Item
                                    hasFeedback
                                    label="Сектор"
                                    name="sectorId"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select size={"large"} placeholder={"Выберите сектор"} options={Object.values(sectors).map(sector => ({
                                        label: sector.name,
                                        value: sector.id
                                    }))}/>
                                </Form.Item>
                            </Form>
                    }
                </Modal>
            </MainComponent>
        </AllComponent>
    );
};

export default RoomsPage;