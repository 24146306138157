import React from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    Button,
    Flex,
    Form,
    Input,
    notification,
    Spin,
    Select,
    Tabs,
    DatePicker,
    Space,
    Checkbox,
    Empty,
    Modal, Typography, Divider, Tag, Card, Tooltip
} from "antd";
import LocationMethods from "../methods/location.methods";
import RoomMethods from "../methods/room.methods";
import LanguageMethods from "../methods/language.methods";
import dayjs from 'dayjs';
import {UserMethods} from "../methods/user.methods";
import {menu} from "../routes";
import T from "./common/t";
import {observer} from "mobx-react-lite";
import DeviceMethods from "../methods/device.methods";

/*TODO - готово*/

const RoomComponent = () => {
    const [loading, setLoading] = React.useState(true);
    const [languages, setLanguages] = React.useState({});
    const location = useLocation();
    const navigate = useNavigate();

    const [room, setRoom] = React.useState({});
    const [locations, setLocations] = React.useState({});
    const [users, setUsers] = React.useState({});
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [startRent, setStartRent] = React.useState(null);
    const [stopRent, setStopRent] = React.useState(null);
    const [userId, setUserId] = React.useState(null);
    const [locationId, setLocationId] = React.useState(0);
    const [checkedSectors, setCheckedSectors] = React.useState([]);
    const [checkedDevices, setCheckedDevices] = React.useState([]);
    const [isRented, setIsRented] = React.useState(0);
    const [devices, setDevices] = React.useState({});

    const [form] = Form.useForm();

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} ' + 'is required',
    };

    const getDataRoom = React.useCallback(() => {
        (async () => {
            const [
                room,
                locations,
                languages,
                users,
                devices
            ] = await Promise.allSettled([
                RoomMethods.getRoomById(location.pathname.split('/').at(-1)),
                LocationMethods.getLocations(),
                LanguageMethods.getLanguages(),
                UserMethods.getUsers(),
                DeviceMethods.getDevices('tuya')
            ]);
            if (
                room.status === 'fulfilled' &&
                locations.status === 'fulfilled' &&
                languages.status === 'fulfilled' &&
                users.status === 'fulfilled' &&
                devices.status === 'fulfilled'
            ) {
                setRoom(room.value.data)
                setLocations(locations.value.data);
                setLanguages(languages.value.data);
                setUsers(users.value.data);
                setDevices(devices.value.data);
                if (Object.values(room.value.data)[0].userId) {
                    setUserId(() => Object.values(room.value.data)[0].userId)
                } else {
                    setUserId(() => null)
                    setIsRented(() => 0)
                }
                setStartRent(() => dayjs(Object.values(room.value.data)[0].startRent))
                setStopRent(() => dayjs(Object.values(room.value.data)[0].stopRent))
                if (Object.values(room.value.data)[0].sectors.length > 0) {
                    setLocationId(Object.values(room.value.data)[0].sectors[0].locationId);
                }
                const tmpSect = []
                Object.values(room.value.data)[0].sectors.forEach(sector => {
                    tmpSect.push(sector.name)
                })
                const tmpDevice = []
                setCheckedSectors(() => tmpSect);
                Object.values(room.value.data)[0].tuya_devices.forEach(device => {
                    tmpDevice.push(device.id)
                })
                setCheckedDevices(() => tmpDevice)
                setLoading(false)
            }
        })()
    }, [location.pathname])

    React.useEffect(() => {
        getDataRoom()
    }, []);

    React.useEffect(() => {
        Object.values(languages).map(lang =>
            form.setFieldValue(`name_${lang.isoCode}`, room[lang.id].name)
        )
        if (Object.keys(room).length > 0) {
            form.setFieldValue(
                'startRent',
                Object.values(room)[0].startRent
                    ? dayjs(startRent)
                    : ''
            );
            form.setFieldValue(
                'stopRent',
                Object.values(room)[0].stopRent
                    ? dayjs(stopRent)
                    : ''
            );
        }

    }, [form, languages, room]);


    const changeLocation = (value) => {
        setLocationId(value);
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setLoading(true);
        RoomMethods.deleteRoom(location.pathname.split('/').at(-1))
            .then(() => {
                openNotificationWithIcon('success', <T>Вы успешно удалили помещение</T>, []);
                handleCancel()
                setTimeout(() => {
                    navigate(-1);
                }, 3000)
            })
            .catch((error) => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const toggleStartRentDate = (e) => {
        setStartRent(() => new Date(e))
    }

    const toggleStopRentDate = (e) => {
        setStopRent(() => new Date(e))
    }

    const toggleUserId = (e) => {
        setUserId(() => e)
    }

    const updateRoomFunction = () => {
        const obj = {};
        for (const language of Object.values(languages)) {
            obj[language.isoCode] = form.getFieldValue(`name_${language.isoCode}`)
        }
        RoomMethods.updateRoom(location.pathname.split('/').at(-1), {
            name: obj,
            startRent,
            stopRent,
            userId,
            locationId,
            checkedSectors,
            checkedDevices,
            isRented
        })
            .then(() => {
                openNotificationWithIcon('success', <T>Вы успешно обновили помещение</T>, [])
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            })
            .catch(error => openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors))
    }

    const changeSector = (checked) => {
        setCheckedSectors(checked)
    }

    const changeDevice = (checked) => {
        setCheckedDevices(checked)
    }

    const changeRent = () => {
        if (isRented === 0) {
            setIsRented(() => 1)
        } else {
            setIsRented(() => 0)
        }
    }

    console.log(devices)

    return (
        <Flex align="start" style={{ padding: '16px' }}>
            <Form
                name="update_room"
                style={{ width: '100%'}}
                form={form}
                multipart={"form-data"}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={updateRoomFunction}
            >
                {contextHolder}
                {
                    loading
                    ?
                    <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                        <Spin size="large" />
                    </Flex>
                    :
                        <Flex justify="space-between" gap={20}>
                            <Flex vertical gap={20} style={{width: '100%'}}>
                                <Tabs
                                    defaultActiveKey="1"
                                    size={'large'}
                                    type={"card"}
                                    items={
                                        Object.values(languages).map(item => {
                                            return {
                                                label: item.isoCode.toUpperCase(),
                                                key: item.id,
                                                children:
                                                    <Form.Item
                                                        hasFeedback
                                                        style={{width: '100%', marginBottom: 0}}
                                                        label={<T>Номер помещения</T>}
                                                        name={`name_${item.isoCode}`}
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Input size={"large"} />
                                                    </Form.Item>
                                            };
                                        })
                                    }
                                />
                                <Divider orientation={"left"}><T>Данные по аренде</T></Divider>
                                <Space size={'middle'} align="end" wrap={true}>
                                    <Form.Item
                                        hasFeedback
                                        label={<T>Начало аренды</T>}
                                        name={`startRent`}

                                    >
                                        <DatePicker disabled={Object.values(room)[0].isRented} onChange={toggleStartRentDate} size={"large"} />
                                    </Form.Item>
                                    <Form.Item
                                        hasFeedback
                                        label={<T>Конец аренды</T>}
                                        name={`stopRent`}
                                    >
                                        <DatePicker disabled={!Object.values(room)[0].isRented} onChange={toggleStopRentDate} size={"large"} />
                                    </Form.Item>
                                    <Form.Item
                                        label={<T>Пользователь</T>}
                                    >
                                        <Select
                                            size={"large"}
                                            style={{ width: '200px'}}
                                            options={
                                                Object.values(users).filter((a, b) => a.name - b.name ? 1 : -1).map(user => ({ label: user.name, value: user.id }))
                                            }
                                            onChange={toggleUserId}
                                            placeholder={<T>Выберите пользователя</T>}
                                            defaultValue={Object.values(room)[0].userId ? Object.values(room)[0].userId : null}

                                            showSearch filterOption={(input, option) => (String(option?.label) ?? '').includes(String(input))}
                                            filterSort={(optionA, optionB) =>
                                                (String(optionA?.label) ?? '').toLowerCase().localeCompare((String(optionB?.label) ?? '').toLowerCase())
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={'isRented'}
                                    >
                                        <Tag color={Object.values(room)[0].isRented ? "green" : "red"}>{Object.values(room)[0].isRented ? <T>В АРЕНДЕ</T> : <T>СВОБОДНО</T>}</Tag>
                                    </Form.Item>
                                </Space>
                                <Form.Item label={<T>Аренда</T>}>
                                    <Space size={"middle"}>
                                        <Checkbox disabled={!Object.values(room)[0].isRented} onChange={changeRent} checked={isRented} >
                                            <Tooltip  placement="right" title={<T>Нажмите чекбокс, затем - сохранить</T>}>
                                                <T>Снять с аренды</T>
                                            </Tooltip>
                                        </Checkbox>
                                    </Space>
                                </Form.Item>
                                <Divider orientation={"left"}><T>Данные по расположению</T></Divider>
                                <Form.Item
                                    label={<T>Локации</T>}
                                    name={`locationId`}
                                    key={locationId}
                                >
                                    <Select
                                        size={"large"}
                                        defaultValue={locationId !== 0 ? locationId : null}
                                        onChange={changeLocation}
                                        disabled={Object.values(room)[0].sectors.length !== 0}
                                        options={Object.values(locations).map((location) => ({ label: location.name, value: location.id }))}
                                    />
                                </Form.Item>
                                <Form.Item label={<T>Сектора</T>}>
                                    {
                                        Object.values(room)[0].sectors.length > 0 || locationId !== 0
                                        ?
                                            locations[locationId].sectors.length > 0
                                                ?
                                                    <Space size={"middle"} wrap={true}>
                                                        <Checkbox.Group onChange={changeSector} defaultValue={checkedSectors}>
                                                            <Space size={"middle"} wrap={true}>
                                                                {
                                                                    locations[locationId].sectors.map((sector) =>
                                                                        <Card key={sector}>
                                                                            <Checkbox value={sector}>{sector}</Checkbox>
                                                                        </Card>
                                                                    )
                                                                }
                                                            </Space>
                                                        </Checkbox.Group>
                                                    </Space>
                                                :
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}><Link to={menu.settings.path + "/" +menu.settings.children.sectors.path}><T>Добавить сектора</T></Link></Empty>
                                        :
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    }
                                </Form.Item>
                                <Form.Item label={<T>Устройства SmartLife</T>}>
                                    {
                                        Object.keys(devices).length > 0
                                        ?
                                        <Space size={"middle"} wrap={true}>
                                            <Checkbox.Group  style={{
                                                width: '100%',
                                            }} onChange={changeDevice} defaultValue={checkedDevices}>
                                                <Space size={"middle"} wrap={true}>
                                                    {
                                                        Object.values(devices).map((device) =>
                                                            (device.deviceType.replaceAll(' ', '') === 'Smartlock' ||
                                                                device.deviceType === "Smart IR with T&H Sensor")
                                                            &&
                                                            <Card key={device.id}>
                                                                <Checkbox value={device.id}>{device.name}</Checkbox>
                                                            </Card>
                                                        )
                                                    }
                                                </Space>
                                            </Checkbox.Group>
                                        </Space>
                                        :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />

                                    }
                                </Form.Item>
                            </Flex>
                            <Flex vertical style={{width: '20%'}}>
                                <Flex vertical style={{width: '100%', position: 'sticky', top: '1rem'}}>
                                    <Form.Item shouldUpdate>
                                        {
                                            () => (
                                                <Button
                                                    size={"large"}
                                                    style={{width: '100%', marginTop: '6px'}}
                                                    loading={false}
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    <T>Сохранить</T>
                                                </Button>
                                            )
                                        }
                                    </Form.Item>
                                    <Button disabled={Object.values(room)[0].isRented || Object.values(room)[0].sectors.length !== 0} onClick={showModal} danger type="text"><T>Удалить помещение</T></Button>
                                </Flex>
                            </Flex>
                        </Flex>
                }
            </Form>
            <Modal title={<T>Удалить помещение</T>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Typography.Text><T>Вы точно хотите удалить помещение</T>?</Typography.Text>
            </Modal>
        </Flex>
    );
};

export default observer(RoomComponent);