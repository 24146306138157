import React from 'react';
import {useLocation} from "react-router-dom";
import {
    Button,
    Flex,
    Form,
    Input,
    notification,
    Spin,
    Tabs,
    Space,
    Tag, Divider, Card
} from "antd";
import DeviceMethods from "../methods/device.methods";
import {PoweroffOutlined} from "@ant-design/icons";
import T from "./common/t";
import {observer} from "mobx-react-lite";
import {UserMethods} from "../methods/user.methods";
import {useAuth} from "../hooks/useAuth";

/*TODO - готово*/

const DeviceComponent = () => {
    const [loading, setLoading] = React.useState(true);
    const [deviceState, setDeviceState] = React.useState("");
    const [deviceProperties, setDeviceProperties] = React.useState([]);
    const [device, setDevice] = React.useState({});
    const [pir, setPir] = React.useState('');
    const params = useLocation();

    const user = useAuth()

    const [userName, setUserName] = React.useState('');

    React.useEffect(() =>  {
        (async  () =>  {
            UserMethods.getProfile().then(res  =>  {
                setUserName(() => res.data.first_name + ' ' + res.data.last_name);
            })
        })()
    }, [user]);

    const location = useLocation();

    const [api, contextHolder] = notification.useNotification();

    const [serviceState, setServiceState] = React.useState("");

    React.useEffect(() => {
        setServiceState(() => params.search.split('=')[1])
    }, [params.search])

    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors.length > 0 ? errors[0] : '',
        });
    };

    const [form] = Form.useForm();

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} ' + 'is required',
    };

    React.useEffect(() => {
        if (serviceState.length > 0) {
            (async () => {
                const [
                    device,
                    state
                ] = await Promise.allSettled([
                    DeviceMethods.getDeviceById(serviceState, location.pathname.split('/').at(-1)),
                ]);
                if (
                    device.status === 'fulfilled'
                ) {
                    setDevice(device.value.data)
                    setLoading(false)
                }
            })()
        }
    }, [location.pathname, serviceState]);

    React.useEffect(() => {
        if (serviceState === 'tuya' && device.deviceId) {
            DeviceMethods.getTuyaDeviceProperties({device_id: device.deviceId}).then((response) => {
                console.log('tuya', response)
                setDeviceProperties(() => response.result?.properties)
            })
        }
    }, [serviceState, device]);

    React.useEffect(() => {
        form.setFieldsValue({...device})
    }, [device, form]);

    const deviceInfo = React.useCallback((formData) => {
        DeviceMethods.getDeviceInfo('ajax', formData).then((response) => {
            console.log('response', response.data)
            if (response.data.temperature){
                setPir(() => response.data.temperature)
            }

            setDeviceState(response.data.lockupRelayMode)
        }).catch((error) => {
            openNotificationWithIcon('error', error.response.data.message, error.response.data.errors ? error.response.data.errors : []);
        })
    })

    React.useEffect(() => {
        if (Object.keys(device).length > 0 && serviceState === 'ajax') {
            const formData = new FormData();
            formData.append("userId", device.ajaxUserId);
            formData.append("hubId", device.hubId);
            formData.append("deviceId", device.deviceId);
            deviceInfo(formData);
        }
    }, [device, serviceState]);

    const setCommand = () => {
        const formData = new FormData();
        formData.append("userId", device.ajaxUserId);
        formData.append("hubId", device.hubId);
        formData.append("deviceId", device.deviceId);
        formData.append("deviceType", device.deviceType);
        formData.append("userName", userName);
        formData.append("deviceName", device.name);
        formData.append("command", "switchStateGate");
        DeviceMethods.setCommand('ajax', formData).then((response) => {
            openNotificationWithIcon('success', <T>Команда успешно отправлена</T>, []);
        }).catch((error) => {
            openNotificationWithIcon('error', error.response.data.message, error.response.data.errors ? error.response.data.errors : []);
        })
    }

    // const changeDevicesTuya = (deviceId) => {
    //     if (Number(deviceId) !== 0){
    //
    //         }
    //         $host.post('/tuya/tuya_device', {device_id: deviceId}).then(data => {
    //             setDeviceIdTuya(deviceId);
    //             getPropertiesTuya(deviceId);
    //         })
    //     } else {
    //         setDeviceTuyaProperties([]);
    //     }
    // }

    const setCommandTuya = (action_data) => {
        DeviceMethods.setCommand('tuya', {device_id: device.deviceId, action_data}).then((response) => {
            if (!response.success) {
                openNotificationWithIcon('error', response.msg, []);
            }
            console.log('relay', response)
        })
        // $host.post('/tuya/tuya_device_command', {device_id, action_data}).then(data => {
        //     getPropertiesTuya(device_id);
        //     changeDevicesTuya(device_id);
        // })
    }

    const [status, setStatus] = React.useState({});

    React.useEffect(() => {
        const st = {};
        DeviceMethods.getTuyaDeviceStatus({device_id: device.deviceId}).then((response) => {
            // console.log('status', response.result.status[13], response.result.name);
            if (response.result?.status[13]) {
                st[response.result.name] = response.result.status[13].value;
                setStatus((prevState) => ({...prevState,...st}));
            }
        })
        setStatus(() => st);

    }, [device]);

    const setCommandCloseDoorTuya = () => {
        // закрывет дверь
        DeviceMethods.getPswTicketTuya({device_id: device.deviceId}).then((response) => {
            if (response.success) {
                DeviceMethods.getTuyaDeviceState({device_id: device.deviceId}).then((response1) => {
                    if (response1.success) {
                        DeviceMethods.setCommandOpenDoor(
                            {
                                device_id: device.deviceId,
                                commands: {
                                    ticket_id: response.result.ticket_id,
                                    open: false
                                },
                                userName,
                                deviceName: device.name,
                                command: 'close'
                            }).then((response) => {
                            if (!response1.success) {
                                openNotificationWithIcon('error', response.msg, []);
                            }
                            openNotificationWithIcon('success', <T>Команда успешно отправлена</T>, []);
                            setTimeout(() => {
                                window.location.reload();
                            }, 6000)

                        })
                    }
                })
            }
        });
    }

    const setCommandOpenDoorTuya = () => {
        // закрывет дверь
        DeviceMethods.getPswTicketTuya({device_id: device.deviceId}).then((response) => {
            if (response.success) {
                DeviceMethods.getTuyaDeviceState({device_id: device.deviceId}).then((response1) => {
                    if (response1.success) {
                        DeviceMethods.setCommandOpenDoor(
                            {
                                device_id: device.deviceId,
                                commands: {
                                    ticket_id: response.result.ticket_id, open: true
                                },
                                userName,
                                deviceName: device.name,
                                command: 'open'
                            }).then((response) => {
                            if (!response1.success) {
                                openNotificationWithIcon('error', response.msg, []);
                            }
                            openNotificationWithIcon('success', <T>Команда успешно отправлена</T>, []);
                            setTimeout(() => {
                                window.location.reload();
                            }, 6000)
                        })
                    }
                })
            }
        });
    }

    return (
        <Flex align="start" style={{ padding: '16px' }}>
            {contextHolder}
            {
                serviceState === 'ajax'
                ?
                    <Form
                        name="update_device"
                        style={{ width: '100%'}}
                        form={form}
                        multipart={"form-data"}
                        layout="vertical"
                        validateMessages={validateMessages}
                    >
                        {
                            loading
                                ?
                                <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                                    <Spin size="large" />
                                </Flex>
                                :
                                <Tabs
                                    type={"card"}
                                    items={[
                                        {label: 'Информация', key: 'info', children: <Space size={"middle"} direction={"vertical"}>
                                                <Space size={'middle'} align="center" >
                                                    <Form.Item
                                                        hasFeedback
                                                        label={<T>Название</T>}
                                                        name={`name`}
                                                    >
                                                        <Input disabled size={"large"} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        hasFeedback
                                                        label={<T>Статус</T>}
                                                        name={`online`}
                                                    >
                                                        <Tag color={device.online ? "green" : "red"} size={"large"} >{device.online ? <T>Online</T> : <T>Offline</T>}</Tag>
                                                    </Form.Item>
                                                </Space>

                                                <Space size={'middle'} align="center">
                                                    <Form.Item
                                                        hasFeedback
                                                        label={<T>Id хаба</T>}
                                                        name={`hubId`}
                                                    >
                                                        <Input disabled size={"large"} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        hasFeedback
                                                        label={<T>Id устройства</T>}
                                                        name={`deviceId`}
                                                    >
                                                        <Input disabled size={"large"} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        hasFeedback
                                                        label={<T>Тип устройства</T>}
                                                        name={`deviceType`}
                                                    >
                                                        <Input disabled size={"large"} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        hasFeedback
                                                        label={<T>Температура</T>}
                                                        name={`temperature`}
                                                    >
                                                        <Tag style={{padding:'.6rem', borderRadius: '.5rem'}} color={'green'} size={"large"}>
                                                            {pir} ℃
                                                        </Tag>
                                                    </Form.Item>

                                                </Space>
                                            </Space>},
                                        device.deviceType === "Relay" && {label: <T>Команды</T>, key: 'command', children:
                                                <Space size={"middle"} align="center">
                                                    <Button onClick={setCommand} icon={<PoweroffOutlined />} size={"large"} style={{width: '100%', marginTop: '6px'}} type={"primary"} loading={false}>
                                                        {deviceState === "IMPULSE" ? <T>Отктыть / Закрыть</T> : <T>Отктыть</T>}
                                                    </Button>
                                                </Space>},
                                    ]}
                                />
                        }
                    </Form>
                :
                    <Form
                        name="update_device"
                        style={{ width: '100%'}}
                        form={form}
                        multipart={"form-data"}
                        layout="vertical"
                        validateMessages={validateMessages}
                    >
                        {
                            loading
                                ?
                                <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                                    <Spin size="large" />
                                </Flex>
                                :
                                <Tabs
                                    type={"card"}
                                    items={[
                                        {label: 'Информация', key: 'info', children: <Space size={"middle"} direction={"vertical"}>
                                                <Space size={'middle'} align="center" >
                                                    <Form.Item
                                                        hasFeedback
                                                        label={<T>Название</T>}
                                                        name={`name`}
                                                    >
                                                        <Input disabled size={"large"} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        hasFeedback
                                                        label={<T>Статус</T>}
                                                        name={`online`}
                                                    >
                                                        <Tag color={device.isOnline ? "green" : "red"} size={"large"} >{device.isOnline ? <T>Online</T> : <T>Offline</T>}</Tag>
                                                    </Form.Item>
                                                </Space>

                                                <Space size={'middle'} align="center">
                                                    <Form.Item
                                                        hasFeedback
                                                        label={<T>Id устройства</T>}
                                                        name={`deviceId`}
                                                    >
                                                        <Input disabled size={"large"} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        hasFeedback
                                                        label={<T>Тип устройства</T>}
                                                        name={`deviceType`}
                                                    >
                                                        <Input disabled size={"large"} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        hasFeedback
                                                        label={<T>Модель устройства</T>}
                                                        name={`deviceModel`}
                                                    >
                                                        <Input disabled size={"large"} />
                                                    </Form.Item>
                                                </Space>
                                            </Space>},
                                        deviceProperties?.length > 0 && deviceProperties?.at(-1)?.code !== 'total_ele'
                                            ?
                                            deviceProperties[0]?.code === "switch_1"
                                                ?
                                                {
                                                    label: <T>Команды</T>, key: 'command', children:
                                                        <Space size={"middle"} align="center">
                                                            {
                                                                (deviceProperties[0]?.code === 'switch_1' && deviceProperties[0]?.value)
                                                                    ?
                                                                    <Button icon={<PoweroffOutlined/>} size={"large"} style={{width: '100%', marginTop: '6px'}}
                                                                            type={"primary"} loading={false} onClick={() => setCommandTuya(false)}>Выкл</Button>
                                                                    :
                                                                    <Button icon={<PoweroffOutlined/>} size={"large"} style={{width: '100%', marginTop: '6px'}}
                                                                            type={"primary"} loading={false} onClick={() => setCommandTuya(true)}>Вкл</Button>
                                                            }
                                                        </Space>
                                                }
                                                :
                                                deviceProperties.at(-1)?.code === 'special_control'
                                                    &&
                                                    {
                                                        label: <T>Команды</T>, key: 'command', children:
                                                            <Space size={"middle"} align="center" wrap={
                                                                true
                                                            }>
                                                                <Card key={device.deviceId}>
                                                                    <Divider style={{marginTop: 0}} orientation={"left"}>
                                                                        {device.name}
                                                                    </Divider>
                                                                    <Button disabled={status[device.name]} icon={<PoweroffOutlined/>} size={"large"} style={{width: '100%', marginTop: '6px'}}
                                                                            type={"primary"} loading={false} onClick={() => setCommandOpenDoorTuya(device.deviceId)}>Открыть</Button>
                                                                    <Button disabled={!status[device.name]} icon={<PoweroffOutlined/>} size={"large"} style={{width: '100%', marginTop: '6px'}}
                                                                            type={"primary"} loading={false} onClick={() => setCommandCloseDoorTuya(device.deviceId)}>Закрыть</Button>
                                                                </Card>

                                                            </Space>
                                                    }
                                            :
                                            deviceProperties &&
                                            {
                                                label: <T>Показания счетчика</T>, key: 'command', children: <button style={{
                                                    padding: '1rem'
                                                }}
                                                disabled={true}>{deviceProperties?.at(-1)?.value / 100} kWt</button>
                                            },

                                            deviceProperties?.at(0)?.code === "temp_current" && deviceProperties?.at(1)?.code === "humidity_value"
                                            &&
                                            {
                                                label: <T>Температура / Влажность</T>, key: 'command', children:
                                                    <Space size={"middle"} align="center" wrap={
                                                        true
                                                    }>
                                                        <div style={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                                            <Form.Item
                                                                hasFeedback
                                                                label={<T>Температура</T>}
                                                            >
                                                                <Input disabled={true} size={"large"} value={(deviceProperties.at(0)?.value * 0.1).toFixed(1) + ' ℃'}/>
                                                            </Form.Item>
                                                        </div>

                                                        <div style={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                                            <Form.Item
                                                                hasFeedback
                                                                label={<T>Влажность воздуха</T>}
                                                            >
                                                            <Input disabled={true} size={"large"} value={(deviceProperties.at(1)?.value).toFixed(1) + ' %'}/>
                                                            </Form.Item>
                                                        </div>

                                                    </Space>
                                            }

                                    ]}
                                />
                        }
                    </Form>
            }


        </Flex>
    );
};

export default observer(DeviceComponent);