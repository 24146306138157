import React from 'react';
import MainComponent from "../components/common/main.component";
import T from "../components/common/t";
import {observer} from "mobx-react-lite";
import AllComponent from "../components/common/all.component";
import {Card, Flex, Form, Input, notification, Spin, Table} from "antd";
import {useLocation} from "react-router-dom";
import LoggerMethods from "../methods/logger.methond";

const columns = [
    {
        title: <T>ФИО</T>,
        dataIndex: 'userName',
        key: 'userName',
        width: '15%',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.id - b.id,
    },
    {
        title: <T>Действие</T>,
        dataIndex: 'action',
        width: '75%',
        key: 'action',
    },
    {
        title: <T>Создано</T>,
        dataIndex: 'createdAt',
        width: '15%',
        key: 'createdAt',
        render:  (text)  =>  text.split('T')[0].replaceAll('-', '.') + ' ' + text.split('T')[1].split('.')[0],
    },
];

const LoggerPage = () => {
    const [loading, setLoading] = React.useState(true);
    const [searchInput, setSearchInput] = React.useState('');
    const [emptyFilter, setEmptyFilter] = React.useState(false);
    const location = useLocation();
    const [filteredData, setFilteredData] = React.useState([]);
    const [searched, setSearched] = React.useState(false);
    const [loggerData, setLoggerData] = React.useState([]);

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    React.useEffect(() => {
        const filtered = loggerData.filter(d =>
            d.userName.toLowerCase().includes(searchInput.toLowerCase()) ||
            d.action.toLowerCase().includes(searchInput.toLowerCase())
        )
        setTimeout(() => {
            setFilteredData(() => filtered)
            setSearched(() => true)
            if (filtered.length === 0) {
                setEmptyFilter(() => true)
            } else {
                setEmptyFilter(() => false)
            }
        }, 300);
    }, [searchInput, loggerData]);

    const search = (e) => {
        setSearchInput(e.target.value);
    }

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} ' + 'is required',
        types: {
            // eslint-disable-next-line
            email: '${label} ' + 'is not a valid email',
            // eslint-disable-next-line
            tel: '${label} ' + 'is not a valid phone number',
        },
    };

    React.useEffect(() => {
        (async () => {
            const [
                logger,
            ] = await Promise.allSettled([
                LoggerMethods.getLogger(),
            ]);
            if (
                logger.status === 'fulfilled'
            ) {
                setLoggerData(() => Object.values(logger.value.data))
                setLoading(false)
            }
        })()
    }, [location.pathname]);

    const err = React.useCallback(() => {
        openNotificationWithIcon('error', <T>По вашему запросу ничего не найдено</T>, []);
    }, []);

    React.useMemo(() =>  {
        if (emptyFilter && searchInput.length > 0) {
            err();
        }
    }, [emptyFilter, err]);

    return (
        <AllComponent>
            <MainComponent title={<T>Логирование</T>} showButton={false}>
                {contextHolder}
                {
                    loading
                        ?
                        <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                            <Spin size="large" />
                        </Flex>
                        :
                        <Flex justify="space-between">
                            <Flex vertical gap={20} style={{width: '100%'}}>
                                {searched && contextHolder}
                                <Table
                                    style={{maxWidth: '100%', borderRight: '1px solid #f0f0f0'}}
                                    columns={columns}
                                    pagination={false}
                                    scroll={{x:'max-content', y: 'calc(100vh - 13rem)'}}
                                    dataSource={
                                       filteredData.length > 0
                                           ?
                                           filteredData
                                           :
                                           loggerData
                                    }
                                />
                            </Flex>
                            <Flex vertical style={{width: '20%'}}>
                                <Flex vertical style={{width: '100%', position: 'sticky', top: 0}}>
                                    <Card style={{boxShadow: 'none'}} bordered={false}>
                                        <Input placeholder="Search..." value={searchInput} onChange={search} />
                                    </Card>
                                </Flex>
                            </Flex>
                        </Flex>
                }
            </MainComponent>
        </AllComponent>
    );
};

export default observer(LoggerPage);