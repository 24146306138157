import React from 'react';
import {Button, Checkbox, Flex, Form, Input, Spin} from "antd";
import T from "../../common/t";
import BannersMethods from "../../../methods/banners.methods";

const AddBannerFormComponent = ({openNotificationWithIcon, validateMessages}) => {
    const [form] = Form.useForm();
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const [video, setVideo] = React.useState(null);

    const handleChange = (e) => {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
            setFile({name: e.target.files[0].name, data: reader.result});
        }
    }

    const handleChangeVideo = (e) => {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
            setVideo({name: e.target.files[0].name, data: reader.result});
        }
    }

    const handleOk = () => {
        setLoadingForm(true);
        BannersMethods.addBanner({...form.getFieldsValue(), cover: file, video: video})
            .then(res => {
                form.resetFields();
                openNotificationWithIcon('success', <T>Банер успешно добавлен</T>, []);
                setTimeout(() => {
                    setLoadingForm(false);
                    window.location.reload();
                }, 1000);
            })
            .catch(error => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    return (

        loadingForm
        ?
            <Flex justify="center" align="center" style={{height: '176px'}}>
                <Spin size="large" />
            </Flex>
        :
            <Form
                style={{ width: '100%'}}
                form={form}
                multipart={"form-data"}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={handleOk}
            >
                <Form.Item
                    hasFeedback
                    label={<T>Заголовок</T>}
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Описание</T>}
                    name="description"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    label={<T>Обложка (Обязательно)</T>}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input type={"file"} onChange={handleChange}/>
                </Form.Item>
                <Form.Item
                    label={<T>Видео (Обязательно)</T>}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input type={"file"} onChange={handleChangeVideo}/>
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Ссылка (Забронировать склад)</T>}
                    name="url1"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Ссылка (Комерческие помещения)</T>}
                    name="url2"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Опубликовано</T>}
                    name="isActive"
                >
                    <Checkbox size={"large"} checked={true}/>
                </Form.Item>
                <Form.Item style={{marginBottom: 0}}>
                    <Button style={{width: '100%'}} size={"large"} type="primary" htmlType="submit" ><T>Сохранить</T></Button>
                </Form.Item>
            </Form>
    );
};

export default AddBannerFormComponent;
