import React from 'react';
import {Flex, Form, Input, Spin, Tag} from "antd";
import {useLocation} from "react-router-dom";
import LanguageMethods from "../../methods/language.methods";
import T from "../common/t";
import RoleMethods from "../../methods/role.methods";

const UpdateRoleFormComponent = () => {
    const [pubDate, setPubDate] = React.useState('');
    const [pub, setPub] = React.useState(false);
    const location = useLocation();
    const [updateDate, setUpdateDate] = React.useState('');
    const [onePage, setOnePage] = React.useState({});
    const [loadingForm, setLoadingForm] = React.useState(false);

    const [form] = Form.useForm();

    const getDataOneNews = React.useCallback(() => {
        (async () => {
            const [
                onePage,
            ] = await Promise.allSettled([
                RoleMethods.getRoleById(location.pathname.split('/').at(-1)),
            ]);
            if (
                onePage.status === 'fulfilled'
            ) {
                setOnePage(() => onePage.value.data);
                setLoadingForm(() => false)
            }
        })()
    }, [location.pathname])


    React.useEffect(() => {
        getDataOneNews()
    }, []);

    React.useEffect(() => {
        form.setFieldValue(`name`, onePage.name)
        form.setFieldValue(`value`, onePage.value)
        setPub(() => onePage.isActive)
        setPubDate(() => onePage.createdAt)
        setUpdateDate(() => onePage.updatedAt)
    }, [form, onePage]);


    return (
        <>
            {
                loadingForm
                    ?
                    <Flex justify="center" align="center" style={{height: '176px'}}>
                        <Spin size="large" />
                    </Flex>
                    :
                    <>
                        <Form
                            name="update_room"
                            style={{ width: '100%', padding: '1rem'}}
                            form={form}
                            multipart={"form-data"}
                            layout="vertical"
                        >
                            <Flex justify="space-between" gap={20}>
                                <Flex vertical gap={20} style={{width: '100%'}}>

                                    <Form.Item
                                        hasFeedback
                                        style={{width: '100%', marginBottom: '1rem',}}
                                        label={<T>Название</T>}
                                        name={`name`}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input size={"large"} disabled/>
                                    </Form.Item>
                                    <Form.Item
                                        hasFeedback
                                        style={{width: '100%', marginBottom: '1rem',}}
                                        label={<T>Значение</T>}
                                        name={`value`}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input size={"large"} disabled/>
                                    </Form.Item>
                                    <Flex>
                                        <Form.Item
                                            name={'isActive'}
                                        >
                                            {
                                                pub
                                                    ?
                                                    <Tag style={{fontSize: '1.2rem', padding: '.5rem'}} color={"green"}><T>Активный</T></Tag>
                                                    :
                                                    <Tag style={{fontSize: '1.2rem', padding: '.5rem'}} color={"red"}><T>Снят с публикации</T></Tag>
                                            }

                                        </Form.Item>
                                    </Flex>
                                </Flex>
                                <Flex vertical style={{width: '20%'}}>
                                    <Flex vertical style={{width: '100%', position: 'sticky', top: '1rem'}}>
                                        <Form.Item
                                            label={<T>Дата публикации</T>}
                                        >
                                            <Input type={"dateTime"} disabled value={
                                                pubDate?.replaceAll('-', '.')
                                                    .replace('T', ' ')
                                            }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={<T>Дата обновления</T>}
                                        >
                                            <Input type={"dateTime"} disabled value={
                                                updateDate?.replaceAll('-', '.')
                                                    .replace('T', ' ')
                                            }
                                            />
                                        </Form.Item>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Form>
                    </>
            }
        </>
    );
};

export default UpdateRoleFormComponent;