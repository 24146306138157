import React from 'react';
import MainComponent from "../components/common/main.component";
import MessageComponent from "../components/message.component";
import T from "../components/common/t";
import {observer} from "mobx-react-lite";
import AllComponent from "../components/common/all.component";

const MessagesPage = () => {
    return (
        <AllComponent>
            <MainComponent title={<T>Message</T>} showButton={false}>
                <MessageComponent />
            </MainComponent>
        </AllComponent>
    );
};

export default observer(MessagesPage);