import React from 'react';
import T from "../components/common/t";
import MainComponent from "../components/common/main.component";
import {useLocation} from "react-router-dom";
import AllWebComponent from "../components/web/allWeb.component";
import AllComponent from "../components/common/all.component";
import AllModalComponent from "../components/web/allModal.component";

const AllDataPage = () => {
    const location = useLocation();

    const [currentPage, setCurrentPage] = React.useState("");
    const [showModalTitle, setShowModalTitle] = React.useState("");
    const [pageType, setPageType] = React.useState("");
    const [openModal, setOpenModal] = React.useState(false);

    React.useEffect(() => {
        if (location.pathname.split('/').at(-1) === 'news') {
            setCurrentPage(() => <T>Новости</T>);
            setShowModalTitle(() => <T>Добавить новость</T>);
            setPageType(() => 'news');
        }
        if (location.pathname.split('/').at(-1) === 'unitTypes') {
            setCurrentPage(() => <T>Тип кладовок</T>);
            setShowModalTitle(() => <T>Добавить тип кладовки</T>);
            setPageType(() => 'unitTypes');
        }
        if (location.pathname.split('/').at(-1) === 'units') {
            setCurrentPage(() => <T>Кладовки</T>);
            setShowModalTitle(() => <T>Добавить кладовку</T>);
            setPageType(() => 'units');
        }
        if (location.pathname.split('/').at(-1) === 'sliders') {
            setCurrentPage(() => <T>Слайдер</T>);
            setShowModalTitle(() => <T>Добавить слайд</T>);
            setPageType(() => 'sliders');
        }
        if (location.pathname.split('/').at(-1) === 'advantages') {
            setCurrentPage(() => <T>Преимущества</T>);
            setShowModalTitle(() => <T>Добавить преимущество</T>);
            setPageType(() => 'advantages');
        }
        if (location.pathname.split('/').at(-1) === 'pages') {
            setCurrentPage(() => <T>Страницы</T>);
            setShowModalTitle(() => <T>Добавить страницу</T>);
            setPageType(() => 'pages');
        }
        if (location.pathname.split('/').at(-1) === 'statics') {
            setCurrentPage(() => <T>Сатика</T>);
            setPageType(() => 'statics');
        }
        if (location.pathname.split('/').at(-1) === 'languages') {
            setCurrentPage(() => <T>Языки</T>);
            setPageType(() => 'languages');
        }
        if (location.pathname.split('/').at(-1) === 'roles') {
            setCurrentPage(() => <T>Роли</T>);
            setPageType(() => 'roles');
        }
        if (location.pathname.split('/').at(-1) === 'locations') {
            setCurrentPage(() => <T>Локации</T>);
            setShowModalTitle(() => <T>Добавить локацию</T>);
            setPageType(() => 'locations');
        }
        if (location.pathname.split('/').at(-1) === 'sectors') {
            setCurrentPage(() => <T>Сектора</T>);
            setShowModalTitle(() => <T>Добавить сектор</T>);
            setPageType(() => 'sectors');
        }
        if (location.pathname.split('/').at(-1) === 'services') {
            setCurrentPage(() => <T>Сервисы</T>);
            setShowModalTitle(() => <T>Добавить сервис</T>);
            setPageType(() => 'services');
        }
        if (location.pathname.split('/').at(-1) === 'banners') {
            setCurrentPage(() => <T>Банеры</T>);
            setShowModalTitle(() => <T>Добавить банер</T>);
            setPageType(() => 'banners');
        }
        if (location.pathname.split('/').at(-1) === 'stock') {
            setCurrentPage(() => <T>Адреса</T>);
            setShowModalTitle(() => <T>Добавить адрес</T>);
            setPageType(() => 'stock');
        }
        if (location.pathname.split('/').at(-1) === 'web_pages') {
            setCurrentPage(() => <T>Web страницы</T>);
            setShowModalTitle(() => <T>Добавить Web страницу</T>);
            setPageType(() => 'web_pages');
        }
        if (location.pathname.split('/').at(-1) === 'groups') {
            setCurrentPage(() => <T>Группировка Ajax устройств</T>);
            setShowModalTitle(() => <T>Добавить группу</T>);
            setPageType(() => 'groups');
        }
    }, [location.pathname]);

    const showModal = () => {
        setOpenModal(() => true);
    }

    const closeModal = () => {
        setOpenModal(() => false);
    }

    return (
        <AllComponent>
            <MainComponent title={currentPage} showButton={pageType !== 'statics' && pageType !== 'roles' && pageType !== 'languages' && true} showModal={showModal}>
                <AllWebComponent type={pageType} />
            </MainComponent>
            <AllModalComponent modalTitle={showModalTitle} openModal={openModal} setOpenModal={closeModal} type={pageType} />
        </AllComponent>
    );
};

export default AllDataPage;