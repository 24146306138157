import React from 'react';
import MainComponent from "../components/common/main.component";
import UsersComponent from "../components/users.component";
import {Checkbox, Flex, Form, Input, Modal, notification, Select, Space, Spin} from "antd";
import RoleMethods from "../methods/role.methods";
import {UserMethods} from "../methods/user.methods";
import {phoneCodes} from "../phoneCodes";
import AllComponent from "../components/common/all.component";

const UsersPage = () => {
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [rolesData, setRolesData] = React.useState({});
    const [checkbox, setCheckbox] = React.useState(0);
    const [phoneCode, setPhoneCode] = React.useState(371);

    const [form] = Form.useForm();

    React.useEffect(() => {
        (async () => {
            const [roles] = await Promise.allSettled([
                RoleMethods.getRoles(),
            ])
            if (roles.status === "fulfilled") {
                setRolesData(roles.value.data);
            }
        })()
    }, [])

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} is required!',
        types: {
            // eslint-disable-next-line
            email: '${label} is not a valid email!',
            // eslint-disable-next-line
            tel: '${label} is not a valid phone number!',
        },
    };

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setLoadingForm(true);
        form.setFieldsValue("");
        UserMethods.addUser({...form.getFieldsValue(), phone_code: phoneCode, isActive: checkbox})
            .then(res => {
                form.resetFields();
                openNotificationWithIcon('success', 'Данные успешно добавлены', []);
                setTimeout(() => {
                    setIsModalOpen(false);
                    setLoadingForm(false);
                    window.location.reload();
                }, 1000);
            })
            .catch(error => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
        setCheckbox(false);
    };

    const toggleCheckbox = () => {
        if (checkbox === 0) {
            setCheckbox(() => 1)
        } else {
            setCheckbox(() => 0)
        }
    }

    const togglePhoneCode = (code) => {
        setPhoneCode(code);
    }

    const prefixSelector = (
        <Form.Item
            name="prefix"
            noStyle
            key={'phone_code'}
            rules={[
                {
                    required: true,
                },
            ]}
        >
            <Select style={{ width: 100}} defaultValue={phoneCode} onChange={togglePhoneCode}>
                {
                    Object.values(phoneCodes).map(code =>
                        <Select.Option key={code} value={code}>+ {code}</Select.Option>
                    )
                }
            </Select>
        </Form.Item>
    );

    return (
        <AllComponent>
            <MainComponent title="Users" showModal={showModal} showButton={true}>
                <UsersComponent />
                <Modal
                    title={'Add new user'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                >
                    {contextHolder}
                    {
                        loadingForm
                            ?
                            <Flex justify="center" align="center" style={{height: '176px'}}>
                                <Spin size="large" />
                            </Flex>
                            :

                            <Form
                                style={{ width: '100%'}}
                                form={form}
                                multipart={"form-data"}
                                layout="vertical"
                                validateMessages={validateMessages}
                            >
                                <Space size={"middle"} >
                                    <Form.Item
                                        hasFeedback
                                        label="Имя"
                                        name="first_name"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input size={"large"} />
                                    </Form.Item>

                                    <Form.Item
                                        hasFeedback
                                        label="Фамилия"
                                        name="last_name"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input size={"large"} />
                                    </Form.Item>

                                    <Form.Item
                                        hasFeedback
                                        label="Статус"
                                        name="isActive"
                                    >
                                        <Checkbox value={checkbox} onChange={toggleCheckbox} size={"large"} >
                                            Активный
                                        </Checkbox>
                                    </Form.Item>
                                </Space>

                                <Form.Item
                                    hasFeedback
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input size={"large"} />
                                </Form.Item>

                                <Form.Item
                                    hasFeedback
                                    label="Телефон"
                                    name="phone"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input addonBefore={prefixSelector} style={{ width: '100%' }} onKeyUp={e => e.target.value = e.target.value.replace(/\D/, '')} size={"large"} placeholder="Пример: 9999999" />
                                </Form.Item>

                                <Form.Item
                                    hasFeedback
                                    label="Роль"
                                    name="roleId"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select size={"large"} placeholder={"Выберите роль"} options={Object.values(rolesData).map(
                                        role => ({ label: role.name, value: role.id }))}
                                    />
                                </Form.Item>
                            </Form>
                    }
                </Modal>
            </MainComponent>
        </AllComponent>
    );
};

export default UsersPage;