import {$host} from "../http/axios";

export default class ConfigMethods {
    static getConfig = async () => {
        const {data} = await $host.post(`/config/get`);
        return data;
    }

    static updateConfig = async (formData) => {
        const {data} = await $host.post(`/config/update`, formData);
        return data;
    }
}