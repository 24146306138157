import React from 'react';
import {Button, Card, Form, Input, Typography, notification, Spin} from "antd";
import LOGO_IMG from "../img/logo.svg";
import BACK_ICON from "../icons/back.svg";
import {useNavigate} from "react-router-dom";
import {menu, userMenu} from "../routes";
import {UserMethods} from "../methods/user.methods";
import {Context} from "../index";
import {jwtDecode} from 'jwt-decode';
import {observer} from "mobx-react-lite";
import T from "./common/t";

const LoginComponent = () => {
    const {user_store, lang_store, static_store} = React.useContext(Context);
    const navigate = useNavigate();
    const [clientReady, setClientReady] = React.useState(false);
    const [step, setStep] = React.useState(1);
    const [loading, setLoading] = React.useState(false);
    const [loadingData, setLoadingData] = React.useState(false);
    const [email, setEmail] = React.useState("");

    const switchLang = (lang) => {
        localStorage.setItem('lang', lang);
        lang_store.setLang(lang);
    }

    // To disable submit button at the beginning.
    React.useEffect(() => {
        setClientReady(true);
    }, []);

    // React.useEffect(() => {
    //     setLoadingData(true)
    //     if (user) {
    //         const {roleValue} = user;
    //         user_store.setUserRole(roleValue);
    //         navigate(menu.dashboard.path);
    //     }
    //     setLoadingData(false)
    // }, [user, navigate, user_store]);

    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors[0],
        });
    };

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} ' + 'is required',
        types: {
            // eslint-disable-next-line
            email: '${label} ' + 'is not a valid email',
        },
    };

    const nextStep = (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('email', values.email);
        UserMethods.login(formData).then((response) => {
            setStep(() => step + 1);
            setEmail(() => values.email);
        }).catch((error) => {
            openNotificationWithIcon('error', error.response?.data?.message,[error.response?.data?.errors]);
        }).finally(() => {
            setLoading(false);
            form.setFieldValue('email', '');
            form.resetFields();
        });
    }

    const backStep = () => {
        setStep(() => step - 1);
        form.setFieldValue('code', '');
        form.resetFields();
    }

    const login = (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('email', email);
        formData.append('confirmCode', values.code);
        UserMethods.confirm(formData).then((response) => {
            localStorage.setItem('token', response.data);
            const {roleValue} = jwtDecode(response.data);
            user_store.setUserRole(roleValue);
            if (roleValue === "1" || roleValue === "2" || roleValue === "3") {
                return navigate(menu.dashboard.path);
            } else {
                return navigate(userMenu.rooms.path);
            }
        }).catch((error) => {
            openNotificationWithIcon('error', error.response?.data?.message, [error.response?.data?.errors]);
        }).finally(() => {
            setLoading(false);
            form.setFieldValue('code', '');
            form.resetFields();
        })
    }

    return (
        <>
            {contextHolder}
            {
                loadingData
                    ?
                        <div style={{height: '100vh', width: '100wv', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Spin size="large"/>
                        </div>
                    :
                step === 1
                    ?
                    <div style={{overflow: "auto"}}>
                        <Card className={"login-card"} style={{width: 400}}>
                            <div className={"logo-card"}>
                                <img src={LOGO_IMG} alt={"logo"}/>
                            </div>
                            <div style={{margin: '1rem auto', display: 'flex', justifyContent: 'center', gap: '1rem'}}>
                                {
                                    lang_store.isLangs.slice().reverse().map((lang) =>
                                        <Button onClick={() => switchLang(lang.isoCode)}
                                                danger={lang_store.isLang === lang.isoCode}>
                                            {lang.isoCode.toUpperCase()}
                                        </Button>
                                    )
                                }
                            </div>
                            {
                                static_store.isLoading
                                    ?
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "3rem 0 1rem 0"}}>
                                        <Spin size="large"/>
                                    </div>
                                    :
                                    <>
                                        <Typography.Title level={3} style={{textAlign: 'center', margin: '0'}}><T>Добро
                                            пожаловать!</T></Typography.Title>
                                        <Typography.Text type="secondary"
                                                         style={{
                                                             textAlign: 'center',
                                                             margin: '0 auto 12px auto',
                                                             display: 'block'
                                                         }}>
                                            <T>Введите ваш email и нажмите далее</T>
                                        </Typography.Text>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            validateMessages={validateMessages}
                                            onFinish={nextStep}
                                        >
                                            <Form.Item
                                                hasFeedback
                                                label={<T>Email</T>}
                                                name="email"
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        required: true,
                                                    },
                                                ]
                                                }
                                            >
                                                <Input size={"large"}/>
                                            </Form.Item>
                                            <Form.Item shouldUpdate>
                                                {
                                                    () => (
                                                        <Button size={"large"} style={{width: '100%', marginTop: '6px'}}
                                                                htmlType="submit"
                                                                loading={loading} disabled={
                                                            !clientReady ||
                                                            !form.isFieldsTouched(true) ||
                                                            !!form.getFieldsError().filter(({errors}) => errors.length).length
                                                            || loading
                                                        } type="primary"><T>Далее</T></Button>
                                                    )
                                                }
                                            </Form.Item>
                                            <div className={"flex ai-center jc-between mt-16"}>
                                                <Typography.Text type="secondary"><T>Нет аккаунта?</T></Typography.Text>
                                                <Typography.Link onClick={() => navigate(menu.registration.path)}
                                                                 type="primary"><T>Зарегистрируйтесь</T></Typography.Link>
                                            </div>
                                        </Form>
                                    </>
                            }
                        </Card>
                    </div>
                    :
                    <div style={{overflow: "auto"}}>
                        <Card className={"login-card"} style={{width: 400}}>
                            <div className={"logo-card"}>
                                <img src={LOGO_IMG} alt={"logo"}/>
                            </div>
                            <div onClick={backStep} className={"back-card"}>
                                <img src={BACK_ICON} alt={"logo"}/>
                            </div>
                            <div style={{margin: '1rem auto', display: 'flex', justifyContent: 'center', gap: '1rem'}}>
                                {
                                    lang_store.isLangs.slice().reverse().map((lang) =>
                                        <Button onClick={() => switchLang(lang.isoCode)}
                                                danger={lang_store.isLang === lang.isoCode}>
                                            {lang.isoCode.toUpperCase()}
                                        </Button>
                                    )
                                }
                            </div>
                            {
                                static_store.isLoading
                                    ?
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "3rem 0 1rem 0"}}>
                                        <Spin size="large"/>
                                    </div>
                                    :
                                    <>
                                        <Typography.Title level={3} style={{textAlign: 'center', margin: '0'}}><T>Добро
                                            пожаловать!</T></Typography.Title>

                                        <Typography.Text type="secondary"
                                                         style={{
                                                             textAlign: 'center',
                                                             margin: '0 auto 12px auto',
                                                             display: 'block'
                                                         }}><T>Введите
                                            ваш код из email и нажмите войти</T></Typography.Text>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            validateMessages={validateMessages}
                                            onFinish={login}
                                        >
                                            <Form.Item
                                                hasFeedback
                                                label={<T>Код</T>}
                                                name="code"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input maxLength={6}
                                                       onKeyUp={e => e.target.value = e.target.value.replace(/\D/, '')}
                                                       size={"large"}/>
                                            </Form.Item>
                                            <Form.Item shouldUpdate>
                                                {
                                                    () => (
                                                        <Button size={"large"} style={{width: '100%', marginTop: '6px'}}
                                                                htmlType="submit"
                                                                loading={loading} disabled={
                                                            !clientReady ||
                                                            !form.isFieldsTouched(true) ||
                                                            !!form.getFieldsError().filter(({errors}) => errors.length).length ||
                                                            loading
                                                        } type="primary"><T>Войти</T></Button>
                                                    )
                                                }
                                            </Form.Item>
                                            <div className={"flex ai-center jc-between mt-16"}>
                                                <Typography.Text type="secondary"><T>Нет аккаунта?</T></Typography.Text>
                                                <Typography.Link onClick={() => navigate(menu.registration.path)}
                                                                 type="primary"><T>Зарегистрируйтесь</T></Typography.Link>
                                            </div>
                                        </Form>
                                    </>
                            }
                        </Card>
                    </div>
            }
        </>
    );
};

export default observer(LoginComponent);