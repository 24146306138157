import {$host} from "../http/axios";

export default class NewsMethods {
    static addNews = async (formData) => {
        const {data} = await $host.post(`/news/add`, formData);
        return data;
    }
    static getOneNews = async (id) => {
        const {data} = await $host.post(`/news/get/${id}`);
        return data;
    }

    static getAll = async () => {
        const {data} = await $host.post(`/news/all?limit=1000000000`);
        return data;
    }

    static updateNews = async (id, formData) => {
        const {data} = await $host.post(`/news/update/${id}`, formData);
        return data;
    }

    static getByLangNews = async (id, isoCode) => {
        const {data} = await $host.post(`/news/get/${id}/lang/${isoCode}`);
        return data;
    }

    static deleteNews = async (id) => {
        const {data} = await $host.post(`/news/delete/${id}`);
        return data;
    }
}