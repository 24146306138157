import {$host} from "../http/axios";

export default class StaticMethods {
    static addStatic = async (formData) => {
        const {data} = await $host.post(`/static/add`, formData);
        return data;
    }

    static getStatics = async () => {
        const {data} = await $host.post(`/static/all?limit=1000000000`);
        return data;
    }

    static getStaticById = async (formData) => {
        const {data} = await $host.post(`/static/get/${formData}`);
        return data;
    }

    static getStaticByLang = async (isoCode) => {
        const {data} = await $host.post(`/static/lang/all/${isoCode}`);
        return data;
    }

    static updateStaticById = async (id, formData) => {
        const {data} = await $host.post(`/static/update/${id}`, formData);
        return data;
    }

    static deleteStatic = async (id) => {
        const {data} = await $host.post(`/static/delete/${id}`);
        return data;
    }
}