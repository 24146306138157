import React from 'react';
import RegistrationComponent from "../components/registration.component";
import AllComponent from "../components/common/all.component";

const RegistrationPage = () => {
    return (
        <AllComponent>
            <RegistrationComponent />
        </AllComponent>
    );
};

export default RegistrationPage;