import {makeAutoObservable} from "mobx";

export default class StaticStore{
    constructor() {
        this._statics = {};
        this._loading = true;
        makeAutoObservable(this);
    }

    setStatics(data) {this._statics = data}

    get isStatics() {return this._statics}

    setLoading(data) {this._loading = data}

    get isLoading() {return this._loading}
}