import React from 'react';
import MainComponent from "../components/common/main.component";
import UserComponent from "../components/user.component";
import AllComponent from "../components/common/all.component";

const UserPage = () => {
    return (
        <AllComponent>
            <MainComponent title="User" showButton={false}>
                <UserComponent />
            </MainComponent>
        </AllComponent>
    );
};

export default UserPage;