import {$host} from "../http/axios";

export default class RoomMethods {
    static addRoom = async (formData) => {
        const {data} = await $host.post(`/room/add`, formData);
        return data;
    }

    static getRooms = async () => {
        const {data} = await $host.post(`/room/all?limit=1000`);
        return data;
    }

    static getRoomById = async (formData) => {
        const {data} = await $host.post(`/room/get/${formData}`);
        return data;
    }

    static getRoomByLang = async (id, isoCode, formData) => {
        const {data} = await $host.post(`/room/get/${id}/lang/${isoCode}`, formData);
        return data;
    }

    static startRent = async (id, formData) => {
        const {data} = await $host.post(`/room/start/${id}`, formData);
        return data;
    }

    static stopRent = async (id, formData) => {
        const {data} = await $host.post(`/room/stop/${id}`, formData);
        return data;
    }

    static updateRoom = async (id, formData) => {
        const {data} = await $host.post(`/room/update/${id}`, formData);
        return data;
    }

    static deleteRoom = async (id) => {
        const {data} = await $host.post(`/room/delete/${id}`);
        return data;
    }
}