import React from 'react';
import MainComponent from "../components/common/main.component";
import {Flex, Form, Input, Modal, notification, Spin, Select} from "antd";
import ServicesComponent from "../components/services.component";
import DeviceMethods from "../methods/device.methods";
import AllComponent from "../components/common/all.component";

const ServicesPage = () => {
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [service, setService] = React.useState('ajax');

    const [form] = Form.useForm();

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} is required!',
        types: {
            // eslint-disable-next-line
            email: '${label} is not a valid email!',
            // eslint-disable-next-line
            tel: '${label} is not a valid phone number!',
        },
    };

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setLoadingForm(true);
        form.setFieldsValue("");
        DeviceMethods.addService(service,{name: service.charAt(0).toUpperCase() + service.slice(1), ...form.getFieldsValue()})
            .then(res => {
                form.resetFields();
                openNotificationWithIcon('success', 'Данные успешно добавлены', []);
                setTimeout(() => {
                    setIsModalOpen(false);
                    setLoadingForm(false);
                }, 1000);
            })
            .catch(error => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };

    const switchService = (value) => {
        setService(() => value);
    };

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <AllComponent>
            <MainComponent title="Services" showModal={showModal} showButton={true}>
                <ServicesComponent />
                <Modal
                    title={'Add new service'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                >
                    {contextHolder}
                    {
                        loadingForm
                            ?
                            <Flex justify="center" align="center" style={{height: '176px'}}>
                                <Spin size="large" />
                            </Flex>
                            :

                            <Form
                                style={{ width: '100%'}}
                                form={form}
                                multipart={"form-data"}
                                layout="vertical"
                                validateMessages={validateMessages}
                            >
                                <Form.Item
                                    label="Сервис"
                                    name="service"
                                    layout="vertical"
                                >
                                    <Select
                                        style={{ width: '100%'}}
                                        showSearch
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        onChange={switchService}
                                        defaultValue={service}
                                        // onSearch={onSearch}
                                        filterOption={filterOption}
                                        options={[
                                            {
                                                value: 'ajax',
                                                label: 'Ajax',
                                            },
                                            {
                                                value: 'tuya',
                                                label: 'Tuya',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                                {
                                    service === 'ajax'
                                    ?
                                        <>
                                            <Form.Item
                                                hasFeedback
                                                label="Логин"
                                                name="login"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                            <Form.Item
                                                hasFeedback
                                                label="Пароль"
                                                name="passwordHash"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                            <Form.Item
                                                hasFeedback
                                                label="X Api Key"
                                                name="xApiKey"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                        </>
                                    :
                                        <>
                                            <Form.Item
                                                hasFeedback
                                                label="Логин"
                                                name="email"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                            <Form.Item
                                                hasFeedback
                                                label="Пароль"
                                                name="password"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                            <Form.Item
                                                hasFeedback
                                                label="Client Id"
                                                name="clientId"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                            <Form.Item
                                                hasFeedback
                                                label="Client Secret"
                                                name="clientSecret"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                            <Form.Item
                                                hasFeedback
                                                label="Project Code"
                                                name="projectCode"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                        </>
                                }
                            </Form>
                    }
                </Modal>
            </MainComponent>
        </AllComponent>
    );
};

export default ServicesPage;