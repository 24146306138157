import {makeAutoObservable} from "mobx";

export default class UsersStore{
    constructor() {
        this._userRole = "";
        this._messages = {};
        makeAutoObservable(this);
    }

    setUserRole(data) {this._userRole = data}

    get isUserRole() {return this._userRole}

    setUserMessages(data) {this._messages = data}

    get isUserMessages() {return this._messages}
}