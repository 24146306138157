import React from 'react';
import {Button, Flex, Form, Input, Modal, notification, Spin, Tabs} from "antd";
import T from "../../common/t";
import LanguageMethods from "../../../methods/language.methods";
import {useLocation, useNavigate} from "react-router-dom";
import AdvantageMethods from "../../../methods/advantage.methods";
import StockMethods from "../../../methods/stock.methods";
import GoogleMapReact from "google-map-react";
import {defaultProps, Marker} from "./addStockForm.component";

const UpdateStockFormComponent = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [pubDate, setPubDate] = React.useState('');
    const [cover, setCover] = React.useState(false);
    const [file, setFile] = React.useState({});
    const [pub, setPub] = React.useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [languages, setLanguages] = React.useState({});
    const [updateDate, setUpdateDate] = React.useState('');
    const [oneAdvantage, setOneAdvantage] = React.useState({});
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [marker, setMarker] = React.useState({});

    const [form] = Form.useForm();

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} ' + 'is required',
    };

    const changeCover = () => {
        setCover(!cover);
    }

    const handleChange = (e) => {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
            setFile({name: e.target.files[0].name, data: reader.result});
        }
    }

    const getDataOneNews = React.useCallback(() => {
        (async () => {
            const [
                oneAdvantage,
                languages
            ] = await Promise.allSettled([
                StockMethods.getOneStock(location.pathname.split('/').at(-1)),
                LanguageMethods.getLanguages(),
            ]);
            if (
                oneAdvantage.status === 'fulfilled' &&
                languages.status === 'fulfilled'
            ) {
                setOneAdvantage(() => oneAdvantage.value.data)
                setLanguages(() => languages.value.data)
            }
        })()
    }, [location.pathname])



    React.useEffect(() => {
        getDataOneNews()
    }, []);

    React.useEffect(() => {
        if (Object.keys(languages).length > 0 && Object.keys(oneAdvantage).length > 0) {
            console.log(oneAdvantage)
            Object.values(languages).map(lang => {
                form.setFieldValue(`name`, oneAdvantage.name)
                form.setFieldValue(`address`, oneAdvantage.address)
                form.setFieldValue(`url`, oneAdvantage.url)
                if (lang.isDefault) {
                    form.setFieldValue(`cover`, oneAdvantage.img)
                    setPub(() => oneAdvantage.isActive)
                }
                const obj = {};
                obj.lat = oneAdvantage.lat
                obj.lng = oneAdvantage.lng
                setMarker(() => obj)
                setPubDate(() => oneAdvantage.createdAt)
                setUpdateDate(() => oneAdvantage.updatedAt)
            })
            setLoading(false)
        }
    }, [form, languages, oneAdvantage]);

    const handleOk = () => {
        setLoadingForm(true);


        StockMethods.updateStock(location.pathname.split('/').at(-1), {
            cover: Object.keys(file).length > 0 ? file : null,
            isActive: pub,
            name: form.getFieldValue(`name`),
            address: form.getFieldValue(`address`),
            url: form.getFieldValue(`url`),
            lat: marker.lat,
            lng: marker.lng,

        })
            .then(res => {
                form.resetFields();
                openNotificationWithIcon('success', <T>Адрес успешно обновлен</T>, []);
                setTimeout(() => {
                    setIsModalOpen(false);
                    setLoadingForm(false);
                    window.location.reload();
                }, 1000);
            })
            .catch(error => {
                alert(error)
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    const deleteNews = () => {
        StockMethods.deleteStock(location.pathname.split('/').at(-1))
            .then(() => {
                openNotificationWithIcon('success', <T>Адрес удален</T>, null);
                setTimeout(() => {
                    navigate(-1)
                }, 1000);
            })
            .catch((err) => {
                openNotificationWithIcon('error', <T>Ошибка при удалении</T>, err.response?.data?.errors);
            })
    }


    const showModal = () => {
        setIsModalOpen(true);
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const handlePub = () => {
        setPub(() => !pub)
    }

    const clickMapHandler = (e) => {
        const obj = {}
        obj.lat = e.lat;
        obj.lng = e.lng;
        setMarker(() => obj);
    }

    return (
        <>
            {contextHolder}
            {
                loadingForm
                    ?
                    <Flex justify="center" align="center" style={{height: '176px'}}>
                        <Spin size="large" />
                    </Flex>
                    :
                    <>
                        <Form
                            name="update_room"
                            style={{ width: '100%', padding: '1rem'}}
                            form={form}
                            multipart={"form-data"}
                            layout="vertical"
                            validateMessages={validateMessages}
                            onFinish={handleOk}
                        >
                            {
                                loading
                                    ?
                                    <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                                        <Spin size="large" />
                                    </Flex>
                                    :
                                    <Flex justify="space-between" gap={20}>
                                        <Flex vertical gap={20} style={{width: '100%'}}>
                                            {
                                                (form.getFieldValue(`cover`) && !cover) ?
                                                    <Form.Item
                                                        style={{width: '100%', marginBottom: '1rem'}}
                                                        label={<T>Обложка</T>}
                                                        name={`cover`}
                                                    >
                                                        <Flex justify="center" align="center" gap={20}>
                                                            <img style={{objectFit: 'contain'}} width={200} height={100}
                                                                 src={process.env.REACT_APP_STATIC_URL + '/stock/' + location.pathname.split('/').at(-1) + '/' + form.getFieldValue(`cover`)}
                                                                 alt="Обложка"/>
                                                            <Button onClick={changeCover}><T>Изменить</T></Button>
                                                        </Flex>
                                                    </Form.Item>
                                                    :
                                                    <Form.Item
                                                        hasFeedback
                                                        style={{width: '100%', marginBottom: '1rem'}}
                                                        label={<T>Обложка</T>}
                                                        name={`cover`}
                                                    >
                                                        <Flex justify="center" align="center" gap={20}>
                                                            <img style={{objectFit: 'contain'}} width={200} height={100}
                                                                 src={file.data}
                                                                 alt="Обложка"/>
                                                            <Input type='file' size={"large"} onChange={handleChange}/>
                                                        </Flex>

                                                    </Form.Item>
                                            }

                                            <Form.Item
                                                hasFeedback
                                                style={{width: '100%', marginBottom: 0}}
                                                label={<T>Заголовок</T>}
                                                name={`name`}
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                            <Form.Item
                                                hasFeedback
                                                style={{width: '100%', marginBottom: 0}}
                                                label={<T>Адрес</T>}
                                                name={`address`}
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                            <Form.Item label={<T>Нажмите на карту, чтобы изменить позицыю маркера</T>}>
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{key: process.env.REACT_APP_GMAP_API}}
                                                    defaultCenter={defaultProps.center}
                                                    defaultZoom={defaultProps.zoom}
                                                    style={{
                                                        height: '40rem',
                                                        width: '100%'
                                                    }}
                                                    onClick={(e) => clickMapHandler(e)}
                                                >
                                                    {
                                                        Object.keys(marker).length > 0 && <Marker lat={marker.lat} lng={marker.lng} />
                                                    }

                                                </GoogleMapReact>
                                            </Form.Item>
                                            <Form.Item
                                                hasFeedback
                                                style={{width: '100%', marginBottom: 0}}
                                                label={<T>Ссылка (Внешняя)</T>}
                                                name={`url`}
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                        </Flex>
                                        <Flex vertical style={{width: '20%'}}>
                                            <Flex vertical style={{width: '100%', position: 'sticky', top: '1rem'}}>
                                                <Form.Item
                                                    label={<T>Опубликовать</T>}
                                                    name={'isActive'}
                                                >
                                                    <Input size={"large"} type={"checkbox"} checked={pub} onChange={handlePub}/>
                                                </Form.Item>
                                                <Form.Item
                                                    label={<T>Дата публикации</T>}
                                                >
                                                    <Input type={"dateTime"} disabled value={
                                                        pubDate.replaceAll('-', '.')
                                                            .replace('T', ' ')
                                                    }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label={<T>Дата обновления</T>}
                                                >
                                                    <Input type={"dateTime"} disabled value={
                                                        updateDate.replaceAll('-', '.')
                                                            .replace('T', ' ')
                                                    }
                                                    />
                                                </Form.Item>
                                                <Form.Item shouldUpdate>
                                                    {
                                                        () => (
                                                            <Button
                                                                size={"large"}
                                                                style={{width: '100%', marginTop: '6px'}}
                                                                loading={false}
                                                                type="primary"
                                                                htmlType="submit"
                                                            >
                                                                <T>Сохранить</T>
                                                            </Button>
                                                        )
                                                    }
                                                </Form.Item>
                                                <Button
                                                    size={"large"}
                                                    style={{width: '100%', marginTop: '6px'}}
                                                    loading={false}
                                                    onClick={showModal}
                                                    danger={true}
                                                >
                                                    <T>Удалить адрес</T>
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                            }
                        </Form>

                        <Modal title={<T>Удалить адрес</T>} open={isModalOpen} onOk={deleteNews} onCancel={handleCancel}>
                            <p>{<T>Вы точно хотите удалить адрес</T>} ?</p>
                        </Modal>
                    </>
            }
        </>
    );
};

export default UpdateStockFormComponent;