import React from 'react';
import {Card, Flex, Input, notification, Spin, Table} from "antd";
import DeviceMethods from "../methods/device.methods";
import T from "./common/t";
import {observer} from "mobx-react-lite";

const columns = [
    {
        title: <T>Имя</T>,
        dataIndex: 'name',
        key: 'name',
        width: '15%',
    },
    {
        title: <T>Логин</T>,
        dataIndex: 'login',
        width: '75%',
        key: 'login',
    }
];

const ServicesComponent = () => {
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            const [ajax, tuya] = await Promise.allSettled([
                DeviceMethods.getServices('ajax'),
                DeviceMethods.getServices('tuya')
            ])
            if (ajax.status === 'fulfilled' && tuya.status === 'fulfilled') {
                setData(() => [...Object.values(ajax.value.data), ...Object.values(tuya.value.data)]);
                setLoading(false);
                console.log(Object.values(tuya.value.data))
            }
        })();
    }, []);

    const [searchInput, setSearchInput] = React.useState('');
    const [filteredData, setFilteredData] = React.useState([]);
    const [emptyFilter, setEmptyFilter] = React.useState(false);
    const [searched, setSearched] = React.useState(false);

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors[0],
        });
    };

    const search = (e) => {
        setSearchInput(e.target.value);
    }

    React.useEffect(() => {
        const filtered = data.filter(d =>
            d.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            d.login.toLowerCase().includes(searchInput.toLowerCase())
        )
        setTimeout(() => {
            setFilteredData(() => filtered)
            setSearched(() => true)
            if (filtered.length === 0) {
                setEmptyFilter(() => true)
            } else {
                setEmptyFilter(() => false)
            }
        }, 300);
    }, [searchInput, data]);

    const err = React.useCallback(() => {
        openNotificationWithIcon('error', <T>По вашему запросу ничего не найдено</T>, []);
    }, []);

    React.useMemo(() =>  {
        if (emptyFilter) {
            err();
        }
    }, [emptyFilter, err]);

    return (
        loading
            ?
            <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                <Spin size="large" />
            </Flex>
            :
            <>
                {searched && contextHolder}
                <Flex justify="space-between">
                    <Flex vertical gap={20} style={{width: '100%'}}>
                        <Table
                            style={{maxWidth: '100%', borderRight: '1px solid #f0f0f0'}}
                            columns={columns}
                            pagination={false}
                            scroll={{x:'max-content', y: 'calc(100vh - 13rem)'}}
                            dataSource={
                                filteredData.length > 0
                                    ?
                                    filteredData
                                    :
                                    data
                            } />
                    </Flex>
                    <Flex vertical style={{width: '20%'}}>
                        <Flex vertical style={{width: '100%', position: 'sticky', top: '0'}}>
                            <Card style={{boxShadow: 'none'}} bordered={false}>
                                <Input placeholder="Search..." value={searchInput} onChange={search} />
                            </Card>
                        </Flex>
                    </Flex>
                </Flex>
            </>
    );
};

export default observer(ServicesComponent);