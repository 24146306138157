import React from 'react';
import {Button, Checkbox, Flex, Form, Input, Select, Spin} from "antd";
import LocationMethods from "../../methods/location.methods";
import T from "../common/t";
import SectorMethods from "../../methods/sector.methods";

const AddSectorFormComponent = ({openNotificationWithIcon, validateMessages}) => {
    const [form] = Form.useForm();
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [locationId, setLocationId] = React.useState(null);
    const [locations, setLocations] = React.useState({});


    const toggleLocationId = (value) => {
        setLocationId(() => value)
    }

    React.useEffect(() => {
        LocationMethods.getLocations()
            .then(res => {setLocations(() => res.data)})
            .catch(error => openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors))
            .finally(() => {
                setLoadingForm(() => false)
            });
        // eslint-disable-next-line
    }, []);

    const handleOk = () => {
        setLoadingForm(true);
        SectorMethods.addSector({...form.getFieldsValue(), locationId})
            .then(() => {
                form.resetFields();
                openNotificationWithIcon('success', <T> Сектор успешно добавлен</T>, []);
                setTimeout(() => {
                    setLoadingForm(false);
                    window.location.reload();
                }, 1000);
            })
            .catch(error => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    return (

        loadingForm
        ?
            <Flex justify="center" align="center" style={{height: '176px'}}>
                <Spin size="large" />
            </Flex>
        :
            <Form
                style={{ width: '100%'}}
                form={form}
                multipart={"form-data"}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={handleOk}
            >
                <Form.Item
                    hasFeedback
                    label={<T>Название</T>}
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Локация</T>}
                    name="locationId"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        placeholder="Выберите локацию"
                        size={"large"}
                        options={Object.values(locations).map(item => ({value: item.id, label: item.name}))}
                        onChange={toggleLocationId}
                    />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Опубликовано</T>}
                    name="isActive"
                >
                    <Checkbox size={"large"} checked={true}/>
                </Form.Item>
                <Form.Item style={{marginBottom: 0}}>
                    <Button style={{width: '100%'}} size={"large"} type="primary" htmlType="submit" ><T>Сохранить</T></Button>
                </Form.Item>
            </Form>
    );
};

export default AddSectorFormComponent;
