import {$host} from "../http/axios";

export default class DeviceMethods {
    static addService = async (service, formData) => {
        if (service === 'ajax') {
            const {data} = await $host.post(`/ajax/add`, formData);
            return data;
        } else if (service === 'tuya') {
            const {data} = await $host.post(`/tuya/add_service`, formData);
            return data;
        }

    }

    static getServices = async (service) => {
        if (service === 'ajax') {
            const {data} = await $host.post(`/ajax/all?limit=1000`);
            return data;
        } else if (service ===  'tuya') {
            const {data} = await $host.post(`/tuya/all?limit=1000`);
            return data;
        }
    }

    static getDevices = async (service) => {
        if (service === 'ajax') {
            const {data} = await $host.post(`/ajax/device/all?limit=1000`);
            return data;
        } else if (service ===  'tuya') {
            const {data} = await $host.post(`/tuya/device/all?limit=1000`);
            return data;
        }
    }

    static getDeviceById = async (service, formData) => {
        if (service === 'ajax') {
            const {data} = await $host.post(`/ajax/device/get/${formData}`);
            return data;
        } else if (service ===  'tuya') {
            const {data} = await $host.post(`/tuya/device/get/${formData}`);
            return data;
        }
    }

    static getDeviceInfo = async (service, formData) => {
        if (service === 'ajax') {
            const {data} = await $host.post(`/ajax/device/info`, formData);
            return data;
        }
    }

    static setCommand = async (service, formData) => {
        if (service === 'ajax') {
            const {data} = await $host.post(`/ajax/device/command`, formData);
            return data;
        } else if (service ===  'tuya') {
            const {data} = await $host.post(`/tuya/set_device_command`, formData);
            return data;
        }
    }

    static setCommandOpenDoor = async (formData) => {
        const {data} = await $host.post(`/tuya/set_device_open_door`, formData);
        return data;
    }

    static getTuyaDeviceProperties = async (formData) => {
        const {data} = await $host.post(`/tuya/get_device_properties`, formData);
        return data;
    }

    static syncDevices = async (service, formData) => {
        if (service === 'ajax') {
            const {data} = await $host.post(`/ajax/sync`, formData);
            return data;
        } else if (service === 'tuya') {
            const {data} = await $host.post(`/tuya/sync`, formData);
            return data;
        }
    }

    static getPswTicketTuya = async (formData) => {
        const {data} = await $host.post(`/tuya/get_device_password_ticket`, formData);
        return data;
    }

    static getTuyaDeviceStatus = async (formData) => {
        const {data} = await $host.post(`/tuya/get_device_status`, formData);
        return data;
    }

    static getTuyaDeviceState = async (formData) => {
        const {data} = await $host.post(`/tuya/get_device_state`, formData);
        return data;
    }
}