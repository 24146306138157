import React from "react";
import {Context} from "../index";
import {observer} from "mobx-react-lite";

export const useLang = () => {
    const [lang, setLang] = React.useState("");
    const {lang_store} = React.useContext(Context);

    React.useEffect(() => {
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'lv');
            lang_store.setLang('lv');
            setLang(() => 'lv');
        } else {
            setLang(() => localStorage.getItem('lang'));
            lang_store.setLang(localStorage.getItem('lang'));
        }
    }, []);

    return observer(lang);
};