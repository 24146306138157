import React from 'react';
import {Button, Checkbox, Flex, Form, Input, Spin, Select} from "antd";
import T from "../../common/t";
import UnitTypesMethods from "../../../methods/unitTypes.methods";
import UnitMethods from "../../../methods/units.methods";
import ReactQuill from "react-quill";
import LanguageMethods from "../../../methods/language.methods";
import {toolbarOptions} from "../oneWeb.component";

const AddTypeFormComponent = ({openNotificationWithIcon, validateMessages}) => {
    const [form] = Form.useForm();
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const [unitTypes, setUnitTypes] = React.useState([]);
    const [languages, setLanguages] = React.useState({});
    const [select, setSelect] = React.useState([]);
    const [unitTypeId, setUnitTypeId] = React.useState({value: 0, label: 'Выберите тип', disabled: true});


    const handleChange = (e) => {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
            setFile({name: e.target.files[0].name, data: reader.result});
        }
    }

    React.useEffect(() => {
        (async () => {
            const [languages, unitTypes] = await Promise.allSettled([
                LanguageMethods.getLanguages(),
                UnitTypesMethods.getAllByLang(localStorage.getItem('lang'))
            ])
            if (languages.status === 'fulfilled' && unitTypes.status === 'fulfilled') {
                setUnitTypes(() => unitTypes.value.data);
                setLanguages(() => Object.values(languages.value.data).filter((a) => a.isoCode === localStorage.getItem('lang'))[0]);
            }
        })()
    }, [])

    React.useEffect(() => {
        if (languages.isDefault) {
            for (const type of unitTypes) {
                setSelect((state) => [...state, {value: type.id, label: type.title}])
            }
        }
    }, [languages, unitTypes]);

    const handleChangeSelect = (value) => {
        setUnitTypeId(() => value)
    }


    const handleOk = () => {
        setLoadingForm(true);
        UnitMethods.addUnit({...form.getFieldsValue(), cover: file, unitTypeId: unitTypeId})
            .then(res => {
                form.resetFields();
                openNotificationWithIcon('success', <T>Помещение успешно добавлено</T>, []);
                setTimeout(() => {
                    setLoadingForm(false);
                    window.location.reload();
                }, 1000);
            })
            .catch(error => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    return (

        loadingForm
        ?
            <Flex justify="center" align="center" style={{height: '176px'}}>
                <Spin size="large" />
            </Flex>
        :
            <Form
                style={{ width: '100%'}}
                form={form}
                multipart={"form-data"}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={handleOk}
            >
                <Form.Item
                    hasFeedback
                    label={<T>Название помещения</T>}
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Размер (Текст)</T>}
                    name="size_text"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Размер (Номер)</T>}
                    name="size_number"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Цена</T>}
                    name="price"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    label={<T>Обложка (Обязательно)</T>}
                    // name={"img"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input type={"file"} onChange={handleChange}/>
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Ссылка (внешняя)</T>}
                    name="url"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Тип кладовки</T>}
                    name="unitTipeId"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select size={"large"}
                        onChange={handleChangeSelect}
                        options={select}
                    />

                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Текст</T>}
                    name="text"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <ReactQuill
                        style={{width: '100%', maxWidth: '100%', marginBottom: 0, marginTop: '1rem'}}
                        theme="snow"
                        modules={
                            {
                                toolbar: toolbarOptions
                            }
                        }
                    />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Опубликовано</T>}
                    name="isActive"
                >
                    <Checkbox size={"large"} checked={true}/>
                </Form.Item>
                <Form.Item style={{marginBottom: 0}}>
                    <Button style={{width: '100%'}} size={"large"} type="primary" htmlType="submit" ><T>Сохранить</T></Button>
                </Form.Item>
            </Form>
    );
};

export default AddTypeFormComponent;
