import React from 'react';
import {Layout, Menu} from "antd";
import {menu, securityMenu, userMenu} from "../../routes";
import {useLocation, useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";

const { Sider } = Layout;

const SidebarComponent = () => {
    const {user_store} = React.useContext(Context);
    const navigate = useNavigate();
    const locations = useLocation();
    const [leftMenu, setLeftMenu] = React.useState(null);

    const getMenu = (menus) => {
        return Object.values(menus).map((item, index) => (
            item.type === 'menu'
            &&
            {
                key: item.path,
                icon: React.createElement(item.icon),
                label: item.label,
            })
        );
    }

    React.useMemo(() => {
        if (Number(user_store.isUserRole) === 1 || Number(user_store.isUserRole) === 2) {
            const items = getMenu(menu);
            setLeftMenu(() => [...items])
        } else if (Number(user_store.isUserRole) === 3) {
            const items = getMenu(securityMenu);
            setLeftMenu(() => [...items]);
        } else if (Number(user_store.isUserRole) === 999) {
            const items = getMenu(userMenu);
            setLeftMenu(() => [...items]);
        }
    }, [user_store.isUserRole]);

    const clickMenu = (e) => {
        return navigate(e.key);
    }

    return (
        <Sider
            style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
            trigger={null} collapsible collapsed={true}
        >
            <Menu onClick={clickMenu} theme="dark" defaultSelectedKeys={[locations.pathname]} items={
                leftMenu && leftMenu
            } />
        </Sider>
    );
};

export default observer(SidebarComponent);