import {$host} from "../http/axios";

export default class GroupMethods {
    static addGroup = async (formData) => {
        const {data} = await $host.post(`/groups/add`, formData);
        return data;
    }

    static getAll = async () => {
        const {data} = await $host.post(`/groups/all?limit=1000000000`);
        return data;
    }

    static getGroups = async () => {
        const {data} = await $host.post(`/groups/all?limit=1000`);
        return data;
    }

    static getGroupById = async (formData) => {
        const {data} = await $host.post(`/groups/get/${formData}`);
        return data;
    }

    static updateGroupById = async (id, formData) => {
        const {data} = await $host.post(`/groups/update/${id}`, formData);
        return data;
    }

    static switchGroup = async (formData) => {
        const {data} = await $host.post(`/ajax/switchGroup`, formData);
        return data;
    }

    static deleteGroup = async (id) => {
        const {data} = await $host.post(`/groups/delete/${id}`);
        return data;
    }
}