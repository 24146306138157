import React from 'react';
import {Link} from "react-router-dom";
import {Card, Flex, Form, Input, notification, Space, Spin, Table, Tabs, Tag, Typography} from "antd";
import DeviceMethods from "../methods/device.methods";
import T from "./common/t";
import {observer} from "mobx-react-lite";

/*TODO - сделать синхронизацию устройств*/

const ajaxColumns = [
    {
        title: <T>Название</T>,
        dataIndex: 'name',
        key: 'name',
        width: '15%',
    },
    {
        title: <T>Id хаба</T>,
        dataIndex: 'hubId',
        width: '15%',
        key: 'hubId',
    },
    {
        title: <T>Id устройства</T>,
        dataIndex: 'deviceId',
        width: '15%',
        key: 'deviceId',
    },
    {
        title: <T>Тип устрйства</T>,
        dataIndex: 'deviceType',
        width: '15%',
        key: 'deviceType',
    },
    {
        title: <T>Владелец</T>,
        dataIndex: 'service',
        width: '20%',
        key: 'service',
        render: (_, record) => (
            <Typography.Text>{record.service.name}</Typography.Text>
        )
    },
    {
        title: <T>Статус</T>,
        dataIndex: 'status',
        width: '10%',
        key: 'status',
        render: (_, record) => (
            <Tag color={record.status === 'online' ? 'green' : 'volcano'} key={record.status}>
                <T>{record.status.toUpperCase()}</T>
            </Tag>
        )
    },
    {
        title: '',
        key: 'action',
        width: '10%',
        render: (_, record) => (
            <Space size="middle">
                <Link to={`${record.id}?service=ajax`}><T>Перейти</T></Link>
            </Space>
        ),
    },
];

const tuyaColumns = [
    {
        title: <T>Название</T>,
        dataIndex: 'name',
        key: 'name',
        width: '15%',
    },
    {
        title: <T>Id устройства</T>,
        dataIndex: 'deviceId',
        width: '15%',
        key: 'deviceIdTuya',
    },
    {
        title: <T>Тип устройства</T>,
        dataIndex: 'deviceType',
        width: '15%',
        key: 'deviceTypeTuya',
    },
    {
        title: <T>Модель устройства</T>,
        dataIndex: 'deviceModel',
        width: '15%',
        key: 'deviceModelTuya',
    },
    {
        title: <T>Владелец</T>,
        dataIndex: 'serviceTuya',
        width: '20%',
        key: 'serviceTuya',
        render: (_, record) => (
            <Typography.Text>{record.service.name}</Typography.Text>
        )
    },
    {
        title: <T>Статус</T>,
        dataIndex: 'statusTuya',
        width: '10%',
        key: 'statusTuya',
        render: (_, record) => (
            <Tag color={record.status === 'online' ? 'green' : 'volcano'} key={record.status}>
                <T>{record.status.toUpperCase()}</T>
            </Tag>
        )
    },
    {
        title: '',
        key: 'actionTuya',
        width: '10%',
        render: (_, record) => (
            <Space size="middle">
                <Link to={`${record.id}?service=tuya`}><T>Перейти</T></Link>
            </Space>
        ),
    },
];

const DevicesComponent = () => {
    const [loading, setLoading] = React.useState(true);

    const [dataAjax, setDataAjax] = React.useState([]);
    const [dataTuya, setDataTuya] = React.useState([]);

    React.useEffect(() => {
        (async () => {
            const [
                ajax, tuya] = await Promise.allSettled([
                    DeviceMethods.getDevices('ajax'),
                    DeviceMethods.getDevices('tuya')
                ]
            )
            if (ajax.status === 'fulfilled' && tuya.status === 'fulfilled') {
                setDataAjax(() => Object.values(ajax.value.data));
                setDataTuya(() => Object.values(tuya.value.data));
                setLoading(false)
            }
        })()
    }, []);

    const [searchInput, setSearchInput] = React.useState('');
    const [searchInputTuya, setSearchInputTuya] = React.useState('');
    const [filteredData, setFilteredData] = React.useState([]);
    const [filteredDataTuya, setFilteredDataTuya] = React.useState([]);
    const [emptyFilter, setEmptyFilter] = React.useState(false);
    const [searched, setSearched] = React.useState(false);

    const search = (e) => {
        setSearchInput(e.target.value);
    }

    const searchTuya = (e) => {
        setSearchInputTuya(e.target.value);
    }

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors[0],
        });
    };

    React.useEffect(() => {
        const filtered = dataAjax.filter(d =>
            d.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            d.hubId.toLowerCase().includes(searchInput.toLowerCase()) ||
            d.deviceId.toLowerCase().includes(searchInput.toLowerCase()) ||
            d.deviceType.toLowerCase().includes(searchInput.toLowerCase())
        )
        setTimeout(() => {
            setFilteredData(() => filtered)
            setSearched(() => true)
            if (filtered.length === 0) {
                setEmptyFilter(() => true)
            } else {
                setEmptyFilter(() => false)
            }
        }, 300);
    }, [searchInput, dataAjax]);

    React.useEffect(() => {
        const filtered = dataTuya.filter(d =>
            d.name.toLowerCase().includes(searchInputTuya.toLowerCase()) ||
            d.deviceId.toLowerCase().includes(searchInputTuya.toLowerCase()) ||
            d.deviceType.toLowerCase().includes(searchInputTuya.toLowerCase()) ||
            d.deviceModel.toLowerCase().includes(searchInputTuya.toLowerCase())
        )
        setTimeout(() => {
            setFilteredDataTuya(() => filtered)
            setSearched(() => true)
            if (filtered.length === 0) {
                setEmptyFilter(() => true)
            } else {
                setEmptyFilter(() => false)
            }
        }, 300);
    }, [searchInputTuya, dataTuya]);

    const err = React.useCallback(() => {
        openNotificationWithIcon('error', <T>По вашему запросу ничего не найдено</T>, []);
    }, []);

    React.useMemo(() =>  {
        if (emptyFilter && searched.length > 0) {
            err();
        }
    }, [emptyFilter, err]);

    const services = [
        {
            id: 1,
            name: 'Ajax',
            children:
                <Flex justify="space-between">
                    <Flex vertical gap={20} style={{width: '100%'}}>
                        <Table
                            style={{maxWidth: '100%', borderRight: '1px solid #f0f0f0'}}
                            pagination={false}
                            columns={ajaxColumns}
                            dataSource={filteredData.length > 0 ? filteredData : dataAjax}
                            scroll={{x:'max-content', y: 'calc(100vh - 13rem)'}}
                        />
                    </Flex>

                    <Flex vertical style={{width: '20%'}}>
                        <Flex vertical style={{width: '100%', position: 'sticky', top: 0}}>
                            <Card style={{boxShadow: 'none'}} bordered={false}>
                                <Input placeholder="Search..." value={searchInput} onChange={search}/>
                            </Card>
                        </Flex>
                    </Flex>
                </Flex>
        },
        {
            id: 2,
            name: 'Tuya',
            children:
                <Flex justify="space-between">
                    <Flex vertical gap={20} style={{width: '100%'}}>
                        <Table
                            style={{maxWidth: '100%', borderRight: '1px solid #f0f0f0'}}
                            pagination={false}
                            columns={tuyaColumns}
                            dataSource={filteredDataTuya.length > 0 ? filteredDataTuya : dataTuya}
                            scroll={{x:'max-content', y: 'calc(100vh - 13rem)'}}
                        />
                    </Flex>

                    <Flex vertical style={{width: '20%'}}>
                        <Flex vertical style={{width: '100%', position: 'sticky', top: 0}}>
                            <Card style={{boxShadow: 'none'}} bordered={false}>
                                <Input placeholder="Search..." value={searchInputTuya} onChange={searchTuya}/>
                            </Card>
                        </Flex>
                    </Flex>
                </Flex>
        }
    ]

    return (
        loading
        ?
        <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
            <Spin size="large" />
        </Flex>
        :
        <>
            {searched && contextHolder}
            <div style={{padding: '16px'}}>
                <Tabs
                    defaultActiveKey="1"
                    size={'large'}
                    type={"card"}
                    items={
                        Object.values(services).map(item => {
                            return {
                                label: item.name,
                                key: item.id,
                                children: item.children
                            };
                        })
                    }
                />
            </div>
        </>
    );
};

export default observer(DevicesComponent);