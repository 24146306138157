import {$host} from "../http/axios";

export default class LanguageMethods {
    static addLocation = async (formData) => {
        const {data} = await $host.post(`/language/add`, formData);
        return data;
    }

    static getLanguages = async () => {
        const {data} = await $host.post(`/language/all?limit=1000`);
        return data;
    }

    static getLanguageById = async (formData) => {
        const {data} = await $host.post(`/language/get/${formData}`);
        return data;
    }
    static getLanguageByName = async (formData) => {
        const {data} = await $host.post(`/language/get/one`, formData);
        return data;
    }

    static updateLanguageById = async (formData) => {
        const {data} = await $host.post(`/language/update/${formData}`);
        return data;
    }
}