import {$host} from "../http/axios";

export default class PagesMethods {
    static addPage = async (formData) => {
        const {data} = await $host.post(`/page/add`, formData);
        return data;
    }
    static getPage = async (id) => {
        const {data} = await $host.post(`/page/get/${id}`);
        return data;
    }

    static getAll = async () => {
        const {data} = await $host.post(`/page/all`);
        return data;
    }

    static updatePage = async (id, formData) => {
        const {data} = await $host.post(`/page/update/${id}`, formData);
        return data;
    }

    static translatePage = async (id, formData) => {
        const {data} = await $host.post(`/page/translate/${id}`, formData);
        return data;
    }

    static getByLangPage = async (id, isoCode) => {
        const {data} = await $host.post(`/page/get/${id}/lang/${isoCode}`);
        return data;
    }

    static deletePage = async (id) => {
        const {data} = await $host.post(`/page/delete/${id}`);
        return data;
    }
}