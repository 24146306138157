import React from 'react';
import {Form, Modal, notification} from "antd";
import AddNewsFormComponent from "./forms/addNewsForm.component";
import AddUnitTypeFormComponent from "./forms/addUnitTypeForm.component";
import AddUnitFormComponent from "./forms/addUnitForm.component";
import AddSliderFormComponent from "./forms/addSliderForm.component";
import AddAdvantageFormComponent from "./forms/addAdvantageForm.component";
import AddPageFormComponent from "../panel/addPageForm.component";
import AddLocationFormComponent from "../panel/addLocationForm.component";
import AddSectorFormComponent from "../panel/addSectorForm.component";
import AddBannerFormComponent from "./forms/addBannerForm.component";
import AddStockFormComponent from "./forms/addStockForm.component";
import AddWebPageFormComponent from "./forms/addWebPageForm.component";
import AddGroupFormComponent from "../panel/addGroupForm.component";

const AllModalComponent = ({modalTitle, openModal, setOpenModal, type}) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    React.useEffect(() => {
        if (openModal) {
            setIsModalOpen(() => true)
        }
    }, [openModal])

    const [form] = Form.useForm();

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} '  + 'is required',
        types: {
            // eslint-disable-next-line
            email: '${label} ' + 'is not a valid email',
            // eslint-disable-next-line
            tel: '${label} ' + 'is not a valid phone number',
        },
    };

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    const handleCancel = () => {
        setIsModalOpen(() => false);
        setOpenModal(() => false)
        form.resetFields();
    };

    return (
        <Modal
            title={modalTitle} open={isModalOpen}  onCancel={handleCancel}
            footer={null}
        >
            {contextHolder}
            {
                type === 'news' && <AddNewsFormComponent openNotificationWithIcon={openNotificationWithIcon} validateMessages={validateMessages} form={form} />
            }
            {
                type === 'unitTypes' && <AddUnitTypeFormComponent openNotificationWithIcon={openNotificationWithIcon} validateMessages={validateMessages} form={form} />
            }
            {
                type === 'units' && <AddUnitFormComponent openNotificationWithIcon={openNotificationWithIcon} validateMessages={validateMessages} form={form} />
            }
            {
                type === 'sliders' && <AddSliderFormComponent openNotificationWithIcon={openNotificationWithIcon} validateMessages={validateMessages} form={form} />
            }
            {
                type === 'advantages' && <AddAdvantageFormComponent openNotificationWithIcon={openNotificationWithIcon} validateMessages={validateMessages} form={form} />
            }
            {
                type === 'pages' && <AddPageFormComponent openNotificationWithIcon={openNotificationWithIcon} validateMessages={validateMessages} form={form} />
            }
            {
                type === 'locations' && <AddLocationFormComponent openNotificationWithIcon={openNotificationWithIcon} validateMessages={validateMessages} form={form} />
            }
            {
                type === 'sectors' && <AddSectorFormComponent openNotificationWithIcon={openNotificationWithIcon} validateMessages={validateMessages} form={form} />
            }
            {
                type === 'banners' && <AddBannerFormComponent openNotificationWithIcon={openNotificationWithIcon} validateMessages={validateMessages} form={form} />
            }
            {
                type === 'stock' && <AddStockFormComponent openNotificationWithIcon={openNotificationWithIcon} validateMessages={validateMessages} form={form} />
            }
            {
                type === 'web_pages' && <AddWebPageFormComponent openNotificationWithIcon={openNotificationWithIcon} validateMessages={validateMessages} form={form} />
            }
            {
                type === 'groups' && <AddGroupFormComponent openNotificationWithIcon={openNotificationWithIcon} validateMessages={validateMessages} form={form} />
            }
        </Modal>
    );
};

export default AllModalComponent;