import React from 'react';
import {Link, useLocation} from "react-router-dom";
import {
    Flex,
    Form,
    Input,
    notification,
    Spin,
    Select,
    DatePicker,
    Space,
    Checkbox,
    Empty,
    Divider, Tag, Card, Button, Tabs
} from "antd";
import LocationMethods from "../methods/location.methods";
import RoomMethods from "../methods/room.methods";
import LanguageMethods from "../methods/language.methods";
import dayjs from 'dayjs';
import {menu} from "../routes";
import SectorMethods from "../methods/sector.methods";
import DeviceMethods from "../methods/device.methods";
import T from "./common/t";
import {observer} from "mobx-react-lite";
import {useAuth} from "../hooks/useAuth";
import {PoweroffOutlined} from "@ant-design/icons";
import {UserMethods} from "../methods/user.methods";

/*TODO - готово*/

const RoomByLangComponent = () => {
    const [loading, setLoading] = React.useState(true);
    const [languages, setLanguages] = React.useState({});
    const location = useLocation();

    const user = useAuth();

    const [room, setRoom] = React.useState({});
    const [locations, setLocations] = React.useState({});
    const [startRent, setStartRent] = React.useState(null);
    const [stopRent, setStopRent] = React.useState(null);
    const [locationId, setLocationId] = React.useState(0);
    const [checkedSectors, setCheckedSectors] = React.useState([]);
    const [checkedDevices, setCheckedDevices] = React.useState([]);
    const [devices, setDevices] = React.useState({});
    const [deviceProperties, setDeviceProperties] = React.useState([]);
    const [userName, setUserName] = React.useState('');
    const [tuyaDevices, setTuyaDevisec] = React.useState({});

    const [form] = Form.useForm();

    const [api, contextHolder] = notification.useNotification();

    React.useEffect(() =>  {
        (async  () =>  {
            UserMethods.getProfile().then(res  =>  {
                setUserName(() => res.data.first_name + ' ' + res.data.last_name);
            })
        })()
    }, [user]);

    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} ' + 'is required',
    };

    // React.useEffect(() => {
    //     if (Object.keys(room).length > 0 && room.tuya_devices.length > 0) {
    //         room.tuya_devices.forEach((device, index) => {
    //             DeviceMethods.getTuyaDeviceProperties({device_id: device.deviceId}).then((response) => {
    //                 console.log(`response ${index}`, response)
    //                 // setDeviceProperties(() => response.result.properties)
    //                 if (response.result && response.result.properties) {
    //                     setDeviceProperties((preRes) => [...preRes, {device, prop: response.result.properties}])
    //                 }
    //                 if (!response.success) {
    //                     openNotificationWithIcon('error', <T>{response.msg}</T>, [device.name])
    //                 }
    //             })
    //         })
    //     }
    // }, [room]);


    const getDataRoom = React.useCallback(() => {
        (async () => {
            const [
                room,
                locations,
                languages,
                sectors,
                devices,
            ] = await Promise.allSettled([
                RoomMethods.getRoomByLang(location.pathname.split('/').at(-2), location.pathname.split('/').at(-1)),
                LocationMethods.getLocations(),
                LanguageMethods.getLanguages(),
                SectorMethods.getSectors(),
                DeviceMethods.getDevices('ajax'),
            ]);
            if (
                room.status === 'fulfilled' &&
                locations.status === 'fulfilled' &&
                languages.status === 'fulfilled' &&
                sectors.status === 'fulfilled' &&
                devices.status === 'fulfilled'
            ) {
                setRoom(room.value.data)
                setLocations(locations.value.data);
                setLanguages(languages.value.data);
                setStartRent(() => dayjs(Object.values(room.value.data)[0].startRent))
                setStopRent(() => dayjs(Object.values(room.value.data)[0].stopRent))
                if (room.value.data.sectors.length > 0) {
                    setLocationId(room.value.data.sectors[0].locationId);
                }
                const tmpSect = []
                room.value.data.sectors.forEach(sector => {
                    tmpSect.push(sector.name)
                })
                setCheckedSectors(() => tmpSect);
                const tmpDevice = []
                room.value.data.tuya_devices.forEach(device => {
                    tmpDevice.push(device.id)
                })
                setCheckedDevices(() => tmpDevice)
                setLoading(false)

                const tmpDevices = []
                for (const sector of tmpSect) {
                    for (const device of Object.values(devices.value.data)) {
                        if (device.sectors[sector]) {
                            const tru = tmpDevices.find((item) => item.id === device.id)
                            if (!tru) {
                                tmpDevices.push({sector: sector, ...device})
                            }
                        }
                    }
                }
                setDevices(() => tmpDevices)
            } else {
                openNotificationWithIcon('error', <T>Ошибка</T>, [<T>Ошибка при запросе данных</T>])
            }
        })()
    }, [location.pathname])


    React.useEffect(() => {
        if (room.tuya_devices) {
            for (const d of room.tuya_devices){
                console.log(d.deviceId)
                DeviceMethods.getTuyaDeviceProperties({device_id: d.deviceId}).then(({result}) => {
                    setDeviceProperties(result?.properties)
                })
            }
        }

    }, [room])

    React.useEffect(() => {
        getDataRoom()
    }, []);

    React.useEffect(() => {
        Object.values(languages).map(lang =>
            form.setFieldValue(`name_${lang.isoCode}`, room.name)
        )
        if (Object.keys(room).length > 0) {
            form.setFieldValue(
                'startRent',
                room.startRent
                    ? dayjs(startRent)
                    : ''
            );
            form.setFieldValue(
                'stopRent',
                room.stopRent
                    ? dayjs(stopRent)
                    : ''
            );
        }

    }, [form, languages, room]);

    const changeSector = (checked) => {
        setCheckedSectors(checked)
    }

    // const sendCommand = (device) => {
    //     const formData = new FormData();
    //     formData.append("userId", device.service.userId);
    //     formData.append("hubId", device.hubId);
    //     formData.append("deviceId", device.deviceId);
    //     formData.append("deviceType", device.deviceType);
    //     DeviceMethods.setCommand(formData).then(() => {
    //         openNotificationWithIcon('success', 'Команда успешно отправлена', []);
    //     }).catch((error) => {
    //         openNotificationWithIcon('error', error.response.data.message, error.response.data.errors ? error.response.data.errors : []);
    //     })
    // }

    function sendCommand(device){
        const formData = new FormData();
        formData.append("userId", device.service.userId);
        formData.append("hubId", device.hubId);
        formData.append("deviceId", device.deviceId);
        formData.append("deviceType", device.deviceType);
        formData.append("userName", userName);
        formData.append("deviceName", device.name);
        formData.append("command", "switchStateGate");
        DeviceMethods.setCommand('ajax', formData).then(() => {
            openNotificationWithIcon('success', <T>'Команда успешно отправлена'</T>, []);
        }).catch((error) => {
            openNotificationWithIcon('error', error.response.data.message, error.response.data.errors ? error.response.data.errors : []);
        })
    }

    const setCommandTuya = (deviceId, action_data) => {
        DeviceMethods.setCommand('tuya', {device_id: deviceId, action_data}).then((response) => {
            if (!response.success) {
                openNotificationWithIcon('error', response.msg, []);
            }
            console.log('relay', response)
        })
        // $host.post('/tuya/tuya_device_command', {device_id, action_data}).then(data => {
        //     getPropertiesTuya(device_id);
        //     changeDevicesTuya(device_id);
        // })
    }

    const [status, setStatus] = React.useState({});


    React.useEffect(() => {
        const st = {};
        if (room.tuya_devices) {
            Object.values(room.tuya_devices).forEach(device => {
                DeviceMethods.getTuyaDeviceStatus({device_id: device.deviceId}).then((response) => {
                    // console.log('status', response.result.status[13], response.result.name);
                    if (response.result.status[13]) {
                        st[response.result.name] = response.result.status[13].value;
                        setStatus((prevState) => ({...prevState,...st}));
                    }
                })
            })
            setStatus(() => st);
        }


    }, [room]);

    const setCommandCloseDoorTuya = (deviceId, deviceName) => {
        // закрывет дверь
        DeviceMethods.getPswTicketTuya({device_id: deviceId}).then((response) => {
            if (response.success) {
                DeviceMethods.getTuyaDeviceState({device_id: deviceId}).then((response1) => {
                    if (response1.success) {
                        DeviceMethods.setCommandOpenDoor(
                            {
                                device_id: deviceId,
                                commands: {ticket_id: response.result.ticket_id, open: false},
                                userName,
                                deviceName,
                                command: "close"
                            }).then((response) => {
                            if (!response1.success) {
                                openNotificationWithIcon('error', response.msg, []);
                            }
                            openNotificationWithIcon('success', <T>Дверь закрывается</T>, []);
                            setTimeout(() => {
                                window.location.reload();
                            }, 6000)

                        })
                    }
                })
            }
        });
    }

    const setCommandOpenDoorTuya = (deviceId, deviceName) => {
        // закрывет дверь
        DeviceMethods.getPswTicketTuya({device_id: deviceId}).then((response) => {
            if (response.success) {
                DeviceMethods.getTuyaDeviceState({device_id: deviceId}).then((response1) => {
                    if (response1.success) {
                        DeviceMethods.setCommandOpenDoor(
                            {
                                device_id: deviceId,
                                commands: {ticket_id: response.result.ticket_id, open: true},
                                userName,
                                deviceName,
                                command: "open"
                            }).then((response) => {
                            if (!response1.success) {
                                openNotificationWithIcon('error', response.msg, []);
                            }
                            openNotificationWithIcon('success', <T>Дверь открывается</T>, []);
                            setTimeout(() => {
                                window.location.reload();
                            }, 5000)
                        })
                    }
                })
            }
        });
    }

    return (
        <Flex align="start" style={{ padding: '16px' }}>
            <Form
                name="update_room"
                style={{ width: '100%'}}
                form={form}
                multipart={"form-data"}
                layout="vertical"
                validateMessages={validateMessages}
            >
                {contextHolder}
                {
                    loading
                    ?
                    <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                        <Spin size="large" />
                    </Flex>
                    :
                        <Tabs
                            type={"card"}
                            items={[
                                (user.roleValue === "1" ||  user.roleValue  ===  "2")
                                &&
                                {
                                    label: 'Управление',
                                    key: 'command',
                                    children:

                                    devices.length > 0
                                        &&
                                        <>
                                            <Form.Item label={<T>Ворота</T>}>
                                                <Space size={"large"} align="start" wrap={true}>

                                                    {
                                                        devices.filter(a => Number(a.groups[0]?.value) === 1).map((device) =>
                                                            <Space key={device.deviceId} size={"middle"} direction={"vertical"} wrap={true}>
                                                                <Card size={"small"}>
                                                                    <Button style={{width: '100%'}} size={'large'} onClick={() => sendCommand(device)} type={"primary"}>{device.name} </Button>
                                                                </Card>
                                                            </Space>
                                                        )
                                                    }

                                                </Space>
                                            </Form.Item>
                                            <Form.Item label={<T>Двери</T>}>
                                                <Space size={"large"} align="start" wrap={true}>

                                                    {
                                                        devices.filter(a => Number(a.groups[0]?.value) === 2).map((device) =>
                                                            <Space key={device.deviceId} size={"middle"} direction={"vertical"} wrap={true}>
                                                                <Card size={"small"}>
                                                                    <Button style={{width: '100%'}} size={'large'} onClick={() => sendCommand(device)} type={"primary"}>{device.name} </Button>
                                                                </Card>
                                                            </Space>
                                                        )
                                                    }
                                                </Space>
                                            </Form.Item>
                                            <Form.Item>
                                                {
                                                    room.tuya_devices.length > 0
                                                        ?
                                                        room.tuya_devices.map(device =>

                                                            device.deviceType === "Smart IR with T&H Sensor"
                                                            &&
                                                            <Space size={"middle"} align="center" wrap={
                                                                true
                                                            }>
                                                                <div style={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                                                    <Form.Item
                                                                        hasFeedback
                                                                        label={<T>Температура</T>}
                                                                    >
                                                                        <Input disabled={true} size={"large"} value={deviceProperties.length > 0 && (deviceProperties.at(0)?.value * 0.1).toFixed(1) + ' ℃'}/>
                                                                    </Form.Item>
                                                                </div>

                                                                <div style={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                                                    <Form.Item
                                                                        hasFeedback
                                                                        label={<T>Влажность воздуха</T>}
                                                                    >
                                                                        <Input disabled={true} size={"large"} value={deviceProperties?.length > 0 && (deviceProperties?.at(1)?.value).toFixed(1) + ' %'}/>
                                                                    </Form.Item>
                                                                </div>

                                                            </Space>
                                                        )
                                                        :
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                                                }
                                            </Form.Item>
                                        </>

                                },
                                {
                                    label: <T>Информация</T>,
                                    key: 'info',
                                    children:
                                        <Space direction="vertical" align="start" wrap={true}>
                                            <Form.Item
                                                hasFeedback
                                                style={{width: '100%', marginBottom: 0}}
                                                label={<T>Номер помещения</T>}
                                                name={`name_${localStorage.getItem('lang')}`}
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input disabled size={"large"}/>
                                            </Form.Item>
                                            <Divider orientation={"left"}><T>Данные по аренде</T></Divider>
                                            <Space size={'middle'} align="end" wrap={true}>
                                                <Form.Item
                                                    hasFeedback
                                                    label={<T>Начало аренды</T>}
                                                    name={`startRent`}

                                                >
                                                    <DatePicker disabled size={"large"}/>
                                                </Form.Item>
                                                <Form.Item
                                                    hasFeedback
                                                    label={<T>Конец аренды</T>}
                                                    name={`stopRent`}
                                                >
                                                    <DatePicker disabled size={"large"}/>
                                                </Form.Item>
                                                <Form.Item
                                                    name={'isRented'}
                                                >
                                                    <Tag
                                                        color={room.isRented ? "green" : "red"}>{room.isRented ? <T>В АРЕНДЕ</T> : <T>СВОБОДНО</T>}</Tag>
                                                </Form.Item>
                                            </Space>
                                            <Divider orientation={"left"}><T>Данные по расположению</T></Divider>
                                            <Form.Item
                                                label={<T>Локации</T>}
                                                name={`locationId`}
                                                key={locationId}
                                            >
                                                <Select
                                                    size={"large"}
                                                    defaultValue={locationId !== 0 ? locationId : null}
                                                    disabled={true}
                                                    options={Object.values(locations).map((location) => ({
                                                        label: location.name,
                                                        value: location.id
                                                    }))}
                                                />
                                            </Form.Item>
                                            <Form.Item label={<T>Сектора</T>}>
                                                {
                                                    room.sectors.length > 0 || locationId !== 0
                                                        ?
                                                        locations[locationId].sectors.length > 0
                                                            ?
                                                            <Space size={"middle"} wrap={true}>
                                                                {
                                                                    <Checkbox.Group disabled onChange={changeSector}
                                                                                    defaultValue={checkedSectors}>
                                                                        <Space size={"middle"} wrap={true}>
                                                                            {
                                                                                locations[locationId].sectors.map((sector) =>
                                                                                    <Card key={sector}>
                                                                                        <Checkbox
                                                                                            value={sector}>{sector}</Checkbox>
                                                                                    </Card>
                                                                                )
                                                                            }
                                                                        </Space>
                                                                    </Checkbox.Group>
                                                                }
                                                            </Space>
                                                            :
                                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}><Link
                                                                to={menu.settings.path + "/" + menu.settings.children.sectors.path}><T>Добавить
                                                                сектора</T></Link></Empty>
                                                        :
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                                                }
                                            </Form.Item>
                                        </Space>
                                }
                            ]}
                        />
                }
            </Form>
        </Flex>
    );
};

export default observer(RoomByLangComponent);