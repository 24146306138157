import React from 'react';
import LoginComponent from "../components/login.component";
import AllComponent from "../components/common/all.component";

const LoginPage = () => {
    return (
        <AllComponent>
            <LoginComponent />
        </AllComponent>
    );
};

export default LoginPage;