import React from 'react';
import {
    Button,
    Card,
    Checkbox,
    Empty,
    Flex,
    Form,
    Input,
    Modal,
    notification,
    Space,
    Spin,
    Tabs,
    Tag
} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import T from "../common/t";
import DeviceMethods from "../../methods/device.methods";
import GroupMethods from "../../methods/group.methods";
import LanguageMethods from "../../methods/language.methods";

const UpdateGroupFormComponent = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [pubDate, setPubDate] = React.useState('');
    const [pub, setPub] = React.useState(false);
    const location = useLocation();
    const [updateDate, setUpdateDate] = React.useState('');
    const [onePage, setOnePage] = React.useState({});
    const [loadingForm, setLoadingForm] = React.useState(false);
    const navigate = useNavigate();
    const [devicesChecked, setDevicesChecked] = React.useState([]);
    const [devices, setDevices] = React.useState([]);
    const [tempDevices, setTempDevices] = React.useState([]);
    const [deviceTypes, setDeviceTypes] = React.useState([]);
    const [languages, setLanguages] = React.useState({});
    const [name, setName] = React.useState("");
    const [activeTab, setActiveTab] = React.useState("Relay");

    const [form] = Form.useForm();

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    const getDataOneNews = React.useCallback(() => {
        (async () => {
            const [
                group,
                devices,
                languages
            ] = await Promise.allSettled([
                GroupMethods.getGroupById(location.pathname.split('/').at(-1)),
                DeviceMethods.getDevices('ajax'),
                LanguageMethods.getLanguages(),
            ]);
            if (
                group.status === 'fulfilled' && devices.status === 'fulfilled' && languages.status === 'fulfilled'
            ) {
                setOnePage(() => group.value.data);
                setDevices(() => devices.value.data);
                setLanguages(() => languages.value.data)
                setLoadingForm(false)
            }
        })()
    }, [location.pathname])

    React.useEffect(() => {
        if (Object.keys(languages).length > 0 && Object.keys(onePage).length > 0) {
            Object.values(languages).map(lang => {
                form.setFieldValue(`name_${lang.isoCode}`, onePage[lang.id].name)
                if (lang.isDefault) {
                    form.setFieldValue('value', onePage[lang.id].value)
                    setPub(() => onePage[lang.id].isActive)
                    setPubDate(() => onePage[lang.id].createdAt)
                    setUpdateDate(() => onePage[lang.id].updatedAt)
                }
            })
            setLoadingForm(false)
        }
    }, [form, languages, onePage]);


    React.useEffect(() => {
        getDataOneNews()
    }, []);

    const handleOk = () => {
        setLoadingForm(true);
        const objField = {};
        for (const language of Object.values(languages)) {
            objField[language.isoCode] = form.getFieldValue(`name_${language.isoCode}`)
        }
        GroupMethods.updateGroupById(location.pathname.split('/').at(-1),
        {
            isActive: pub,
            name: objField,
            devicesChecked}
        )
            .then(() => {
                form.resetFields();
                openNotificationWithIcon('success', <T>Группа успешно обновлена</T>, []);
                setTimeout(() => {
                    setIsModalOpen(false);
                    setLoadingForm(false);
                    window.location.reload();
                }, 1000);
            })
            .catch(error => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    React.useEffect(() => {


        Object.values(devices).forEach(item => {
            setTempDevices((prev) => [...prev, {label: item.name, value: item.id, deviceType: item.deviceType, status: item.status}])
        });


        if (Object.values(onePage)[0]?.ajax_devices) {
            Object.values(onePage)[0]?.ajax_devices.forEach(item => {setDevicesChecked(prev => [...prev, item.id])})
        }

        const deviceTypesTemp = [];
        Object.values(devices).forEach(item => {
            if (!deviceTypesTemp.includes(item.deviceType)) {
                deviceTypesTemp.push(item.deviceType);
            }
        })
        setDeviceTypes(() => deviceTypesTemp);
    }, [form, onePage]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handlePub = () => {
        setPub(() => !pub)
    }

    const checkDevices = (checkedValues) => {
        setDevicesChecked(() => checkedValues)
    }

    console.log(devicesChecked)

    const deleteSector = () => {
        setLoadingForm(true);
        GroupMethods.deleteGroup(location.pathname.split('/').at(-1))
            .then(() => {
                openNotificationWithIcon('success', <T>Вы успешно удалили группу</T>, []);
                handleCancel()
                setTimeout(() => {
                    navigate(-1);
                }, 3000)
            })
            .catch((error) => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors);
            })
            .finally(() => {
                setLoadingForm(false);
            });
    }

    return (
        <>
            {contextHolder}
            {
                loadingForm
                    ?
                    <Flex justify="center" align="center" style={{height: '176px'}}>
                        <Spin size="large" />
                    </Flex>
                    :
                    <>
                        <Form
                            name="update_room"
                            style={{ width: '100%', padding: '1rem'}}
                            form={form}
                            multipart={"form-data"}
                            layout="vertical"
                            onFinish={handleOk}
                        >
                            <Flex justify="space-between" gap={20}>
                                <Flex vertical gap={20} style={{width: '100%'}}>
                                    <Tabs
                                        defaultActiveKey="1"
                                        size={'large'}
                                        type={"card"}
                                        items={
                                            Object.values(languages).map(item => {
                                                return {
                                                    label: item.isoCode.toUpperCase(),
                                                    key: item.id,
                                                    children: <Form.Item
                                                        hasFeedback
                                                        label={<T>Название</T>}
                                                        name={`name_${item.isoCode}`}
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Input size={"large"} />
                                                    </Form.Item>
                                                };
                                            })
                                        }
                                    />

                                    <Form.Item
                                        hasFeedback
                                        style={{width: '100%', marginBottom: '1rem',}}
                                        label={<T>Значение</T>}
                                        name={`value`}
                                    >
                                        <Input size={"large"} disabled/>
                                    </Form.Item>
                                    <Form.Item label={<T>Устройства</T>}>
                                        <Tabs
                                            defaultActiveKey={activeTab}
                                            size={'large'}
                                            type={"card"}
                                            onChange={(key) => setActiveTab(key)}
                                            items={
                                                deviceTypes.map(item => {
                                                    if (item  ===  "Relay")  {
                                                        return {
                                                            label: item,
                                                            key: item,
                                                            children:
                                                                Object.values(tempDevices).length > 0
                                                                    ?
                                                                    <Space size={"middle"} wrap={"wrap"}>
                                                                        {
                                                                            <Checkbox.Group
                                                                                onChange={checkDevices}
                                                                                defaultValue={devicesChecked}
                                                                            >
                                                                                <Space size={"middle"} wrap={"wrap"}>
                                                                                    {
                                                                                        tempDevices.filter(i => i.deviceType === item).map(item =>
                                                                                            <Card key={item.value}>
                                                                                                <Checkbox value={item.value}>{item.label}</Checkbox>
                                                                                                <Tag color={item.status === 'online'? 'green' :'red'}>{item.status.toUpperCase()}</Tag>
                                                                                            </Card>
                                                                                        )
                                                                                    }
                                                                                </Space>
                                                                            </Checkbox.Group>
                                                                        }
                                                                    </Space>
                                                                    :
                                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                                                        }
                                                    }
                                                })
                                            }
                                        />
                                    </Form.Item>
                                </Flex>
                                <Flex vertical style={{width: '20%'}}>
                                    <Flex vertical style={{width: '100%', position: 'sticky', top: '1rem'}}>
                                        <Form.Item
                                            label={<T>Опубликовать</T>}
                                            name={'isActive'}
                                        >
                                            <Input size={"large"} type={"checkbox"} checked={pub} onChange={handlePub}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={<T>Дата публикации</T>}
                                        >
                                            <Input type={"dateTime"} disabled value={
                                                pubDate?.replaceAll('-', '.')
                                                    .replace('T', ' ')
                                            }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={<T>Дата обновления</T>}
                                        >
                                            <Input type={"dateTime"} disabled value={
                                                updateDate?.replaceAll('-', '.')
                                                    .replace('T', ' ')
                                            }
                                            />
                                        </Form.Item>
                                        <Form.Item shouldUpdate>
                                            {
                                                () => (
                                                    <Button
                                                        size={"large"}
                                                        style={{width: '100%', marginTop: '6px'}}
                                                        loading={false}
                                                        type="primary"
                                                        htmlType="submit"
                                                    >
                                                        <T>Сохранить</T>
                                                    </Button>
                                                )
                                            }
                                        </Form.Item>
                                        <Button
                                            size={"large"}
                                            style={{width: '100%', marginTop: '6px'}}
                                            loading={false}
                                            onClick={showModal}
                                            danger={true}
                                            disabled
                                        >
                                            <T>Удалить группу</T>
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Form>

                        <Modal title={<T>Удалить сектор</T>} open={isModalOpen} onOk={deleteSector} onCancel={handleCancel}>
                            <p>{<T>Вы точно хотите удалить группу</T>} ?</p>
                        </Modal>
                    </>
            }
        </>
    );
};

export default UpdateGroupFormComponent;