import React from 'react';
import ContentComponent from "./content.component";
import SidebarComponent from "./sidebar.component";
import {Flex, Layout, Spin, Typography} from "antd";
import {useAuth} from "../../hooks/useAuth";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {useNavigate} from "react-router-dom";
import {UserMethods} from "../../methods/user.methods";
import {menu} from "../../routes";
import {useLang} from "../../hooks/useLang";
import BLOCKED_IMG from "../../img/blocked.png"
import T from "./t";

const MainComponent = ({title, children, showModal, showButton}) => {
    const {user_store} = React.useContext(Context);
    const user = useAuth();
    const lang = useLang();
    const [active, setActive] = React.useState(true);
    const [timer, setTimer] = React.useState(5);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (user && user.isActive) {
            user_store.setUserRole(user.roleValue);
            setActive(() => true)
            setLoading(() => false)
        } else if (user && !user.isActive){
            setActive(() => false)
            setLoading(() => false)

            let time = 5

            setInterval(() => {
                time -= 1
                setTimer(() => time)
                if (time === 0) {
                    UserMethods.logout()
                        .then()
                        .finally(() =>
                        {
                            localStorage.removeItem('token');
                            navigate(menu.login.path)
                        }
                    )
                }
            }, 1000);
        }
    }, [user, lang]);

    return (
            <Layout>
                {
                    active
                    ?
                        <>
                            <SidebarComponent user={user} />
                            {
                                loading
                                    ?
                                    <Flex style={{width: '100vw', height: '100vh'}} justify="center" align="center">
                                        <Spin size={"large"}/>
                                    </Flex>
                                    :
                                    <ContentComponent title={title} showButton={showButton} showModal={showModal}>
                                        {
                                            user
                                            &&
                                            children
                                        }
                                    </ContentComponent>
                            }
                        </>

                    :
                        <Flex vertical style={{width: '100vw', height: '100vh', padding: '16px'}} justify="center" align="center">
                            <img width={240} height={240} src={BLOCKED_IMG} style={{marginBottom: '1rem'}} alt={'blocked'}/>
                            <Typography.Title level={4}><T>Ваш аккаунт заблокирован</T></Typography.Title>
                            <Typography.Text><T>Аккаунт будет активирован в ближайшее время, как только мы проверим регистратора</T>.</Typography.Text>
                            <Typography.Text><T>Спасибо за понимание</T>.</Typography.Text>
                            <div style={{margin: '1rem'}}></div>
                            <Typography.Text><T>Вы будите перенаправлкны на страницу авторизации через</T>:</Typography.Text>
                            <Typography.Title level={4}>{timer} <T>cек</T>.</Typography.Title>
                        </Flex>
                }

            </Layout>

    );
};

export default observer(MainComponent);