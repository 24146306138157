import React from 'react';
import {Button, Checkbox, Flex, Form, Input, Spin} from "antd";
import T from "../../common/t";
import StockMethods from "../../../methods/stock.methods";
import GoogleMapReact from "google-map-react";


export const defaultProps = {
    center: {
        lat: 56.91370236167376,
        lng: 24.1410897448897
    },
    zoom: 10,
    language: 'lv'
};

export const Marker = () => {
    const markerRef = React.useRef(null);

    return (
        <div ref={markerRef}>
            <img width={32} height={32} src={`${process.env.PUBLIC_URL}/icons/gIcon.svg`} alt={"marker"}/>
        </div>
    )
}

const AddStockFormComponent = ({openNotificationWithIcon, validateMessages}) => {
    const [form] = Form.useForm();
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [file, setFile] = React.useState(null);

    const [marker, setMarker] = React.useState({});

    const handleChange = (e) => {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
            setFile({name: e.target.files[0].name, data: reader.result});
        }
    }

    const clickMapHandler = (e) => {
        const obj = {}
        obj.lat = e.lat;
        obj.lng = e.lng;
        setMarker(() => obj);
    }

    const handleOk = () => {
        setLoadingForm(true);
        StockMethods.addStock({...form.getFieldsValue(), cover: file, lat: marker.lat, lng: marker.lng})
            .then(res => {
                form.resetFields();
                openNotificationWithIcon('success', <T>Преимущество успешно добавлено</T>, []);
                setTimeout(() => {
                    setLoadingForm(false);
                    window.location.reload();
                }, 1000);
            })
            .catch(error => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    return (

        loadingForm
        ?
            <Flex justify="center" align="center" style={{height: '176px'}}>
                <Spin size="large" />
            </Flex>
        :
            <Form
                style={{ width: '100%'}}
                form={form}
                multipart={"form-data"}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={handleOk}
            >
                <Form.Item
                    hasFeedback
                    label={<T>Заголовок</T>}
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    label={<T>Обложка (Обязательно)</T>}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input type={"file"} onChange={handleChange}/>
                </Form.Item>
                <Form.Item label={<T>Нажмите на карту, чтобы добавить / изменить позицыю маркера</T>}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: process.env.REACT_APP_GMAP_API}}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                        style={{
                            height: '20rem',
                            width: '100%'
                        }}
                        onClick={(e) => clickMapHandler(e)}
                    >
                        {
                            Object.keys(marker).length > 0 && <Marker lat={marker.lat} lng={marker.lng} />
                        }

                    </GoogleMapReact>
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Адрес</T>}
                    name="address"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Ссылка (Внешняя)</T>}
                    name="url"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Опубликовано</T>}
                    name="isActive"
                >
                    <Checkbox size={"large"} checked={true}/>
                </Form.Item>
                <Form.Item style={{marginBottom: 0}}>
                    <Button style={{width: '100%'}} size={"large"} type="primary" htmlType="submit" ><T>Сохранить</T></Button>
                </Form.Item>
            </Form>
    );
};

export default AddStockFormComponent;
