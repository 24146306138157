import React from 'react';
import HeaderComponent from "./header.component";
import {Layout, Spin, theme} from "antd";
import FooterComponent from "./footer.component";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";

const {Content} = Layout;

const ContentComponent = ({children, title, showModal, showButton}) => {
    const {static_store} = React.useContext(Context);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (
        <Layout style={{height: 'calc(100vh - 64px)', width: '100vw', position: 'fixed', top: '64px', bottom: 0, left: '80px', right: 0}}>
            {
                static_store.isLoading
                    ?
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "3rem 0 1rem 0", width: 'calc(100% - 64px)'}}>
                        <Spin size="large"/>
                    </div>
                    :
                    <>
                        <HeaderComponent title={title} showModal={showModal} showButton={showButton} />
                        <Content style={{margin: '20px 20px 0', height: 'calc(100vh - 128px)', width: 'calc(100vw - 120px)', background: colorBgContainer, borderRadius: borderRadiusLG, overflow: 'auto'}}>
                            {children}
                        </Content>
                        <FooterComponent />
                    </>

            }

        </Layout>
    )
};

export default observer(ContentComponent);