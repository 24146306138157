import React from 'react';
import {Avatar, Badge, Button, Flex, Layout, Space, theme, Typography} from "antd";
import {MessageOutlined, UserOutlined} from "@ant-design/icons";
import {useLocation, useNavigate} from "react-router-dom";
import {menu} from "../../routes";
import T from "./t";
import MessageMethods from "../../methods/message.methods";
import {observer} from "mobx-react-lite";
import {UserMethods} from "../../methods/user.methods";
import {useAuth} from "../../hooks/useAuth";
import LanguageMethods from "../../methods/language.methods";
import {Context} from "../../index";

const { Header } = Layout;

const HeaderComponent = ({title, showModal, showButton}) => {
    const user = useAuth();
    const {lang_store, static_store} = React.useContext(Context);
    const navigate = useNavigate();
    const [messageCount, setMessageCount] = React.useState(0);
    const location = useLocation();

    React.useEffect(() => {
        if (user) {
            (async () => {
                const [
                    profile,
                    messages,
                    languages,
                ] = await Promise.allSettled([
                        UserMethods.getProfile(),
                        MessageMethods.getMessages(),
                        LanguageMethods.getLanguages(),
                    ]
                )
                if (profile.status === "fulfilled" && messages.status === "fulfilled" && languages.status === "fulfilled") {
                    let name = profile.value.data.first_name + ' ' + profile.value.data.last_name;
                    const myMessage = []
                    for (const message of Object.values(messages.value.data)) {
                        if (message.toId === name) {
                            if (!message.isRead) {
                                myMessage.push(message);
                            }
                        }
                    }
                    if (myMessage.length > 0) {
                        setMessageCount(() => myMessage.length)
                    } else {
                        setMessageCount(() => 0)
                    }
                    lang_store.setLangs(Object.values(languages.value.data))
                }
            })();
        }
    }, [user, lang_store])

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    React.useEffect(() => {
        lang_store.setLang(localStorage.getItem('lang'))
    }, [lang_store]);

    return (
        <Header style={{ position: 'fixed', zIndex: 1, top: 0, left: '80px', right: 0, padding: 0, background: colorBgContainer}} >
            <Flex style={{ height: '100% ', padding: '0 16px'}} justify="space-between" align="center">
                <Space size="middle">
                    {
                        (
                            location.pathname !== menu.dashboard.path
                        )
                        &&
                        <Button onClick={() => navigate(-1)} size={"middle"} loading={false} type="text"><T>Назад</T></Button>
                    }
                    {
                        (user && Number(user.roleValue) !== 999)
                        &&
                        <Typography.Title className={"title-header"} level={3} style={{ margin: '0 20px 0 0'}}>{title}</Typography.Title>
                    }

                </Space>
                <Space size="middle">
                    {
                        showButton
                            ?
                            location.pathname === menu.devices.path
                                ?
                                <Button onClick={showModal} size={"middle"} htmlType="submit" loading={false} type="primary"><T>Синхронизировать устройства</T></Button>
                                :
                                location.pathname === menu.messages.path
                                    ?
                                        (user && Number(user.roleValue) !== 999)
                                        &&
                                        <Button onClick={showModal} size={"middle"} htmlType="submit" loading={false} type="primary"><T>Написать</T></Button>
                                    :
                                        (user && Number(user.roleValue) !== 999)
                                        &&
                                        <Button onClick={showModal} size={"middle"} htmlType="submit" loading={false} type="primary"><T>Добавить</T></Button>
                            :
                            null

                    }
                    <Badge count={messageCount}>
                        <Avatar className={"hover"} onClick={() => navigate(menu.messages.path)} size={"medium"} icon={<MessageOutlined />}/>
                    </Badge>
                    <Avatar className={"hover"} onClick={() => navigate(menu.profile.path)} size={"medium"} icon={<UserOutlined />} />
                </Space>
            </Flex>
        </Header>
    );
};

export default observer(HeaderComponent);