import {$host} from "../http/axios";

export default class StockMethods {
    static addStock = async (formData) => {
        const {data} = await $host.post(`/stock/add`, formData);
        return data;
    }
    static getOneStock = async (id) => {
        const {data} = await $host.post(`/stock/get/${id}`);
        return data;
    }

    static getAll = async () => {
        const {data} = await $host.post(`/stock/all?limit=1000000000`);
        return data;
    }

    static updateStock = async (id, formData) => {
        const {data} = await $host.post(`/stock/update/${id}`, formData);
        return data;
    }

    static getByLangStock = async (id, isoCode) => {
        const {data} = await $host.post(`/stock/get/${id}/lang/${isoCode}`);
        return data;
    }

    static deleteStock = async (id) => {
        const {data} = await $host.post(`/stock/delete/${id}`);
        return data;
    }
}