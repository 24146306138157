import React from 'react';
import {Button, Checkbox, Flex, Form, Input, Spin} from "antd";
import LocationMethods from "../../methods/location.methods";
import T from "../common/t";

const AddLocationFormComponent = ({openNotificationWithIcon, validateMessages}) => {
    const [form] = Form.useForm();
    const [loadingForm, setLoadingForm] = React.useState(false);

    const handleOk = () => {
        setLoadingForm(true);
        LocationMethods.addLocation({...form.getFieldsValue()})
            .then(() => {
                form.resetFields();
                openNotificationWithIcon('success', <T>Локация успешно добавлена</T>, []);
                setTimeout(() => {
                    setLoadingForm(false);
                    window.location.reload();
                }, 1000);
            })
            .catch(error => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    return (

        loadingForm
        ?
            <Flex justify="center" align="center" style={{height: '176px'}}>
                <Spin size="large" />
            </Flex>
        :
            <Form
                style={{ width: '100%'}}
                form={form}
                multipart={"form-data"}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={handleOk}
            >
                <Form.Item
                    hasFeedback
                    label={<T>Название</T>}
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Адрес</T>}
                    name="address"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size={"large"} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={<T>Опубликовано</T>}
                    name="isActive"
                >
                    <Checkbox size={"large"} checked={true}/>
                </Form.Item>
                <Form.Item style={{marginBottom: 0}}>
                    <Button style={{width: '100%'}} size={"large"} type="primary" htmlType="submit" ><T>Сохранить</T></Button>
                </Form.Item>
            </Form>
    );
};

export default AddLocationFormComponent;
