import React from 'react';
import T from "../common/t";
import {Card, Flex, Input, notification, Space, Spin, Table, Tag} from "antd";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import NewsMethods from "../../methods/news.methods";
import UnitTypesMethods from "../../methods/unitTypes.methods";
import UnitMethods from "../../methods/units.methods";
import SliderMethods from "../../methods/slider.methods";
import AdvantageMethods from "../../methods/advantage.methods";
import PagesMethods from "../../methods/pages.methods";
import StaticMethods from "../../methods/static.methods";
import LanguageMethods from "../../methods/language.methods";
import RoleMethods from "../../methods/role.methods";
import LocationMethods from "../../methods/location.methods";
import SectorMethods from "../../methods/sector.methods";
import BannersMethods from "../../methods/banners.methods";
import StockMethods from "../../methods/stock.methods";
import WebPageMethods from "../../methods/webPage.methods";
import GroupMethods from "../../methods/group.methods";

const columnsAll = [
    {
        title: <T>Название</T>,
        dataIndex: 'name',
        key: 'name',
        width: '40%',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.id - b.id,
    },
    {
        title: <T>Состояние</T>,
        key: 'isActive',
        width: '10%',
        render: (_, record) => (
            <Space size="middle">
                <Tag color={record.isActive? 'green' :'red'}>{record.isActive ? <T>Опубликовано</T> : <T>Не опубликовано</T>}</Tag>
            </Space>
        ),
    },
    {
        title: <T>Создано</T>,
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '10%',
        render:  (text)  =>  text?.split('T')[0].replaceAll('-', '.') + ' ' + text?.split('T')[1].split('.')[0],
    },
    {
        title: '',
        width: '5%',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Link to={`${record.id}`}><T>Перейти</T></Link>
            </Space>
        ),
    },

];

const AllWebComponent = ({type}) => {
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([]);

    const [columns, setColumns] = React.useState(columnsAll);

    React.useEffect(() => {
        if (type === 'pages') {
            setColumns(() => [
                {
                    title: <T>Название</T>,
                    dataIndex: 'title',
                    key: 'title',
                    width: '35%',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.id - b.id,
                },
                {
                    title: <T>Состояние</T>,
                    key: 'isActive',
                    width: '15%',
                    render: (_, record) => (
                        <Space size="middle">
                            <Tag color={record.isActive? 'green' :'red'}>{record.isActive ? <T>Опубликовано</T> : <T>Не опубликовано</T>}</Tag>
                        </Space>
                    ),
                },
                {
                    title: <T>Создано</T>,
                    dataIndex: 'createdAt',
                    width: '10%',
                    key: 'createdAt',
                    render:  (text)  =>  text?.split('T')[0].replaceAll('-', '.') + ' ' + text?.split('T')[1].split('.')[0],
                },
                {
                    title: '',
                    width: '5%',
                    key: 'action',
                    render: (_, record) => (
                        <Space size="middle">
                            <Link to={`${record.id}`}><T>Перейти</T></Link>
                        </Space>
                    ),
                },
            ]);
        }
    }, [type]);

    React.useEffect(() => {
        if (type === 'news') {
            NewsMethods.getAll()
                .then(res => {
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }
        if (type === 'unitTypes') {
            UnitTypesMethods.getAll()
                .then(res => {
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }
        if (type === 'units') {
            UnitMethods.getAll()
                .then(res => {
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }
        if (type === 'sliders') {
            SliderMethods.getAll()
                .then(res => {
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }
        if (type === 'advantages') {
            AdvantageMethods.getAll()
                .then(res => {
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }
        if (type === 'pages') {
            PagesMethods.getAll()
                .then(res => {
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }
        if (type === 'statics') {
            StaticMethods.getStatics()
                .then(res => {
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }
        if (type === 'languages') {
            LanguageMethods.getLanguages()
                .then(res => {
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }
        if (type === 'roles') {
            RoleMethods.getRoles()
                .then(res => {
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }
        if (type === 'locations') {
            LocationMethods.getLocations()
                .then(res => {
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }
        if (type === 'sectors') {
            SectorMethods.getSectors()
                .then(res => {
                    console.log(Object.values(res.data))
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }

        if (type === 'banners') {
            BannersMethods.getAll()
                .then(res => {
                    console.log(Object.values(res.data))
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }

        if (type === 'stock') {
            StockMethods.getAll()
                .then(res => {
                    console.log(Object.values(res.data))
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }
        if (type === 'web_pages') {
            WebPageMethods.getAll()
                .then(res => {
                    console.log(Object.values(res.data))
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }

        if (type === 'groups') {
            GroupMethods.getAll()
                .then(res => {
                    console.log(Object.values(res.data))
                    setData(() => Object.values(res.data));
                })
                .finally(() => setLoading(false));
        }

    }, [type]);

    const [searchInput, setSearchInput] = React.useState('');
    const [filteredData, setFilteredData] = React.useState([]);
    const [emptyFilter, setEmptyFilter] = React.useState(false);
    const [searched, setSearched] = React.useState(false);

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors[0],
        });
    };

    const search = (e) => {
        setSearchInput(() => e.target.value);
    }

    React.useEffect(() => {
        let filtered;
        if (type === 'pages'){
            filtered = data.filter(d => d.title.toLowerCase().trim().includes(searchInput.toLowerCase().trim()))
        } else {
            filtered = data.filter(d => d.name.toLowerCase().trim().includes(searchInput.toLowerCase().trim()))
        }
        setTimeout(() => {
            setFilteredData(() => filtered)

            setSearched(() => true)
            if (filtered.length === 0) {
                setEmptyFilter(() => true)
            } else {
                setEmptyFilter(() => false)
            }
        }, 300);
    }, [searchInput, data]);

    const err = React.useCallback(() => {
        openNotificationWithIcon('error', <T>По вашему запросу ничего не найдено</T>, []);
    }, []);

    React.useEffect(() =>  {
        if (emptyFilter && searchInput.length > 0) {
            err();
        }
    }, [emptyFilter, searchInput]);

    return (
            loading
            ?
            <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                <Spin size="large" />
            </Flex>
            :
            <>
                {searched && contextHolder}
                <Flex justify="space-between">
                    <Flex vertical gap={20} style={{width: '100%'}}>
                        <Table
                            style={{maxWidth: '100%', borderRight: '1px solid #f0f0f0'}}
                            columns={columns}
                            pagination={false}
                            scroll={{x:'400', y: 'calc(100vh - 13rem)'}}
                            dataSource={
                                filteredData.length > 0
                                    ?
                                    filteredData
                                    :
                                    data
                            } />
                    </Flex>
                    <Flex vertical style={{width: '20%'}}>
                        <Flex vertical style={{width: '100%', position: 'sticky', top: '0'}}>
                            <Card style={{boxShadow: 'none'}} bordered={false}>
                                <Input placeholder="Search..." value={searchInput} onChange={search} />
                            </Card>
                        </Flex>
                    </Flex>
                </Flex>
            </>
    );
};

export default observer(AllWebComponent);