import React from 'react';
import {Layout} from "antd";
import T from "./t";
import {observer} from "mobx-react-lite";

const {Footer} = Layout;

const FooterComponent = () => {
    return (
        <Footer style={{textAlign: 'center', paddingLeft: 0}}>
            Ambar.lv ©{new Date().getFullYear()} <T>All right reserved!</T>
        </Footer>
    );
};

export default observer(FooterComponent);