import {$host} from "../http/axios";

export default class UnitMethods {
    static addUnit = async (formData) => {
        const {data} = await $host.post(`/unit/add`, formData);
        return data;
    }
    static getOneUnit = async (id) => {
        const {data} = await $host.post(`/unit/get/${id}`);
        return data;
    }

    static getAll = async () => {
        const {data} = await $host.post(`/unit/all?limit=1000000000`);
        return data;
    }

    static updateUnit = async (id, formData) => {
        const {data} = await $host.post(`/unit/update/${id}`, formData);
        return data;
    }

    static getByLangUnit = async (id, isoCode) => {
        const {data} = await $host.post(`/unit/get/${id}/lang/${isoCode}`);
        return data;
    }

    static deleteUnit = async (id) => {
        const {data} = await $host.post(`/unit/delete/${id}`);
        return data;
    }
}