import React from 'react';
import {Button, Card, Divider, Flex, Form, Input, notification, Spin} from "antd";
import T from "../common/t";
import ConfigMethods from "../../methods/config.methods";
import {toolbarOptions} from "../web/oneWeb.component";
import ReactQuill from "react-quill";

const UpdateConfigFormComponent = () => {
    const [loadingForm, setLoadingForm]  =  React.useState(false);

    React.useEffect(() => {
        ConfigMethods.getConfig().then(res => {
            form.setFieldsValue(res.data);
        })
    }, []);

    const [form] = Form.useForm();

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} ' + 'is required',
        types: {
            // eslint-disable-next-line
            email: '${label} ' + 'is not a valid email',
            // eslint-disable-next-line
            tel: '${label} ' + 'is not a valid phone number',
        },
    };

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors[0],
        });
    };

    const updateConfig  = (values)  =>  {
        setLoadingForm(true);
        const formData = new FormData();
        formData.append("android_version", values.android_version);
        formData.append("ios_version", values.ios_version);
        formData.append("android_update_url", values.android_update_url);
        formData.append("ios_update_url", values.ios_update_url);
        formData.append("email", values.email);
        formData.append("phone", values.phone);
        formData.append("share_in_url", values.share_in_url);
        formData.append("share_face_url", values.share_face_url);
        formData.append("seo_block_text", values.seo_block_text);
        formData.append("seo_block_description", values.seo_block_description);
        ConfigMethods.updateConfig(formData).then(res  => {
            openNotificationWithIcon('success', <T>Конфиг успешно обновлен</T>, []);
        }).catch(error => {
            if (error.response.data.errors.length > 0){
                error.response.data.errors.map(err => {
                    openNotificationWithIcon('error', error.response.data.message, [err]);
                })
            } else {
                openNotificationWithIcon('error', error.response.data.message, [error.response.data.errors]);
            }
        })
            .finally(() => setLoadingForm(false));
    }

    return (
        <>
            {contextHolder}
            <Form
                name="update_room"
                style={{ width: '100%', padding: '1rem 0 1rem 1rem'}}
                form={form}
                multipart={"form-data"}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={updateConfig}
            >

                {
                    loadingForm
                        ?
                        <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                            <Spin size="large" />
                        </Flex>
                        :
                        <Flex justify="space-between">
                            <Flex vertical gap={20} style={{width: '100%'}}>
                                <Form.Item
                                    hasFeedback
                                    label={<T>Андроид версия</T>}
                                    name="android_version"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input size={"large"} />
                                </Form.Item>
                                <Form.Item
                                    hasFeedback
                                    label={<T>Сылка PlayMarket</T>}
                                    name="android_update_url"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input size={"large"}/>
                                </Form.Item>
                                <Form.Item
                                    hasFeedback
                                    label={<T>IOS версия</T>}
                                    name="ios_version"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input size={"large"} />
                                </Form.Item>
                                <Form.Item
                                    hasFeedback
                                    label={<T>Сылка AppStore</T>}
                                    name="ios_update_url"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input size={"large"} />
                                </Form.Item>

                                <Divider orientation="left">Контакты</Divider>

                                <Form.Item
                                    hasFeedback
                                    label={<T>Email</T>}
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input size={"large"} />
                                </Form.Item>

                                <Form.Item
                                    hasFeedback
                                    label={<T>Телефон</T>}
                                    name="phone"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input size={"large"} />
                                </Form.Item>
                                <Divider orientation="left">Контакты</Divider>
                                <Form.Item
                                    hasFeedback
                                    label={<T>Linkedin соц-сеть (ссылка)</T>}
                                    name="share_in_url"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input size={"large"} />
                                </Form.Item>
                                <Form.Item
                                    hasFeedback
                                    label={<T>FaceBook соц-сеть (ссылка)</T>}
                                    name="share_face_url"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input size={"large"} />
                                </Form.Item>
                                <Divider orientation="left">Куки</Divider>
                                <Form.Item
                                    hasFeedback
                                    label={<T>Куки - приветствие</T>}
                                    name="seo_block_text"
                                >
                                    <ReactQuill
                                        style={{width: '100%', maxWidth: '100%', marginBottom: 0, marginTop: '1rem'}}
                                        theme="snow"
                                        modules={
                                            {
                                                toolbar: toolbarOptions
                                            }
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    hasFeedback
                                    label={<T>Настройка куки</T>}
                                    name="seo_block_description"
                                >
                                    <ReactQuill
                                        style={{width: '100%', maxWidth: '100%', marginBottom: 0, marginTop: '1rem'}}
                                        theme="snow"
                                        modules={
                                            {
                                                toolbar: toolbarOptions
                                            }
                                        }
                                    />
                                </Form.Item>
                            </Flex>
                            <Flex vertical style={{width: '20%'}}>
                                <Flex vertical style={{width: '100%', position: 'sticky', top: '1rem'}}>
                                    <Form.Item shouldUpdate>
                                        {
                                            () => (
                                                <Card style={{boxShadow: 'none'}} bordered={false}>
                                                    <Button
                                                        size={"large"}
                                                        loading={false}
                                                        type="primary"
                                                        htmlType="submit"
                                                        style={{width: '100%'}}
                                                    >
                                                        <T>Сохранить</T>
                                                    </Button>
                                                </Card>
                                            )
                                        }
                                    </Form.Item>
                                </Flex>
                            </Flex>
                        </Flex>
                }
            </Form>
        </>
    );
};

export default UpdateConfigFormComponent;