import React from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import StaticMethods from "../../methods/static.methods";
import LanguageMethods from "../../methods/language.methods";
import {useLang} from "../../hooks/useLang";

const AllComponent = ({children}) => {
    const {static_store, lang_store} = React.useContext(Context);

    useLang()

    React.useEffect(() => {
        if (Object.keys(lang_store.isLangs).length === 0) {
            LanguageMethods.getLanguages().then(res => {
                lang_store.setLangs(Object.values(res.data));
            }).finally(() => static_store.setLoading(false))
        } else {
            static_store.setLoading(false)
        }
        lang_store.setLang(localStorage.getItem("lang"));
    }, []);

    React.useEffect(() => {
        static_store.setLoading(true)
        StaticMethods.getStaticByLang(lang_store.isLang).then(res => {
            static_store.setStatics(res.data);
        }).finally(() => static_store.setLoading(false))

    }, [lang_store, lang_store.isLang, static_store.setStatics]);

    return (
        children
    );
};

export default observer(AllComponent);