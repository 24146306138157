import React from 'react';
import {useLocation} from "react-router-dom";
import {
    Button, Checkbox,
    Flex,
    Form,
    Input, notification,
    Select,
    Spin,
} from "antd";
import {UserMethods} from "../methods/user.methods";
import RoleMethods from "../methods/role.methods";
import {phoneCodes} from "../phoneCodes";
import T from "./common/t";
import {observer} from "mobx-react-lite";

const UserComponent = () => {
    const location = useLocation();

    const [clientReady, setClientReady] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [roleId, setRoleId] = React.useState('');
    const [roles, setRoles] = React.useState({});

    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    React.useEffect(() => {
        (async () => {
            const [user, roles] = await Promise.allSettled([
                UserMethods.getUserById(location.pathname.split('/').at(-1)),
                RoleMethods.getRoles()
            ])
            if (user.status === 'fulfilled' && roles.status === 'fulfilled'){
                setIsActive(() => user.value.data.isActive);
                setRoles(() => roles.value.data);
                setRoleId(() => user.value.data.roleId);
                setLoading(false);
                form.setFieldsValue(user.value.data);
            }
        })()
    }, [location.pathname, form]);

    // To disable submit button at the beginning.
    React.useEffect(() => {
        setClientReady(true);
    }, []);

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} ' + 'is required',
        types: {
            // eslint-disable-next-line
            email: '${label} ' + 'is not a valid email',
            // eslint-disable-next-line
            tel: '${label} ' + 'is not a valid phone number',
        },
    };

    const updateUser = () => {
        setLoading(true)
        UserMethods.updateUser(location.pathname.split('/').at(-1), {...form.getFieldsValue(), isActive, roleId})
            .then(() => openNotificationWithIcon('success', <T>Вы успешно обновили пользователя</T>, []))
            .catch((error) => openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors))
            .finally(() => setLoading(false))
    }

    const toggleActiveUser = () => {
        if (isActive){
            setIsActive(() => false);
        } else {
            setIsActive(() => true);
        }
    }

    const toggleUserRoleId = (e) => {
        setRoleId(() => e);
    }

    const prefixSelector = (
        <>
            +
            <Form.Item
                name="phone_code"
                noStyle
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select style={{ width: 100}} showSearch defaultValue={371} filterOption={(input, option) => (String(option?.label) ?? '').includes(String(input))}
                        filterSort={(optionA, optionB) =>
                            (String(optionA?.label) ?? '').toLowerCase().localeCompare((String(optionB?.label) ?? '').toLowerCase())
                        }
                        options={phoneCodes}
                />
            </Form.Item>
        </>

    );

    return (
        <Flex align="start" style={{ padding: '16px' }}>
            {contextHolder}
            <Form
                style={{ width: '100%', marginRight: '16px' }}
                form={form}
                multipart={"form-data"}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={updateUser}
            >
                {
                    loading
                        ?
                        <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                            <Spin size="large" />
                        </Flex>
                        :
                        <Flex justify="space-between" gap={20}>
                            <Flex vertical gap={20} style={{width: '100%'}}>
                                <Form.Item
                                    hasFeedback
                                    label={<T>Имя</T>}
                                    name="first_name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input size={"large"} />
                                </Form.Item>
                                <Form.Item
                                    hasFeedback
                                    label={<T>Фамилия</T>}
                                    name="last_name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input size={"large"} />
                                </Form.Item>
                                <Form.Item
                                    hasFeedback
                                    label={<T>Email</T>}
                                    name="email"
                                >
                                    <Input disabled size={"large"} />
                                </Form.Item>
                                <Form.Item
                                    hasFeedback
                                    label={<T>Телефон</T>}
                                    name="phone"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                    mask={"phone"}
                                >
                                    <Input mask={"phone"}  addonBefore={prefixSelector} style={{ width: '100%' }} onKeyUp={e => e.target.value = e.target.value.replace(/\D/, '')} size={"large"} />
                                </Form.Item>
                                <Form.Item
                                    hasFeedback
                                    label={<T>Роль</T>}
                                    name="roleId"
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        size={"large"}
                                        defaultValue={roleId}
                                        options={Object.values(roles).map(role => ({value: role.id, label: role.name}))}
                                        onChange={toggleUserRoleId}
                                    />
                                </Form.Item>
                            </Flex>
                            <Flex vertical style={{width: '20%'}}>
                                <Flex vertical style={{width: '100%', position: 'sticky', top: '1rem'}}>
                                    <Form.Item
                                        hasFeedback
                                        label={<T>Статус</T>}
                                        name="isActive"
                                    >
                                        <Checkbox
                                            size={"large"}
                                            checked={isActive}
                                            onChange={toggleActiveUser}
                                        ><T>Активный</T></Checkbox>
                                    </Form.Item>
                                    <Form.Item shouldUpdate>
                                        {
                                            () => (
                                                <Button size={"large"} style={{width: '100%', marginTop: '6px'}} htmlType="submit" loading={false} disabled={
                                                    !clientReady ||
                                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                } type="primary"><T>Сохранить</T></Button>
                                            )
                                        }
                                    </Form.Item>
                                    <Form.Item shouldUpdate>
                                        {
                                            () => (
                                                <Button disabled size={"large"} style={{width: '100%', marginTop: '6px'}} htmlType="submit" loading={false} type="primary"><T>Удалить пользователя</T></Button>
                                            )
                                        }
                                    </Form.Item>
                                </Flex>
                            </Flex>
                        </Flex>
                }
            </Form>
        </Flex>
    );
};

export default observer(UserComponent);