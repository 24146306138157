import React from 'react';
import {observer} from "mobx-react-lite";
import 'react-quill/dist/quill.snow.css';
import UpdateNewsFormComponent from "./forms/updateNewsForm.component";
import UpdateUnitTypeFormComponent from "./forms/updateUnitTypeForm.component";
import UpdateUnitFormComponent from "./forms/updateUnitForm.component";
import UpdateSliderFormComponent from "./forms/updateSliderForm.component";
import UpdateAdvantageFormComponent from "./forms/updateAdvantageForm.component";
import UpdatePageFormComponent from "../panel/updatePageForm.component";
import UpdateConfigFormComponent from "../panel/updateConfigForm.component";
import UpdateStaticFormComponent from "../panel/updateStaticForm.component";
import UpdateLanguageFormComponent from "../panel/updateLanguageForm.component";
import UpdateRoleFormComponent from "../panel/updateRoleForm.component";
import UpdateLocationFormComponent from "../panel/updateLocationForm.component";
import UpdateSectorFormComponent from "../panel/updateSectorForm.component";
import UpdateBannersFormComponent from "./forms/updateBannersForm.component";
import UpdateStockFormComponent from "./forms/updateStockForm.component";
import UpdateWebPageFormComponent from "./forms/updateWebPageForm.component";
import UpdateGroupFormComponent from "../panel/updateGroupForm.component";

/*TODO - готово*/

export const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']                                         // remove formatting button
];

const OneWebComponent = ({pageType}) => {
    return (
        <>
            {
                pageType === 'news' && <UpdateNewsFormComponent />
            }
            {
                pageType === 'unitTypes' && <UpdateUnitTypeFormComponent />
            }
            {
                pageType === 'units' && <UpdateUnitFormComponent />
            }
            {
                pageType === 'sliders' && <UpdateSliderFormComponent />
            }
            {
                pageType === 'advantages' && <UpdateAdvantageFormComponent />
            }
            {
                pageType === 'pages' && <UpdatePageFormComponent />
            }
            {
                pageType === 'config' && <UpdateConfigFormComponent />
            }
            {
                pageType === 'statics' && <UpdateStaticFormComponent />
            }
            {
                pageType === 'languages' && <UpdateLanguageFormComponent />
            }
            {
                pageType === 'roles' && <UpdateRoleFormComponent />
            }
            {
                pageType === 'locations' && <UpdateLocationFormComponent />
            }
            {
                pageType === 'sectors' && <UpdateSectorFormComponent />
            }
            {
                pageType === 'banners' && <UpdateBannersFormComponent />
            }
            {
                pageType === 'stock' && <UpdateStockFormComponent />
            }
            {
                pageType === 'web_pages' && <UpdateWebPageFormComponent />
            }
            {
                pageType === 'groups' && <UpdateGroupFormComponent />
            }
        </>

    );
};

export default observer(OneWebComponent);