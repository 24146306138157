import React from 'react';
import {Button, Empty, Flex, Form, Input, Modal, notification, Space, Spin, Tag} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import T from "../common/t";
import LocationMethods from "../../methods/location.methods";
import {menu} from "../../routes";

const UpdateLocationFormComponent = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [pubDate, setPubDate] = React.useState('');
    const [pub, setPub] = React.useState(false);
    const location = useLocation();
    const [updateDate, setUpdateDate] = React.useState('');
    const [onePage, setOnePage] = React.useState({});
    const [loadingForm, setLoadingForm] = React.useState(false);
    const navigate = useNavigate();
    const [sectors, setSectors] = React.useState([]);


    const [form] = Form.useForm();

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    const getDataOneNews = React.useCallback(() => {
        (async () => {
            const [
                onePage,
            ] = await Promise.allSettled([
                LocationMethods.getLocationById(location.pathname.split('/').at(-1)),
            ]);
            if (
                onePage.status === 'fulfilled'
            ) {
                setOnePage(() => onePage.value.data);
                setSectors(() => onePage.value.data.sectors);
                setLoadingForm(() => false)
            }
        })()
    }, [location.pathname])


    React.useEffect(() => {
        getDataOneNews()
    }, []);

    const handleOk = () => {
        setLoadingForm(true);

        LocationMethods.updateLocationById(location.pathname.split('/').at(-1), {
            name: form.getFieldValue('name'),
            address: form.getFieldValue('address'),
            isActive: pub,

        })
            .then(res => {
                form.resetFields();
                openNotificationWithIcon('success', <T>Локация успешно обновлена</T>, []);
                setTimeout(() => {
                    setIsModalOpen(false);
                    setLoadingForm(false);
                    window.location.reload();
                }, 1000);
            })
            .catch(error => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    React.useEffect(() => {
        form.setFieldValue(`name`, onePage.name)
        form.setFieldValue(`address`, onePage.address)
        setPub(() => onePage.isActive)
        setPubDate(() => onePage.createdAt)
        setUpdateDate(() => onePage.updatedAt)
    }, [form, onePage]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const deleteNews = () => {
        LocationMethods.deleteLocationById(location.pathname.split('/').at(-1))
            .then(() => {
                openNotificationWithIcon('success', <T>Локация удалена</T>, null);
                setTimeout(() => {
                    navigate(-1)
                }, 1000);
            })
            .catch((err) => {
                openNotificationWithIcon('error', <T>Ошибка при удалении</T>, err.response?.data?.errors);
            })
    }


    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handlePub = () => {
        setPub(() => !pub)
    }

    return (
        <>
            {contextHolder}
            {
                loadingForm
                    ?
                    <Flex justify="center" align="center" style={{height: '176px'}}>
                        <Spin size="large" />
                    </Flex>
                    :
                    <>
                        <Form
                            name="update_room"
                            style={{ width: '100%', padding: '1rem'}}
                            form={form}
                            multipart={"form-data"}
                            layout="vertical"
                            onFinish={handleOk}
                        >
                            <Flex justify="space-between" gap={20}>
                                <Flex vertical gap={20} style={{width: '100%'}}>

                                    <Form.Item
                                        hasFeedback
                                        style={{width: '100%', marginBottom: '1rem',}}
                                        label={<T>Название</T>}
                                        name={`name`}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input size={"large"} />
                                    </Form.Item>
                                    <Form.Item
                                        hasFeedback
                                        style={{width: '100%', marginBottom: '1rem',}}
                                        label={<T>Адрес</T>}
                                        name={`address`}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input size={"large"} />
                                    </Form.Item>
                                    <Form.Item
                                        label={<T>Сектора</T>}
                                    >
                                        {
                                            sectors.length > 0
                                                ? sectors.map(sector =>
                                                    <Space key={sector.id} size="middle">
                                                        <Link style={{marginRight: '16px'}} to={`${menu.settings.path + '/' + menu.settings.children.sectors.path}/${sector.id}`}><b>{sector.name}</b></Link>
                                                    </Space>
                                                )
                                                :
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
                                                    <Link to={menu.settings.path + '/' + menu.settings.children.sectors.path}><T>Sectors</T></Link>
                                                </Empty>

                                        }

                                    </Form.Item>
                                </Flex>
                                <Flex vertical style={{width: '20%'}}>
                                    <Form.Item
                                        label={<T>Опубликовать</T>}
                                        name={'isActive'}
                                    >
                                        <Input size={"large"} type={"checkbox"} checked={pub} onChange={handlePub}/>
                                    </Form.Item>
                                    <Flex vertical style={{width: '100%', position: 'sticky', top: '1rem'}}>
                                        <Form.Item
                                            label={<T>Дата публикации</T>}
                                        >
                                            <Input type={"dateTime"} disabled value={
                                                pubDate?.replaceAll('-', '.')
                                                    .replace('T', ' ')
                                            }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={<T>Дата обновления</T>}
                                        >
                                            <Input type={"dateTime"} disabled value={
                                                updateDate?.replaceAll('-', '.')
                                                    .replace('T', ' ')
                                            }
                                            />
                                        </Form.Item>
                                        <Form.Item shouldUpdate>
                                            {
                                                () => (
                                                    <Button
                                                        size={"large"}
                                                        style={{width: '100%', marginTop: '6px'}}
                                                        loading={false}
                                                        type="primary"
                                                        htmlType="submit"
                                                    >
                                                        <T>Сохранить</T>
                                                    </Button>
                                                )
                                            }
                                        </Form.Item>
                                        <Button
                                            size={"large"}
                                            style={{width: '100%', marginTop: '6px'}}
                                            loading={false}
                                            onClick={showModal}
                                            danger={true}
                                        >
                                            <T>Удалить локацию</T>
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Form>

                        <Modal title={<T>Удалить локацию</T>} open={isModalOpen} onOk={deleteNews} onCancel={handleCancel}>
                            <p>{<T>Вы точно хотите удалить локацию</T>} ?</p>
                        </Modal>
                    </>
            }
        </>
    );
};

export default UpdateLocationFormComponent;