import React from "react";
import {UserMethods} from "../methods/user.methods";
import {jwtDecode} from "jwt-decode";
import {useNavigate} from "react-router-dom";
import {menu} from "../routes";

export const useAuth = () => {
    const [user, setUser] = React.useState(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (localStorage.getItem('token')) {
            UserMethods.check()
                .then(() => {
                    setUser(jwtDecode(localStorage.getItem('token')));
                })
                .catch((error) => {
                    if (error?.message !== "Network Error") {
                        localStorage.removeItem('token');
                        setUser(null);
                        navigate(menu.login.path);
                    } else {
                        window.location.reload();
                    }
                }
            )
        } else {
            setUser(null);
            navigate(menu.login.path);
        }
    }, [navigate]);

    return user;
};