import {$host} from "../http/axios";

export default class UnitTypesMethods {
    static addUnitType = async (formData) => {
        const {data} = await $host.post(`/unitTypes/add`, formData);
        return data;
    }
    static getOneUnitType = async (id) => {
        const {data} = await $host.post(`/unitTypes/get/${id}`);
        return data;
    }

    static getAll = async () => {
        const {data} = await $host.post(`/unitTypes/all?limit=1000000000`);
        return data;
    }

    static getAllByLang = async (isoCode) => {
        const {data} = await $host.post(`/unitTypes/get/lang/${isoCode}?limit=1000000000`);
        return data;
    }

    static updateUnitType = async (id, formData) => {
        const {data} = await $host.post(`/unitTypes/update/${id}`, formData);
        return data;
    }

    static getByLangNews = async (id, isoCode) => {
        const {data} = await $host.post(`/unitTypes/get/${id}/lang/${isoCode}`);
        return data;
    }

    static deleteUnitType = async (id) => {
        const {data} = await $host.post(`/unitTypes/delete/${id}`);
        return data;
    }
}