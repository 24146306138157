import {makeAutoObservable} from "mobx";

export default class LangsStore{
    constructor() {
        this._langs = [];
        this._lang = 'lv';
        makeAutoObservable(this);
    }

    setLangs(data) {this._langs = data}

    get isLangs() {return this._langs}

    setLang(data) {this._lang = data}

    get isLang() {return this._lang}
}