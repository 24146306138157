import React from "react";
import StaticMethods from "../../methods/static.methods";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {Spin} from "antd";
import {toJS} from "mobx";

const T = ({children}) => {
    const {static_store} = React.useContext(Context);
    const [translate, setTranslate] = React.useState(children);
    const [loading, setLoading] = React.useState(true);

    React.useMemo(() => {
        if (Object.keys(toJS(static_store.isStatics)).length > 0 && !toJS(static_store.isStatics)[children]){
            console.log(toJS(static_store.isStatics));
            setTranslate(children);
            const formData = new FormData();
            formData.append('name', children);
            formData.append('field', children);
            setTimeout(() => {
                StaticMethods.addStatic(formData).then().catch(error => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                })
            }, 100);
        } else {
            setTranslate(static_store.isStatics[children]);
        }
        // setTranslate(children);

        setLoading(false)
    }, [children, static_store.isStatics]);


    return (
        loading
            ?
                <Spin size="small"/>
            :
                translate
    );
};

export default observer(T);
