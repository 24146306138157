import {$host} from "../http/axios";

export default class RoleMethods {
    static addStatic = async (formData) => {
        const {data} = await $host.post(`/sector/add`, formData);
        return data;
    }

    static getRoles = async () => {
        const {data} = await $host.post(`/user/role/all?limit=1000`);
        return data;
    }

    static getRoleById = async (id) => {
        const {data} = await $host.post(`/user/role/get/${id}`);
        return data;
    }

    static updateSectorById = async (id, formData) => {
        const {data} = await $host.post(`/sector/update/${id}`, formData);
        return data;
    }
}