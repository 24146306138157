import React from 'react';
import {Empty, Flex} from "antd";
import {observer} from "mobx-react-lite";

const AutomationComponent = () => {

    return (
        <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Flex>
    );
};

export default observer(AutomationComponent);