import React from 'react';
import {Divider, Flex, List} from "antd";
import {menu} from "../routes";
import {Link} from "react-router-dom";
import T from "./common/t";
import {observer} from "mobx-react-lite";

const SettingsComponent = () => {
    return (
        <>
            <Divider orientation="left"><T>Основное</T></Divider>
            <List
                size="large"
                bordered={false}
                dataSource={Object.values(menu.settings.children)}
                renderItem={item =>
                    item.type === 'basic' &&
                    <List.Item key={item.path}>
                        <Flex style={{width: '100%'}} justify="space-between" align="middle">
                            <T>{item.label}</T>
                            <Link to={menu.settings.path + '/' + item.path}><T>Перейти</T></Link>
                        </Flex>
                    </List.Item>
                }
            />
            <Divider orientation="left"><T>Web</T></Divider>
            <List
                size="large"
                bordered={false}
                dataSource={Object.values(menu.settings.children)}
                renderItem={item =>
                    item.type === 'web' &&
                    <List.Item key={item.path}>
                        <Flex style={{width: '100%'}} justify="space-between" align="middle">
                            <T>{item.label}</T>
                            <Link to={menu.settings.path + '/' + item.path}><T>Перейти</T></Link>
                        </Flex>
                    </List.Item>

                }
            />
            <Divider orientation="left"><T>Дополнительно</T></Divider>
            <List
                size="large"
                bordered={false}
                dataSource={Object.values(menu.settings.children)}
                renderItem={(item) =>
                    item.type === 'additionally' &&
                    <List.Item>
                        <Flex style={{width: '100%'}} justify="space-between" align="middle">
                            <T>{item.label}</T>
                            <Link to={menu.settings.path + '/' + item.path}><T>Перейти</T></Link>
                        </Flex>
                    </List.Item>
                }
            />
        </>
    );
};

export default observer(SettingsComponent);