import React from 'react';
import {useNavigate} from "react-router-dom";
import {UserMethods} from "../methods/user.methods";
import {Button, Card, Flex, Form, Input, Modal, notification, QRCode, Select, Space, Spin, Typography} from "antd";
import {menu} from "../routes";
import {phoneCodes} from "../phoneCodes";
import {jwtDecode} from "jwt-decode";
import T from "./common/t";
import {observer} from "mobx-react-lite";
import {Context} from "../index";

const ProfileComponent = () => {
    const navigate = useNavigate();
    const {lang_store, static_store} = React.useContext(Context);

    const [clientReady, setClientReady] = React.useState(false);

    const [loading, setLoading] = React.useState(true);

    const [first_name, setFirstName] = React.useState('');
    const [last_name, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone_code, setPhoneCode] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [role, setRole] = React.useState('');
    const [modal, setModal] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState('');
    const [modalText, setModalText] = React.useState('');
    const [deleteAccount, setDeleteAccount] = React.useState(false);

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors[0],
        });
    };

    React.useEffect(() => {
        UserMethods.getProfile()
            .then(res => {
                setFirstName(res.data.first_name);
                setLastName(res.data.last_name);
                setEmail(res.data.email);
                setPhoneCode(res.data.phone_code);
                setPhone(res.data.phone);
                setRole(res.data.role.name);
            })
            .finally(() => setLoading(false));
    }, []);

    // To disable submit button at the beginning.
    React.useEffect(() => {
        setClientReady(true);
    }, []);

    const [form] = Form.useForm();

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} ' + 'is required',
        types: {
            // eslint-disable-next-line
            email: '${label} ' + 'is not a valid email',
            // eslint-disable-next-line
            tel: '${label} ' + 'is not a valid phone number',
        },
    };

    const updateProfile = (values) => {
        setLoading(true);
        const formData = new FormData();
        const {id, roleId} = jwtDecode(localStorage.getItem('token'));
        formData.append('first_name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('phone_code', values.phone_code);
        formData.append('phone', values.phone);
        formData.append('roleId', roleId);
        formData.append('isActive', true);
        UserMethods.updateUser(id, formData)
            .then(() => {
                openNotificationWithIcon('success', <T>Профиль успешно обновлен</T>, []);
            })
            .catch(error => {
                if (error.response.data.errors.length > 0){
                    error.response.data.errors.map(err => {
                        openNotificationWithIcon('error', error.response.data.message, [err]);
                    })
                } else {
                    openNotificationWithIcon('error', error.response.data.message, [error.response.data.errors]);
                }
            })
            .finally(() => setLoading(false));
    }

    React.useEffect(() => {
        form.setFieldsValue({first_name, last_name, email, phone_code, phone, role})
    }, [form, first_name, last_name, email, phone_code, phone, role]);


    const prefixSelector = (
        <>
            +
            <Form.Item
                name="phone_code"
                noStyle
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select showSearch optionFilterProp="children" style={{ width: 100}} defaultValue={phone_code}
                        filterOption={(input, option) => (String(option?.label) ?? '').includes(String(input))}
                        filterSort={(optionA, optionB) =>
                            (String(optionA?.label) ?? '').toLowerCase().localeCompare((String(optionB?.label) ?? '').toLowerCase())
                        }
                        options={phoneCodes}
                />
            </Form.Item>
        </>

    );

    const switchLang = (lang) => {
        localStorage.setItem('lang', lang);
        lang_store.setLang(lang);
    }

    const popup = (title, text, onDelete) => {
        setModal(true);
        setModalTitle(title);
        setModalText(text);
        if (!onDelete) {
            setDeleteAccount(() => false)
        } else {
            setDeleteAccount(() => onDelete)
        }
    }

    const closePopup = () => {
        setModal(false);
        setModalTitle("");
        setModalText("");
    }

    const logout = () => {
        UserMethods.logout()
            .then(() => {
                localStorage.removeItem('token');
                navigate(menu.login.path)
            })
    }

    const deleteMyAccount = () => {
        UserMethods.deleteUser()
            .then(() => {
                localStorage.clear();
                navigate(menu.login.path)
            })
    }

    return (
        <Flex align="start" style={{ padding: '16px' }} wrap={"wrap"} justify={"center"}>
            {contextHolder}
            <Form
                style={{ width: '100%'}}
                form={form}
                multipart={"form-data"}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={updateProfile}
            >
                {

                loading
                    ?
                        <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                            <Spin size="large" />
                        </Flex>
                    :
                    <Flex justify="space-between">
                        <Flex vertical gap={20} style={{width: '100%'}}>
                            <Space size={"middle"} style={{marginBottom: '1rem'}}>
                                {
                                    lang_store.isLangs.slice().reverse().map((lang) =>
                                        <Button onClick={() => switchLang(lang.isoCode)} danger={lang_store.isLang === lang.isoCode}>
                                            {lang.isoCode.toUpperCase()}
                                        </Button>
                                    )
                                }
                            </Space>

                            <Form.Item
                                hasFeedback
                                label={<T>Имя</T>}
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input size={"large"} />
                            </Form.Item>
                            <Form.Item
                                hasFeedback
                                label={<T>Фамилия</T>}
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input size={"large"}/>
                            </Form.Item>
                            <Form.Item
                                hasFeedback
                                label={<T>Email</T>}
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input disabled size={"large"} />
                            </Form.Item>
                            <Form.Item
                                hasFeedback
                                label={<T>Телефон</T>}
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input addonBefore={prefixSelector} style={{ width: '100%' }} onKeyUp={e => e.target.value = e.target.value.replace(/\D/, '')} size={"large"} />
                            </Form.Item>
                            <Form.Item
                                hasFeedback
                                label={<T>Роль</T>}
                                name="role"
                            >
                                <Input disabled style={{ width: '100%' }} onKeyUp={e => e.target.value = e.target.value.replace(/\D/, '')} size={"large"} />
                            </Form.Item>
                        </Flex>
                        <Flex vertical style={{width: '20%'}}>
                            <Flex vertical style={{width: '100%', position: 'sticky', top: '0'}}>
                                <Card style={{boxShadow: 'none'}} bordered={false}>
                                    <Form.Item shouldUpdate>
                                        {
                                            () => (
                                                <Button size={"large"} style={{width: '100%', marginTop: '6px'}} htmlType="submit" loading={false} disabled={
                                                    !clientReady ||
                                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                                } type="primary"><T>Сохранить</T></Button>
                                            )
                                        }
                                    </Form.Item>
                                    <Button size={"large"} style={{width: '100%'}} onClick={() => popup(<T>Выйти</T>, "Вы уверены, что хотите выйти из аккаунта", false)}><T>Выйти</T></Button>
                                    <Button size={"large"} style={{width: '100%', marginTop: '1.5rem'}} disabled={true} danger onClick={() => popup(<T>Удалить аккаунт</T>, "Вы уверены, что хотите удалить аккаунт", true)}><T>Удалить аккаунт</T></Button>
                                </Card>
                            </Flex>
                        </Flex>
                    </Flex>
                }
            </Form>

            <Modal title={modalTitle} open={modal} onOk={deleteAccount ? deleteMyAccount : logout} onCancel={closePopup}>
                        <Typography.Text><T>{modalText}</T>?</Typography.Text>
            </Modal>
        </Flex>
    );
};

export default observer(ProfileComponent);