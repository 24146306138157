import React from 'react';
import SettingsComponent from "../components/settings.component";
import MainComponent from "../components/common/main.component";
import AllComponent from "../components/common/all.component";

const SettingsPage = () => {
    return (
        <AllComponent>
            <MainComponent title="Settings" showButton={false}>
                <SettingsComponent />
            </MainComponent>
        </AllComponent>
    );
};

export default SettingsPage;