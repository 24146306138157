import {$host} from "../http/axios";

export class UserMethods {
    static login = async (formData) => {
        const {data} = await $host.post(`/user/login`, formData);
        return data;
    }

    static registration = async (formData) => {
        const {data} = await $host.post(`/user/registration`, formData);
        return data;
    }

    static logout = async () => {
        const {data} = await $host.post(`/user/logout`);
        return data;
    }

    static confirm = async (formData) => {
        const {data} = await $host.post(`/user/confirm`, formData);
        return data;
    }

    static check = async () => {
        const {data} = await $host.post(`/user/check`);
        return data;
    }

    static getProfile = async () => {
        const {data} = await $host.post(`/user/profile`);
        return data;
    }

    static getMyRooms = async (lang) => {
        const {data} = await $host.post(`/user/rooms/${lang}?limit=1000`);
        return data;
    }

    static getUsers = async () => {
        const {data} = await $host.post(`/user/all?limit=1000`);
        return data;
    }

    static addUser = async (formData) => {
        const {data} = await $host.post(`/user/add`, formData);
        return data;
    }

    static getUserById = async (id) => {
        const {data} = await $host.post(`/user/get/${id}`);
        return data;
    }

    static updateUser = async (id, formData) => {
        const {data} = await $host.post(`/user/update/${id}`, formData);
        return data;
    }

    static deleteUser = async () => {
        const {data} = await $host.post(`/user/delete`);
        return data;
    }
}