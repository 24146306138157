import LoginPage from "./pages/login.page";
import RegistrationPage from "./pages/registration.page";
import DashboardPage from "./pages/dashboard.page";
import {
    AppstoreOutlined,
    LockOutlined,
    SettingOutlined,
    ShopOutlined,
    TeamOutlined
} from "@ant-design/icons";
import RoomsPage from "./pages/rooms.page";
import DevicesPage from "./pages/devices.page";
import UsersPage from "./pages/users.page";
import SettingsPage from "./pages/settings.page";
import MessagePage from "./pages/message.page";
import MessagesPage from "./pages/messages.page";
import ProfilePage from "./pages/profile.page";
import RoomPage from "./pages/room.page";
import ServicesPage from "./pages/services.page";
import AutomationPage from "./pages/automation.page";
import DevicePage from "./pages/device.page";
import UserPage from "./pages/user.page";
import RoomByLangPage from "./pages/roomByLang.page";
import LoggerPage from "./pages/logger.page";
import AllDataPage from "./pages/allData.page";
import OneDataPage from "./pages/oneData.page";
import {jwtDecode} from "jwt-decode";

export const menu = {
    login: {path: "/login", label: "Авторизация", type: "", icon: ""},
    registration: {path: "/registration", label: "Регистрацуия", type: "", icon: ""},
    dashboard: {path: "/", label: "Dashboard", type: "menu", icon: AppstoreOutlined},
    rooms: {path: "/rooms", label: "Помещения", type: "menu", icon: ShopOutlined},
    devices: {path: "/devices", label: "Устройства", type: "menu", icon: LockOutlined},
    users: {path: "/users", label: "Пользователи", type: "menu", icon: TeamOutlined},
    settings: {path: "/settings", label: "Настройки", type: "menu", icon: SettingOutlined, children:
        {
            languages: {path: "languages", label: "Языки", type: "basic"},
            roles: {path: "roles", label: "Роли", type: "basic"},
            statics: {path: "statics", label: "Статика", type: "basic"},
            locations: {path: "locations", label: "Локации", type: "basic"},
            sectors: {path: "sectors", label: "Сектора", type: "basic"},
            services: {path: "services", label: "Сервисы", type: "basic"},
            pages: {path: "pages", label: "Страницы", type: "basic"},
            config: {path: "config", label: "Конфиг", type: "additionally"},
            logger: {path: "logger", label: "Логирование", type: "additionally"},
            automation: {path: "automation", label: "Автоматизации", type: "additionally"},
            // banners: {path: "banners", label: "Баннер", type: "web"},
            stock: {path: "stock", label: "Адреса", type: "web"},
            news: {path: "news", label: "Новости", type: "web"},
            unitTypes: {path: "unitTypes", label: "Типы кладовок", type: "web"},
            units: {path: "units", label: "Кладовки", type: "web"},
            sliders: {path: "sliders", label: "Слайдер", type: "web"},
            advantages: {path: "advantages", label: "Преимущества", type: "web"},
            web_pages: {path: "web_pages", label: "Web страницы", type: "web"},
            groups: {path: "groups", label: "Группировка Ajax устройств", type: "basic"},
        }
    },
    profile: {path: "/profile", label: "Настройки", type: "", icon: ""},
    messages: {path: "/messages", label: "Сообщения", type: "", icon: ""},
}

export const userMenu = {
    rooms: {path: "/", label: "Помещения", type: "menu", icon: ShopOutlined},
    profile: {path: "/profile", label: "Настройки", type: "", icon: ""},
    messages: {path: "/messages", label: "Сообщения", type: "", icon: ""},
}

export const securityMenu = {
    rooms: {path: "/", label: "Управление воротами", type: "menu", icon: AppstoreOutlined},
    profile: {path: "/profile", label: "Настройки", type: "", icon: ""},
    messages: {path: "/messages", label: "Сообщения", type: "", icon: ""},
}

export const admin_routes = {
    login: {path: menu.login.path, element: <LoginPage />},
    registration: {path: menu.registration.path, element: <RegistrationPage />},
    dashboard: {path: menu.dashboard.path, element: <DashboardPage />},
    rooms: {path: menu.rooms.path, element: <RoomsPage />},
    room: {path: menu.rooms.path + '/:id', element: <RoomPage />},
    roomLang: {path: menu.rooms.path + '/:id' + '/:isoCode', element: <RoomByLangPage />},
    devices: {path: menu.devices.path, element: <DevicesPage />},
    device: {path: menu.devices.path + '/:id', element: <DevicePage />},
    users: {path: menu.users.path, element: <UsersPage />},
    user: {path: menu.users.path + '/:id', element: <UserPage />},
    settings: {path: menu.settings.path, element: <SettingsPage />},

    languages:{path: menu.settings.path + '/' + menu.settings.children.languages.path, element: <AllDataPage />},
    language:{path: menu.settings.path + '/' + menu.settings.children.languages.path + '/:id', element: <OneDataPage />},
    roles: {path: menu.settings.path + '/' + menu.settings.children.roles.path, element: <AllDataPage />},
    role: {path: menu.settings.path + '/' + menu.settings.children.roles.path + '/:id', element: <OneDataPage />},
    statics:{path: menu.settings.path + '/' + menu.settings.children.statics.path, element: <AllDataPage />},
    static:{path: menu.settings.path + '/' + menu.settings.children.statics.path + "/:id", element: <OneDataPage />},
    locations:{path: menu.settings.path + '/' + menu.settings.children.locations.path, element: <AllDataPage />},
    location: {path: menu.settings.path + '/' + menu.settings.children.locations.path + '/:id', element: <OneDataPage />},
    sectors: {path: menu.settings.path + '/' +  menu.settings.children.sectors.path, element: <AllDataPage />},
    sector: {path: menu.settings.path + '/' + menu.settings.children.sectors.path + '/:id', element: <OneDataPage />},
    services: {path: menu.settings.path + '/' + menu.settings.children.services.path, element: <ServicesPage />},
    pages: {path: menu.settings.path + '/' + menu.settings.children.pages.path, element: <AllDataPage />},
    page: {path: menu.settings.path + '/' + menu.settings.children.pages.path + '/:id', element: <OneDataPage />},
    web_pages: {path: menu.settings.path + '/' + menu.settings.children.web_pages.path, element: <AllDataPage />},
    web_page: {path: menu.settings.path + '/' + menu.settings.children.web_pages.path + '/:id', element: <OneDataPage />},
    groups: {path: menu.settings.path + '/' + menu.settings.children.groups.path, element: <AllDataPage />},
    group: {path: menu.settings.path + '/' + menu.settings.children.groups.path + '/:id', element: <OneDataPage />},
    config: {path: menu.settings.path + '/' + menu.settings.children.config.path, element: <OneDataPage />},
    logger: {path: menu.settings.path + '/' + menu.settings.children.logger.path, element: <LoggerPage />},
    automation: {path: menu.settings.path + '/' + menu.settings.children.automation.path, element: <AutomationPage />},

    news: {path: menu.settings.path + '/' + menu.settings.children.news.path, element: <AllDataPage />},
    newsOne: {path: menu.settings.path + '/' + menu.settings.children.news.path + '/:id', element: <OneDataPage />},

    // banners: {path: menu.settings.path + '/' + menu.settings.children.banners.path, element: <AllDataPage />},
    // bannerOne: {path: menu.settings.path + '/' + menu.settings.children.banners.path + '/:id', element: <OneDataPage />},

    stocks: {path: menu.settings.path + '/' + menu.settings.children.stock.path, element: <AllDataPage />},
    stockOne: {path: menu.settings.path + '/' + menu.settings.children.stock.path + '/:id', element: <OneDataPage />},

    unitTypes: {path: menu.settings.path + '/' + menu.settings.children.unitTypes.path, element: <AllDataPage />},
    unitTypesOne: {path: menu.settings.path + '/' + menu.settings.children.unitTypes.path + '/:id', element: <OneDataPage />},

    units: {path: menu.settings.path + '/' + menu.settings.children.units.path, element: <AllDataPage />},
    unitsOne: {path: menu.settings.path + '/' + menu.settings.children.units.path + '/:id', element: <OneDataPage />},

    slider: {path: menu.settings.path + '/' + menu.settings.children.sliders.path, element: <AllDataPage />},
    sliderOne: {path: menu.settings.path + '/' + menu.settings.children.sliders.path + '/:id', element: <OneDataPage />},

    advantages: {path: menu.settings.path + '/' + menu.settings.children.advantages.path, element: <AllDataPage />},
    advantagesOne: {path: menu.settings.path + '/' + menu.settings.children.advantages.path + '/:id', element: <OneDataPage />},

    profile: {path: menu.profile.path, element: <ProfilePage />},
    messages: {path: menu.messages.path, element: <MessagesPage />},
    message: {path: menu.messages.path + '/:id', element: <MessagePage />},
    all: {path: '*', element: <DashboardPage />},
}

export const user_routes = {
    login: {path: menu.login.path, element: <LoginPage />},
    registration: {path: menu.registration.path, element: <RegistrationPage />},
    rooms: {path: menu.dashboard.path, element: <RoomsPage />},
    roomLang: {path: menu.rooms.path + '/:id' + '/:isoCode', element: <RoomByLangPage />},
    profile: {path: menu.profile.path, element: <ProfilePage />},
    messages: {path: menu.messages.path, element: <MessagesPage />},
    message: {path: menu.messages.path + '/:id', element: <MessagePage />},
    all: {path: '*', element: <RoomsPage />},
}

export const security_routes = {
    login: {path: menu.login.path, element: <LoginPage />},
    registration: {path: menu.registration.path, element: <RegistrationPage />},
    rooms: {path: menu.dashboard.path, element: <DashboardPage />},
    profile: {path: menu.profile.path, element: <ProfilePage />},
    messages: {path: menu.messages.path, element: <MessagesPage />},
    message: {path: menu.messages.path + '/:id', element: <MessagePage />},
    all: {path: '*', element: <DashboardPage />},
}

export const routes = []

if (localStorage.getItem('token') && (Number(jwtDecode(localStorage.getItem('token')).roleValue) === 1 || Number(jwtDecode(localStorage.getItem('token')).roleValue) === 2)) {
    Object.values(admin_routes).forEach(url => {
        routes.push(url)
    });
} else if (localStorage.getItem('token') && Number(jwtDecode(localStorage.getItem('token')).roleValue) === 3) {
    Object.values(security_routes).forEach(url => {
        routes.push(url)
    });
} else {
    Object.values(user_routes).forEach(url => {
        routes.push(url)
    });
}

export default routes;