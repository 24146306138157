import React from 'react';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import {
    Button,
    Card,
    Divider,
    Flex,
    notification,
    Space,
    Spin,
    Table,
    Tabs,
    Tag,
    Typography
} from "antd";
import {Doughnut} from "react-chartjs-2";
import DeviceMethods from "../methods/device.methods";
import {useAuth} from "../hooks/useAuth";
import LocationMethods from "../methods/location.methods";
import {UserMethods} from "../methods/user.methods";
import RoomMethods from "../methods/room.methods";
import {useNavigate} from "react-router-dom";
import {menu} from "../routes"
import T from "./common/t";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {PoweroffOutlined} from "@ant-design/icons";
import HistoryMethods from "../methods/history.methods";

ChartJS.register(ArcElement, Tooltip, Legend);
export const dataRooms = {
    labels: ['Арендовано', 'Свободно'],
    datasets: [
        {
            label: '# Count',
            data: [0, 0],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)'
            ],
            borderWidth: 1,
        },
    ],
};

export const dataLooks = {
    labels: ['Online', 'Offline'],
    datasets: [
        {
            label: '# Count',
            data: [0, 0],
            backgroundColor: [
                'rgba(87,189,54,0.2)',
                'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
                'rgb(66,196,68)',
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

export const dataLooksTuya = {
    labels: ['Online', 'Offline'],
    datasets: [
        {
            label: '# Count',
            data: [0, 0],
            backgroundColor: [
                'rgba(87,189,54,0.2)',
                'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
                'rgb(66,196,68)',
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

export const dataUsers = {
    labels: ['Активный', 'Неактивный'],
    datasets: [
        {
            label: '# Count',
            data: [0, 0],
            backgroundColor: [
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
                'rgba(54, 162, 235, 1)',
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const DevicesActions = ({group, locations, devices, openNotificationWithIcon}) => {


    const user = useAuth();

    const [userName, setUserName] = React.useState('');
    React.useEffect(() =>  {
        (async  () =>  {
            UserMethods.getProfile().then(res  =>  {
                setUserName(() => res.data.first_name + ' ' + res.data.last_name);
            })
        })()
    }, [user]);

    function sendCommand(device){
        const formData = new FormData();
        formData.append("userId", device.service.userId);
        formData.append("hubId", device.hubId);
        formData.append("deviceId", device.deviceId);
        formData.append("deviceType", device.deviceType);
        formData.append("userName", userName);
        formData.append("deviceName", device.name);
        formData.append("command", "switchStateGate");
        DeviceMethods.setCommand('ajax', formData).then(() => {
            openNotificationWithIcon('success', <T>'Команда успешно отправлена'</T>, []);
        }).catch((error) => {
            openNotificationWithIcon('error', error.response.data.message, error.response.data.errors ? error.response.data.errors : []);
        })
    }

    const compare = (a, b) => {
        if (a.name.at(0).toUpperCase() < b.name.at(0).toUpperCase())
            return 1;
        if (a.name.at(0).toUpperCase() > b.name.at(0).toUpperCase())
            return -1;
        return 0;
    }

    return (
        <>
            <Space size="large" direction={"vertical"}>
                {
                    Object.keys(locations).length > 0
                    &&
                    Object.values(locations).toSorted((a, b) => compare(a, b)).map(location =>
                        <>
                            <Typography.Title level={3}>{location.name}</Typography.Title>
                            {
                                location.sectors.length > 0
                                &&
                                <Flex gap={16} size="large" align="start" wrap="wrap">
                                    {
                                        location.sectors.map(sector =>
                                            Object.values(devices).filter(a => Number(a.groups[0]?.value) === Number(group)).map(device =>
                                                device.deviceType === "Relay"
                                                &&
                                                device.sectors[sector]
                                                &&
                                                <>
                                                    <Button disabled={device.status === 'offline'} onClick={() => sendCommand(device)} type="primary" size="large" ><T>Открыть / Закрыть</T>&nbsp;{device.name}</Button>
                                                </>
                                            )
                                        )
                                    }
                                </Flex>
                            }
                        </>
                    )
                }
            </Space>
        </>

    )
}

const DevicesTuyaActions = ({devices, openNotificationWithIcon}) => {

    const user = useAuth();

    const [userName, setUserName] = React.useState('');
    React.useEffect(() =>  {
        (async  () =>  {
            UserMethods.getProfile().then(res  =>  {
                setUserName(() => res.data.first_name + ' ' + res.data.last_name);
            })
        })()
    }, [user]);

    const [status, setStatus] = React.useState({});

    React.useEffect(() => {
        const st = {};
        Object.values(devices).forEach(device => {
            DeviceMethods.getTuyaDeviceStatus({device_id: device.deviceId}).then((response) => {
                // console.log('status', response.result.status[13], response.result.name);
                if (response.result.status[13]) {
                    st[response.result.name] = response.result.status[13].value;
                    setStatus((prevState) => ({...prevState,...st}));
                }
            })
        })
        setStatus(() => st);

    }, []);

    const setCommandCloseDoorTuya = (deviceId, deviceName) => {
        // закрывет дверь
        DeviceMethods.getPswTicketTuya({device_id: deviceId}).then((response) => {
            if (response.success) {
                DeviceMethods.getTuyaDeviceState({device_id: deviceId}).then((response1) => {
                    if (response1.success) {
                        DeviceMethods.setCommandOpenDoor(
                            {
                                device_id: deviceId,
                                commands: {
                                    ticket_id: response.result.ticket_id,
                                    open: false
                                },
                                userName,
                                deviceName,
                                command: "close"
                            }).then((response) => {
                            if (!response1.success) {
                                openNotificationWithIcon('error', response.msg, []);
                            }
                            openNotificationWithIcon('success', <T>Команда успешно отправлена</T>, []);
                            setTimeout(() => {
                                window.location.reload();
                            }, 6000)

                        })
                    }
                })
            }
        });
    }

    const setCommandOpenDoorTuya = (deviceId, deviceName) => {
        // закрывет дверь
        DeviceMethods.getPswTicketTuya({device_id: deviceId}).then((response) => {
            if (response.success) {
                DeviceMethods.getTuyaDeviceState({device_id: deviceId}).then((response1) => {
                    if (response1.success) {
                        DeviceMethods.setCommandOpenDoor(
                            {
                                device_id: deviceId,
                                commands: {
                                    ticket_id: response.result.ticket_id, open: true
                                },
                                userName,
                                deviceName,
                                command: "open"
                            }).then((response) => {
                            if (!response1.success) {
                                openNotificationWithIcon('error', response.msg, []);
                            }
                            openNotificationWithIcon('success', <T>Команда успешно отправлена</T>, []);
                            setTimeout(() => {
                                window.location.reload();
                            }, 6000)
                        })
                    }
                })
            }
        });
    }

    return (
        <>
            <Space size="large" direction={"vertical"}>
                {
                    Object.keys(devices).length > 0
                    &&
                    <Space size={"middle"} wrap={true}>
                        {
                            Object.values(devices).map(device =>
                                device.deviceType === "Smart lock"
                                &&
                                    <Card key={device.deviceId}>
                                        <Divider style={{marginTop: 0}} orientation={"left"}>
                                            {device.name}
                                        </Divider>
                                        <Button disabled={status[device.name]} icon={<PoweroffOutlined/>} size={"large"} style={{width: '100%', marginTop: '6px'}}
                                                type={"primary"} loading={false} onClick={() => setCommandOpenDoorTuya(device.deviceId, device.name)}>Открыть</Button>
                                        <Button disabled={!status[device.name]} icon={<PoweroffOutlined/>} size={"large"} style={{width: '100%', marginTop: '6px'}}
                                                type={"primary"} loading={false} onClick={() => setCommandCloseDoorTuya(device.deviceId, device.name)}>Закрыть</Button>
                                    </Card>
                            )
                        }
                    </Space>
                }
            </Space>
        </>

    )
}

const columnsMy = [
    {
        title: <T>Пользователь</T>,
        dataIndex: 'userName',
        key: 'userName',
        width: '50%',
    },
    {
        title: <T>Утройство</T>,
        dataIndex: 'deviceName',
        key: 'deviceName',
        width: '30%',
    },
    {
        title: <T>Команда</T>,
        dataIndex: 'command',
        width: '20%',
        key: 'command',
        render: (_, record) => (
            record && record.command === 'open'  ||  record.command  ===  'close'
            ?
            <Tag color={record && record.command === 'open' ? 'volcano' : 'green'}
                 key={record && record.command}>
                <T>{record && record.command === 'open' ? 'open'.toUpperCase() : 'closed'.toUpperCase()}</T>
            </Tag>
            :
            <Tag color={'blue'}
                 key={record && record.command}>
                <T>{record && 'gate'.toUpperCase()}</T>
            </Tag>
        )
    },
    {
        title: <T>Дата</T>,
        dataIndex: 'createdAt',
        width: '20%',
        key: 'createdAt',
    },
];

const columnsTermVl = [
    {
        title: <T>Утройство</T>,
        dataIndex: 'name',
        key: 'name',
        width: '30%',
    },
    {
        title: <T>Температура</T>,
        dataIndex: 'temp_current',
        width: '20%',
        key: 'temp_current',
        render: (_, record) => (
            record && record.temp_current > 25
                ?
                <Tag color={'green'}>
                    {record.temp_current} ℃
                </Tag>
                :
                <Tag color={'blue'}>
                    {record.temp_current} ℃
                </Tag>
        )
    },
    {
        title: <T>Влажность</T>,
        dataIndex: 'humidity_value',
        width: '20%',
        key: 'humidity_value',
        render: (_, record) => (
            record && record.humidity_value > 40
                ?
                <Tag color={'green'}>
                    {record.humidity_value} %
                </Tag>
                :
                <Tag color={'blue'}>
                    {record.humidity_value} %
                </Tag>
        )
    },
];

const HistoryComponent = ({max}) => {
    const [history, setHistory]  = React.useState([]);

    React.useEffect(()  => {
        (async  ()  =>  {
            HistoryMethods.getHistories().then((response)  => {
                setHistory(() => max ? Object.values(response.data).sort((a, b) =>  a.createdAt > b.createdAt ? -1 : 1) : Object.values(response.data).sort((a, b) =>  a.createdAt > b.createdAt ? -1 : 1));
            });
        })()
    }, []);
    return  (
        <Table pagination={false} columns={columnsMy} dataSource={history.length > 0 && max ? history.slice(0, Number(max)) : history}/>
    )
}

const TempVlComponent = () => {
    const [data, setData]  = React.useState([]);

    const [res, setRes] = React.useState([])

    React.useEffect(()  => {
        (async  ()  =>  {
            DeviceMethods.getDevices('tuya').then(({data})  => {
                setData(() => Object.values(data).filter(a => a.deviceType === "Smart IR with T&H Sensor"))
            });
        })()
    }, []);

    React.useEffect(() => {
        if (data.length > 0) {
            data.forEach(item => {
                DeviceMethods.getTuyaDeviceProperties({device_id: item.deviceId}).then(({result}) => {
                    setRes((prev) => [
                        {
                            name: item.name,
                            temp_current: (result?.properties?.at(0).value * 0.1)?.toFixed(1),
                            humidity_value: (result?.properties?.at(1).value)?.toFixed(1)
                        }, ...prev]
                    )
                })
            })
        }
    }, [data])

    return (
        <Table pagination={false} columns={columnsTermVl} dataSource={res}/>
    )
}

const DashboardComponent = () => {
    const {static_store} = React.useContext(Context)
    const [loading, setLoading] = React.useState(true);
    const [devices, setDevices] = React.useState({});
    const [devicesTuya, setDevicesTuya] = React.useState({});
    const [locations, setLocations] = React.useState({});
    const [rooms, setRooms] = React.useState({});
    const [users, setUsers] = React.useState({});
    const navigate = useNavigate();

    const user = useAuth();

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    React.useEffect(() => {
        const online = []
        const offline = []
        const onlineTuya = []
        const offlineTuya = []
        const isActiveUsers = []
        const isRentedRooms = []
        if (user && Number(user.roleValue) === 3) {
            (async () => {
                const [
                    devices,
                    locations,
                    tuya
                ] = await Promise.allSettled([
                    DeviceMethods.getDevices('ajax'),
                    LocationMethods.getLocations(),
                    DeviceMethods.getDevices('tuya'),

                ]);
                if (
                    devices.status === 'fulfilled' &&
                    locations.status === 'fulfilled' &&
                    tuya.status === 'fulfilled'
                ) {
                    setDevices(() => devices.value.data);
                    setLocations(() => locations.value.data);
                    setDevicesTuya(() => tuya.value.data);
                    setLoading(false);
                }
            })()
        }
        else {
            (async () => {
                const [
                    devices,
                    rooms,
                    users,
                    locations,
                    tuya
                ] = await Promise.allSettled([
                        DeviceMethods.getDevices('ajax'),
                        RoomMethods.getRooms(),
                        UserMethods.getUsers(),
                        LocationMethods.getLocations(),
                        DeviceMethods.getDevices('tuya'),
                    ]
                );
                if (devices.status === 'fulfilled' && rooms.status === 'fulfilled' && users.status === 'fulfilled' && locations.status === 'fulfilled') {
                    setDevices(devices.value.data);
                    setDevicesTuya(tuya.value.data);
                    setRooms(rooms.value.data);
                    setLocations(() => locations.value.data);
                    setUsers(users.value.data);
                    if (Object.keys(devices.value.data).length > 0) {
                        Object.values(devices.value.data).forEach(device => {
                            if (device.status === 'online') online.push(device);
                            else offline.push(device);
                        })
                        dataLooks.datasets[0].data = [online.length, offline.length];
                        dataLooks.labels[0] = `Online - ${online.length}`;
                        dataLooks.labels[1] = `Offline - ${offline.length}`;
                    }
                    if (Object.keys(tuya.value.data).length > 0) {
                        Object.values(tuya.value.data).forEach(device => {
                            if (device.status === 'online') onlineTuya.push(device);
                            else offlineTuya.push(device);
                        })
                        dataLooksTuya.datasets[0].data = [onlineTuya.length, offlineTuya.length];
                        dataLooksTuya.labels[0] = `Online - ${onlineTuya.length}`;
                        dataLooksTuya.labels[1] = `Offline - ${offlineTuya.length}`;
                    }
                    if (Object.keys(rooms.value.data).length > 0) {
                        Object.values(rooms.value.data).forEach(room => {
                            if (room.isRented === 'Арендовано') isRentedRooms.push(room);
                        })
                        dataRooms.datasets[0].data = [isRentedRooms.length, Object.keys(rooms.value.data).length - isRentedRooms.length];
                        dataRooms.labels[0] = 'Арендовано ' + isRentedRooms.length;
                        dataRooms.labels[1] = `Свободно - ${Object.keys(rooms.value.data).length - isRentedRooms.length}`;
                    }
                    if (Object.keys(users.value.data).length > 0) {
                        Object.values(users.value.data).forEach(user => {
                            if (user.status === 'Активный') isActiveUsers.push(user);
                        })
                        dataUsers.datasets[0].data = [isActiveUsers.length, Object.keys(users.value.data).length - isActiveUsers.length];
                        dataUsers.labels[0] = `Активных - ${isActiveUsers.length}`
                        dataUsers.labels[1] = `Неактивных - ${Object.keys(users.value.data).length - isActiveUsers.length}`;
                    }
                    setLoading(false);
                }
            })()
        }

    }, [user]);

    const arr = [
        {id: 1, title: <T>Статистика</T>, component: <Flex align="start" >
                <Space style={{width: '100%'}} size="middle" wrap={true} align="top" direction={'horizontal'}>
                    <Card style={{width: '100%', height: '450px' }}>
                        <Typography.Title level={3}><T>История</T></Typography.Title>
                        <HistoryComponent max={5}/>
                    </Card>
                    <Card style={{width: '100%', height: '450px', overflow: "auto" }}>
                        <Typography.Title level={3}><T>Температура / Влажность</T></Typography.Title>
                        <TempVlComponent max={5}/>
                    </Card>
                    <Card style={{width: '100%'}}>
                        <Flex align="center" justify={"space-between"} >
                            <Typography.Title level={3}><T>Помещения</T></Typography.Title>
                            <Tag style={{marginBottom: '.8rem'}} color="magenta"><T>ВСЕГО</T> - {Object.keys(rooms).length}</Tag>
                        </Flex>
                        <Doughnut redraw={true} data={dataRooms} />
                        <Button style={{width: '100%', marginTop: '1rem'}} type="text" size={"large"} onClick={() => navigate(menu.rooms.path)}><T>Перейти</T></Button>
                    </Card>
                    <Card style={{width: '100%'}}>
                        <Flex align="center" justify={"space-between"} >
                            <Typography.Title level={3}><T>Пользователи</T></Typography.Title>
                            <Tag style={{marginBottom: '.8rem'}} color="magenta"><T>ВСЕГО</T> - {Object.keys(users).length}</Tag>
                        </Flex>
                        <Doughnut data={dataUsers} />
                        <Button style={{width: '100%', marginTop: '1rem'}} type="text" size={"large"} onClick={() => navigate(menu.users.path)}><T>Перейти</T></Button>
                    </Card>
                    <Card style={{width: '100%'}}>
                        <Flex align="center" justify={"space-between"} >
                        <Typography.Title level={3}><T>Устройства Ajax</T></Typography.Title>
                        <Tag style={{marginBottom: '.8rem'}} color="magenta"><T>ВСЕГО</T> - {Object.keys(devices).length}</Tag>
                        </Flex>
                        <Doughnut data={dataLooks} />
                        <Button style={{width: '100%', marginTop: '1rem'}} type="text" size={"large"} onClick={() => navigate(menu.devices.path)}><T>Перейти</T></Button>
                    </Card>
                    <Card style={{width: '100%'}}>
                        <Flex align="center" justify={"space-between"} >
                            <Typography.Title level={3}><T>Устройства Tuya</T></Typography.Title>
                            <Tag style={{marginBottom: '.8rem'}} color="magenta"><T>ВСЕГО</T> - {Object.keys(devicesTuya).length}</Tag>
                        </Flex>
                        <Doughnut data={dataLooksTuya} />
                        <Button style={{width: '100%', marginTop: '1rem'}} type="text" size={"large"} onClick={() => navigate(menu.devices.path)}><T>Перейти</T></Button>
                    </Card>
                </Space>
            </Flex>},
        {id: 2, title: <T>Ворота</T>, component: <Space size={"medium"} direction={"vertical"}>
                <DevicesActions group={1} devices={devices} locations={locations} openNotificationWithIcon={openNotificationWithIcon} />
        </Space>},
        {id: 3, title: <T>Двери</T>, component: <Space size={"medium"} direction={"vertical"}>
                <DevicesActions group={2} devices={devices} locations={locations} openNotificationWithIcon={openNotificationWithIcon}/>
        </Space>},
        {id: 4, title: <T>История</T>, component: <HistoryComponent />},
    ]

    const arr2 = [
        {id: 1, title: <T>Ворота</T>, component: <DevicesActions group={1} devices={devices} locations={locations} openNotificationWithIcon={openNotificationWithIcon}/>},
        {id: 2, title: <T>Двери</T>, component: <Space size={"medium"} direction={"vertical"}>
                <DevicesActions group={2} devices={devices} locations={locations} openNotificationWithIcon={openNotificationWithIcon}/>
        </Space>},
    ]


    return (
        loading
            ?
            <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                <Spin size="large" />
            </Flex>
            :
            <div style={{padding: '16px'}}>
                {
                    contextHolder
                }
                {
                    static_store.isLoading
                        ?
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "3rem 0 1rem 0"}}>
                                <Spin size="large"/>
                            </div>
                        :
                            user && Number(user.roleValue) === 3
                            ?
                                <Tabs
                                    defaultActiveKey="1"
                                    type="card"
                                    size={'large'}
                                    items={arr2.map(item => {
                                        return {
                                            label: item.title,
                                            key: item.id,
                                            children: item.component,
                                        };
                                    })}
                                />
                            :
                                <Tabs
                                    defaultActiveKey="1"
                                    type="card"
                                    size={'large'}
                                    items={arr.map(item => {
                                        return {
                                            label: item.title,
                                            key: item.id,
                                            children: item.component,
                                        };
                                    })}
                                />
                }

            </div>
    );
};

export default observer(DashboardComponent);