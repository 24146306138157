import React from 'react';
import {Button, Card, Form, Input, notification, Select, Spin, Typography} from 'antd';
import LOGO_IMG from "../img/logo.svg";
import {menu} from "../routes";
import {useNavigate} from "react-router-dom";
import {phoneCodes} from "../phoneCodes";
import {UserMethods} from "../methods/user.methods";
import T from "./common/t";
import {observer} from "mobx-react-lite";
import {Context} from "../index";

const RegistrationComponent = () => {
    const {lang_store, static_store} = React.useContext(Context);
    const navigate = useNavigate();
    const [clientReady, setClientReady] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors[0],
        });
    };

    const switchLang = (lang) => {
        localStorage.setItem('lang', lang);
        lang_store.setLang(lang);
    }

    // React.useEffect(() => {
    //     if (localStorage.getItem("token")) {
    //         return navigate(menu.loading.path);
    //     }
    // }, [navigate]);

    // To disable submit button at the beginning.
    React.useEffect(() => {
        setClientReady(true);
    }, []);

    const [form] = Form.useForm();

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} ' + 'is required',
        types: {
            // eslint-disable-next-line
            email: '${label} ' + 'is not a valid email',
            // eslint-disable-next-line
            tel: '${label} ' + 'is not a valid phone number',
        },
    };

    const registration = (values) => {
        setLoading(() => true);
        const formData = new FormData();
        formData.append('first_name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('email', values.email);
        formData.append('phone_code', values.prefix);
        formData.append('phone', values.phone);
        formData.append('isActive', false);
        formData.append('roleValue', '999');
        UserMethods.registration(formData)
            .then(() => {
                openNotificationWithIcon('success', <T>Вы успешно зарегистрировались, ожидайте, администратор должен активировать ваш аккаунт</T>, []);
                setTimeout(() => {
                    navigate(menu.login.path);
                }, 3000);
            })
            .catch(error => {
                console.log(error);
                openNotificationWithIcon('error', error.response?.data?.message, [error.response?.data?.errors])
            })
            .finally(() => setLoading(false));
    }

    const prefixSelector = (
        <>
            +
            <Form.Item
                name="prefix"
                noStyle
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select showSearch optionFilterProp="children" style={{ width: 100}}
                        filterOption={(input, option) => (String(option?.label) ?? '').includes(String(input))}
                        filterSort={(optionA, optionB) =>
                            (String(optionA?.label) ?? '').toLowerCase().localeCompare((String(optionB?.label) ?? '').toLowerCase())
                        }
                        options={phoneCodes}
                />
            </Form.Item>
        </>
    );

    return (

        <Card className={"login-card"} style={{width: 400}}>
            {contextHolder}
            <div className={"logo-card"}>
                <img src={LOGO_IMG} alt={"logo"}/>
            </div>
            <div style={{margin: '1rem auto', display: 'flex', justifyContent: 'center', gap: '1rem'}}>
                {
                    lang_store.isLangs.slice().reverse().map((lang) =>
                        <Button onClick={() => switchLang(lang.isoCode)}
                                danger={lang_store.isLang === lang.isoCode}>
                            {lang.isoCode.toUpperCase()}
                        </Button>
                    )
                }
            </div>
            {
                static_store.isLoading
                    ?
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "3rem 0 1rem 0"}}>
                        <Spin size="large"/>
                    </div>
                    :
                    <>
                        <Typography.Title level={3} style={{textAlign: 'center', margin: '0'}}><T>Регистрация</T></Typography.Title>
                        <Typography.Text type="secondary"
                                         style={{textAlign: 'center', margin: '0 auto 12px auto', display: 'block'}}><T>Введите ваши
                            данные и нажмите далее</T></Typography.Text>
                        <Form
                            form={form}
                            multipart={"form-data"}
                            layout="vertical"
                            validateMessages={validateMessages}
                            onFinish={registration}
                        >
                            <Form.Item
                                hasFeedback
                                label={<T>Имя</T>}
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input size={"large"}/>
                            </Form.Item>
                            <Form.Item
                                hasFeedback
                                label={<T>Фамилия</T>}
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input size={"large"}/>
                            </Form.Item>
                            <Form.Item
                                hasFeedback
                                label={<T>Email</T>}
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input size={"large"}/>
                            </Form.Item>
                            <Form.Item
                                hasFeedback
                                label={<T>Телефон</T>}
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input mask={"phone"} addonBefore={prefixSelector} style={{width: '100%'}}
                                       onKeyUp={e => e.target.value = e.target.value.replace(/\D/, '')} size={"large"}/>
                            </Form.Item>
                            <Form.Item shouldUpdate>
                                {
                                    () => (
                                        <Button size={"large"} style={{width: '100%', marginTop: '6px'}} htmlType="submit"
                                                loading={loading} disabled={
                                            !clientReady
                                        } type="primary"><T>Далее</T></Button>
                                    )
                                }
                            </Form.Item>
                            <div className={"flex ai-center jc-between mt-16"}>
                                <Typography.Text type="secondary"><T>Есть аккаунт?</T></Typography.Text>
                                <Typography.Link type="primary"
                                                 onClick={() => navigate(menu.login.path)}><T>Войдите</T></Typography.Link>
                            </div>
                        </Form>
                    </>
            }
        </Card>

    );
};

export default observer(RegistrationComponent);