import React from 'react';
import {Button, Card, Divider, Flex, Input, notification, Space, Spin, Table, Tabs, Tag, Typography} from "antd";
import RoomMethods from "../methods/room.methods";
import {Link, useNavigate} from "react-router-dom";
import {menu} from "../routes";
import {useAuth} from "../hooks/useAuth";
import {useLang} from "../hooks/useLang";
import {UserMethods} from "../methods/user.methods";
import {jwtDecode} from "jwt-decode";
import T from "./common/t";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {PoweroffOutlined} from "@ant-design/icons";
import DeviceMethods from "../methods/device.methods";
import SectorMethods from "../methods/sector.methods";


const columns = [
    {
        title: <T>Название</T>,
        dataIndex: 'name',
        key: 'name',
        width: '15%',
    },
    {
        title: <T>Статус</T>,
        dataIndex: 'isRented',
        width: '15%',
        key: 'isRented',
        render: (_, record) => (
            <Tag color={record && record.isRented === 'Арендовано' ? 'green' : 'volcano'}
                 key={record && record.isRented}>
                <T>{record && record.isRented?.toUpperCase()}</T>
            </Tag>
        )
    },
    {
        title: <T>Аредатор</T>,
        dataIndex: 'arendator',
        width: '20%',
        key: 'arendator',
        render: (_, record) => (
            <Link to={`/users/${record.arendator?.arendatorId}`}
            >
                {record && record.arendator?.name}
            </Link>
        )
    },
    {
        title: <T>Начало аренды</T>,
        dataIndex: 'startRent',
        width: '20%',
        key: 'isRented',
    },
    {
        title: <T>Конец аренды</T>,
        dataIndex: 'stopRent',
        width: '20%',
        key: 'isRented',
    },
    {
        title: '',
        key: <T>action</T>,
        width: '10%',
        render: (_, record) => (
            <Space size="middle">
                <Link to={record && `${menu.rooms.path}/${record.id}`}><T>Перейти</T></Link>
            </Space>
        ),
    },
];


const columnsMy = [
    {
        title: <T>Название</T>,
        dataIndex: 'name',
        key: 'name',
        width: '15%',
    },
    {
        title: <T>Статус</T>,
        dataIndex: 'isRented',
        width: '15%',
        key: 'isRented',
        render: (_, record) => (
            <Tag color={record && record.isRented === 'Арендовано' ? 'green' : 'volcano'}
                 key={record && record.isRented}>
                <T>{record && record.isRented?.toUpperCase()}</T>
            </Tag>
        )
    },
    {
        title: <T>Аредатор</T>,
        dataIndex: 'arendator',
        width: '20%',
        key: 'arendator',
        render: (_, record) => (
            <Link to={record && `/profile`}>{record && record.arendator?.name}</Link>
        )
    },
    {
        title: <T>Начало аренды</T>,
        dataIndex: 'startRent',
        width: '20%',
        key: 'isRented',
    },
    {
        title: <T>Конец аренды</T>,
        dataIndex: 'stopRent',
        width: '20%',
        key: 'isRented',
    },
    {
        title: '',
        key: 'action',
        width: '10%',
        render: (_, record) => (
            <Space size="middle">
                <Link to={record && `${menu.rooms.path}/${record.id}/${localStorage.getItem('lang')}`}><T>Перейти</T></Link>
            </Space>
        ),
    },
];

const RoomsComponent = () => {
    const [loading, setLoading] = React.useState(true);
    const user = useAuth();
    const lang = useLang();
    const {lang_store} = React.useContext(Context);

    const [data, setData] = React.useState([]);
    const [myData, setMyData] = React.useState([]);
    const [admin, setAdmin] = React.useState(false);
    const [emptyFilter, setEmptyFilter] = React.useState(false);
    const [emptyFilterMy, setEmptyFilterMy] = React.useState(false);

    const [searchInput, setSearchInput] = React.useState('');
    const [searchInputMy, setSearchInputMy] = React.useState('');

    const [filteredData, setFilteredData] = React.useState([]);
    const [filteredDataMy, setFilteredDataMy] = React.useState([]);
    const [searched, setSearched] = React.useState(false);
    const [searchedMy, setSearchedMy] = React.useState(false);
    const [devices, setDevices] = React.useState([]);
    const [reload, setReload] = React.useState(false);
    const [reloadButton, setReloadButton] = React.useState(false);
    const [devicesAjax, setDevicesAjax] = React.useState([]);
    const [devicesAjaxLoading, setDevicesAjaxLoading] = React.useState(false);

    const [userName, setUserName] = React.useState('');

    React.useEffect(() =>  {
        (async  () =>  {
            UserMethods.getProfile().then(res  =>  {
                setUserName(() => res.data.first_name + ' ' + res.data.last_name);
            })
        })()
    }, [user]);

    const navigate = useNavigate();

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors[0],
        });
    };

    const search = (e) => {
        setSearchInput(e.target.value);
    }

    const searchMy = (e) => {
        setSearchInputMy(e.target.value);
    }

    React.useEffect(() => {
        setTimeout(() => {
            const filtered = data.filter(d =>
                d.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                d.isRented.toLowerCase().includes(searchInput.toLowerCase()) ||
                d.arendator.name.toLowerCase().includes(searchInput.toLowerCase())
            )
            setFilteredData(() => filtered)
            setSearched(() => true)
            if (filtered.length === 0) {
                setEmptyFilter(() => true)
            } else {
                setEmptyFilter(() => false)
            }
        }, 300);
    }, [searchInput, data]);

    const err = React.useCallback(() => {
        openNotificationWithIcon('error', <T>По вашему запросу ничего не найдено</T>, []);
    }, []);

    React.useMemo(() =>  {
        if (emptyFilter) {
            err();
        }
    }, [emptyFilter, err]);

    React.useEffect(() => {
        setTimeout(() => {
            const filtered = myData.filter(d => d.name.toLowerCase().includes(searchInputMy.toLowerCase()) || d.isRented.toLowerCase().includes(searchInputMy.toLowerCase()) || d.arendator.name.toLowerCase().includes(searchInputMy.toLowerCase()))
            setFilteredDataMy(() => filtered)
            setSearchedMy(() => true)
            if (filtered.length === 0) {
                setEmptyFilterMy(() => true)
            } else {
                setEmptyFilterMy(() => false)
            }
        }, 300);
    }, [searchInputMy, myData]);

    React.useMemo(() =>  {
        if (emptyFilterMy) {
            err();
        }
    }, [emptyFilterMy, err]);

    React.useEffect(() => {
        if (user) {
            if (Number(user.roleValue) !== 999) {
                setAdmin(() => true);
                (async () => {
                    const [rooms, userRooms] = await Promise.allSettled([
                        RoomMethods.getRooms(),
                        UserMethods.getMyRooms(lang_store.isLang)
                    ])
                    if (rooms.status === 'fulfilled' || userRooms.status === 'fulfilled') {

                        setData(() => Object.values(rooms.value.data).sort((a, b) => a.id < b.id ? 1 : -1 ));
                        setMyData(() => Object.values(userRooms.value.data).sort((a, b) => a.id < b.id ? 1 : -1 ));
                        setLoading(() => false);
                    }
                })();
            } else {
                UserMethods.getMyRooms(lang_store.isLang)
                    .then(res => {
                        if (res){
                            setMyData(() => Object.values(res.data));
                            for (const d of Object.values(res.data)) {
                                if (d.tuya) {
                                    setDevices((prevState) => [...prevState, ...d.tuya]);
                                }
                            }
                            // for (const sector of tmpSect) {
                            //     for (const device of Object.values(devices.value.data)) {
                            //         if (device.sectors[sector]) {
                            //             const tru = tmpDevices.find((item) => item.id === device.id)
                            //             if (!tru) {
                            //                 tmpDevices.push({sector: sector, ...device})
                            //             }
                            //         }
                            //     }
                            // }

                            Object.values(res.data).forEach(item => {

                                if (item.sectors) {
                                    for (const sector of item.sectors) {
                                        SectorMethods.getSectorById(sector.id).then((res) => {
                                            if (res && res.data.ajax_devices && res.data.ajax_devices.length > 0) {
                                                for (const d of res.data.ajax_devices) {
                                                    DeviceMethods.getDeviceById('ajax', d.id).then((res) => {
                                                        setDevicesAjax((prevState) => [...prevState, res.data]);
                                                    })
                                                }
                                            }
                                        })
                                    }
                                }

                            });
                        }

                        // setDevices(() => res.data);
                    }).finally(() => {
                    setLoading(() => false);
                })
            }
        }
    }, [user]);

    const tabsAdmin = [
        {id: 1, title: <T>Помещения</T>, component:
            <Flex justify="space-between">
                <Flex vertical gap={20} style={{width: '100%'}}>
                    <Table
                        style={{maxWidth: '100%', borderRight: '1px solid #f0f0f0'}}
                        pagination={false}
                        columns={columns}
                        dataSource={filteredData.length > 0 ? filteredData : data}
                        scroll={{x:'max-content', y: 'calc(100vh - 13rem)'}}
                    />
                </Flex>
                <Flex vertical style={{width: '20%'}}>
                    <Flex vertical style={{width: '100%', position: 'sticky', top: 0}}>
                        <Card style={{boxShadow: 'none'}} bordered={false}>
                            <Input placeholder="Search..." value={searchInput} onChange={search}/>
                        </Card>
                    </Flex>
                </Flex>
            </Flex>
        },
        {id: 2, title: <T>Мои помещения</T>, component:
            <Flex justify="space-between">
                <Flex vertical gap={20} style={{width: '100%'}}>
                    <Table
                        style={{maxWidth: '100%', borderRight: '1px solid #f0f0f0'}}
                        pagination={false}
                        columns={columnsMy}
                        dataSource={filteredDataMy.length > 0 ? filteredDataMy : myData}
                        scroll={{x:'max-content', y: 'calc(100vh - 13rem)'}}
                    />
                </Flex>
                <Flex vertical style={{width: '20%'}}>
                    <Flex vertical style={{width: '100%', position: 'sticky', top: 0}}>
                        <Card style={{boxShadow: 'none'}} bordered={false}>
                            <Input placeholder="Search..." value={searchInputMy} onChange={searchMy}/>
                        </Card>
                    </Flex>
                </Flex>
            </Flex>
        }
    ]

    const [status, setStatus] = React.useState({});

    React.useEffect(() => {
        const st = {};
        setReloadButton(() => true);
        devices.length > 0 && Object.values(devices).forEach(device => {
            DeviceMethods.getTuyaDeviceStatus({device_id: device.deviceId}).then((response) => {
                if (!response.success) {
                    openNotificationWithIcon('error', response.msg, []);
                }
                else if (response.result.status[13]) {
                    st[response.result.name] = response.result.status[13].value;
                    setStatus((prevState) => ({...prevState, ...st}));
                    setReload(() => false);
                }
            }).finally(() => {
                setReload(false)
                setReloadButton(() => false);
            });
        })
        setStatus(() => st);
    }, [reload, devices]);

    const setCommandCloseDoorTuya = (deviceId, deviceName) => {
        // закрывет дверь
        // setLoading(() => true);
        setReloadButton(() => true);
        DeviceMethods.getPswTicketTuya({device_id: deviceId}).then((response) => {

            if (response.success) {
                DeviceMethods.getTuyaDeviceState({device_id: deviceId}).then((response1) => {
                    if (response1.success) {
                        DeviceMethods.setCommandOpenDoor(
                            {
                                device_id: deviceId,
                                commands: {
                                    ticket_id: response.result.ticket_id,
                                    open: false
                                },
                                userName,
                                deviceName,
                                command: 'close'
                            }).then((response) => {
                            if (!response1.success) {
                                openNotificationWithIcon('error', response.msg, []);
                            }
                            openNotificationWithIcon('success', <T>Команда успешно отправлена</T>, []);
                            // setReload(() => true);
                            setTimeout(() => {
                                setReload(() => true);
                            }, 6000)

                        })
                    }
                })
            }
        });
    }

    const setCommandOpenDoorTuya = (deviceId, deviceName) => {
        setReloadButton(() => true);
        // закрывет дверь
        DeviceMethods.getPswTicketTuya({device_id: deviceId}).then((response) => {
            if (response.success) {
                DeviceMethods.getTuyaDeviceState({device_id: deviceId}).then((response1) => {
                    if (response1.success) {
                        DeviceMethods.setCommandOpenDoor(
                            {
                                device_id: deviceId,
                                commands: {
                                    ticket_id: response.result.ticket_id,
                                    open: true
                                },
                                userName,
                                deviceName,
                                command: 'open'
                            }).then((response) => {

                            if (!response1.success) {
                                openNotificationWithIcon('error', response.msg, []);
                            }
                            openNotificationWithIcon('success', <T>Команда успешно отправлена</T>, []);
                            // setReload(() => true);
                            setTimeout(() => {
                                setReload(() => true);
                            }, 6000)
                        })
                    }
                })
            }
        });
    }

    function sendCommand(device){
        setDevicesAjaxLoading(() => true);
        setTimeout(() => {
            setDevicesAjaxLoading(() => false);
        }, 6000)
        const formData = new FormData();
        formData.append("userId", device.ajaxUserId);
        formData.append("hubId", device.hubId);
        formData.append("deviceId", device.deviceId);
        formData.append("deviceType", device.deviceType);
        formData.append("userName", userName);
        formData.append("deviceName", device.deviceName);
        formData.append("command", "switchStateGate");
        DeviceMethods.setCommand('ajax', formData).then(() => {
            openNotificationWithIcon('success', <T>'Команда успешно отправлена'</T>, []);
        }).catch((error) => {
            openNotificationWithIcon('error', error.response.data.message, error.response.data.errors ? error.response.data.errors : []);
        })
    }

    const tabsUser = [
        {id: 1, title: <T>Мои помещения</T>, component:
            <div style={{padding: '16px'}} className={"card-body-custom"}>
                <Space size={"middle"} wrap={true}  direction={"vertical"} style={{width: '100%'}}>
                    <Typography.Title level={5}><T>Ворота</T></Typography.Title>
                    {
                        devicesAjax.length > 0 &&
                        <Space size={"middle"} wrap={true} style={{width: '100%'}}>
                            {
                                devicesAjax.filter(a => Number(a.groups[0]?.value) === 1).filter((value, index, array) =>
                                    array.findIndex(item => item.name === value.name) === index
                                ).map(d =>

                                    <Button key={d.deviceId} style={{width: '100%'}} disabled={devicesAjaxLoading} loading={devicesAjaxLoading} onClick={() => sendCommand(d)} size={"large"} type={"primary"}>{d.name}</Button>
                                )
                            }
                        </Space>

                    }
                    <Typography.Title level={5}><T>Двери</T></Typography.Title>
                    {
                        devicesAjax.length > 0 &&
                        devicesAjax.filter(a => Number(a.groups[0]?.value) === 2).filter((value, index, array) =>
                            array.findIndex(item => item.name === value.name) === index
                        ).map(d =>
                            <div key={d.deviceId} className={"card-body-custom"}>
                                <Divider style={{marginTop: 0}} orientation={"left"}>
                                    {d.name}
                                </Divider>
                                <Button size={"large"} disabled={devicesAjaxLoading}
                                        icon={<PoweroffOutlined/>} style={{width: '100%', marginTop: '6px'}}
                                        type={"primary"} loading={devicesAjaxLoading}
                                        onClick={() => () => sendCommand(d)}>Открыть / Закрыть</Button>
                                {/*<Button size={"large"} disabled={status[d.name] || reloadButton}*/}
                                {/*        icon={<PoweroffOutlined/>} style={{width: '100%', marginTop: '6px'}}*/}
                                {/*        type={"primary"} loading={reloadButton}*/}
                                {/*        onClick={() => setCommandOpenDoorTuya(d.deviceId, d.name)}>Открыть</Button>*/}
                                {/*<Button disabled={!status[d.name] || reloadButton} icon={<PoweroffOutlined/>}*/}
                                {/*        size={"large"} style={{width: '100%', marginTop: '6px'}}*/}
                                {/*        type={"primary"} loading={reloadButton}*/}
                                {/*        onClick={() => setCommandCloseDoorTuya(d.deviceId, d.name)}>Закрыть</Button>*/}

                            </div>
                        )
                    }


                    <Typography.Title level={5}><T>Помещения</T></Typography.Title>
                    {
                        myData.map(d =>
                            <Card key={d.deviceId} size={"small"} style={{width: '100%', backgroundColor: '#fbfcff'}}>
                                <Divider style={{marginTop: 0}} orientation={"left"}>{d.name}</Divider>
                                <Button style={{width: '100%', marginTop: '1rem'}} type="text" size={"large"}
                                        onClick={() => navigate(`${menu.rooms.path}/${d.id}/${localStorage.getItem('lang')}`)}><T>Перейти</T></Button>
                            </Card>
                        )
                    }
                    {/*<Table columns={columnsMy} dataSource={filteredDataMy.length > 0 ? filteredDataMy : myData}/>*/}
                </Space>
            </div>

        }
    ]

    console.log(devices)

    return (
        loading
            ?
            <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                <Spin size="large"/>
            </Flex>
            :
                <>
                    {(searched || searchedMy) && contextHolder}
                    {
                        Number(jwtDecode(localStorage.getItem('token')).roleValue) !== 999
                            ?
                            <Flex align="start" style={{padding: '16px'}}>
                                <Tabs
                                    style={{width: '100%'}}
                                    defaultActiveKey="1"
                                    className="my-tabs"
                                    type="card"
                                    size={'large'}
                                    items={
                                    admin
                                        ? tabsAdmin.map(tab => {
                                            return {
                                                label: tab.title,
                                                key: tab.id,
                                                children: tab.component,
                                            }
                                        })
                                        : tabsUser.map(tab => {
                                            return {
                                                label: tab.title,
                                                key: tab.id,
                                                children: tab.component,
                                            }
                                        })}
                                />
                            </Flex>
                            :
                                tabsUser[0].component

                    }

                </>
    );

};

export default observer(RoomsComponent);