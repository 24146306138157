import React from 'react';
import MainComponent from "../components/common/main.component";
import ProfileComponent from "../components/profile.component";
import T from "../components/common/t";
import {observer} from "mobx-react-lite";
import AllComponent from "../components/common/all.component";

const ProfilePage = () => {
    return (
        <AllComponent>
            <MainComponent title={<T>Profile</T>} showButton={false}>
                <ProfileComponent />
            </MainComponent>
        </AllComponent>
    );
};

export default observer(ProfilePage);