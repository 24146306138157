import React from 'react';
import {Button, Divider, Flex, Form, Input, Modal, notification, Spin, Tabs} from "antd";
import T from "../../common/t";
import ReactQuill from "react-quill";
import {toolbarOptions} from "../oneWeb.component";
import LanguageMethods from "../../../methods/language.methods";
import {useLocation, useNavigate} from "react-router-dom";
import WebPageMethods from "../../../methods/webPage.methods";

const UpdateWebPageFormComponent = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [pubDate, setPubDate] = React.useState('');
    const [pub, setPub] = React.useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [languages, setLanguages] = React.useState({});
    const [updateDate, setUpdateDate] = React.useState('');
    const [oneNews, setOneNews] = React.useState({});
    const [loadingForm, setLoadingForm] = React.useState(false);

    const [form] = Form.useForm();

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} ' + 'is required',
    };

    const getDataOneNews = React.useCallback(() => {
        (async () => {
            const [
                oneNews,
                languages
            ] = await Promise.allSettled([
                WebPageMethods.getOneWebPage(location.pathname.split('/').at(-1)),
                LanguageMethods.getLanguages(),
            ]);
            if (
                oneNews.status === 'fulfilled' &&
                languages.status === 'fulfilled'
            ) {
                setOneNews(() => oneNews.value.data)
                setLanguages(() => languages.value.data)
            }
        })()
    }, [location.pathname])



    React.useEffect(() => {
        getDataOneNews()
    }, []);

    React.useEffect(() => {
        if (Object.keys(languages).length > 0 && Object.keys(oneNews).length > 0) {

            Object.values(languages).map(lang => {
                form.setFieldValue(`name_${lang.isoCode}`, oneNews[lang.id].name)
                form.setFieldValue(`description_${lang.isoCode}`, oneNews[lang.id].description)
                form.setFieldValue(`text_${lang.isoCode}`, oneNews[lang.id].text)
                form.setFieldValue(`seoTitle_${lang.isoCode}`, oneNews[lang.id].seoTitle)
                form.setFieldValue(`seoDescription_${lang.isoCode}`, oneNews[lang.id].seoDescription)
                form.setFieldValue(`seoKeywords_${lang.isoCode}`, oneNews[lang.id].seoKeywords)
                if (lang.isDefault) {
                    form.setFieldValue(`slug`, oneNews[lang.id].slug)
                    setPub(() => oneNews[lang.id].isActive)
                }
                setPubDate(() => oneNews[lang.id].createdAt)
                setUpdateDate(() => oneNews[lang.id].updatedAt)
            })
            setLoading(false)
        }
    }, [form, languages, oneNews]);

    const handleOk = () => {
        setLoadingForm(true);
        const objTitle = {};
        const objDescription = {};
        const objContent = {};
        const objSeoTitle = {};
        const objSeoDescription = {};
        const objSeoKeywords = {};
        for (const language of Object.values(languages)) {
            objTitle[language.isoCode] = form.getFieldValue(`name_${language.isoCode}`)
            objDescription[language.isoCode] = form.getFieldValue(`description_${language.isoCode}`)
            objContent[language.isoCode] = form.getFieldValue(`text_${language.isoCode}`)
            objSeoTitle[language.isoCode] = form.getFieldValue(`seoTitle_${language.isoCode}`)
            objSeoDescription[language.isoCode] = form.getFieldValue(`seoDescription_${language.isoCode}`)
            objSeoKeywords[language.isoCode] = form.getFieldValue(`seoKeywords_${language.isoCode}`)
        }

        WebPageMethods.updateWebPage(location.pathname.split('/').at(-1), {
            slug: form.getFieldValue('slug'),
            isActive: pub,
            name: objTitle,
            text: objContent,
            description: objDescription,
            seoTitle: objSeoTitle,
            seoDescription: objSeoDescription,
            seoKeywords: objSeoKeywords,

        })
            .then(res => {
                form.resetFields();
                openNotificationWithIcon('success', <T>Web страница успешно обновлена</T>, []);
                setTimeout(() => {
                    setIsModalOpen(false);
                    setLoadingForm(false);
                    window.location.reload();
                }, 1000);
            })
            .catch(error => {
                alert(error)
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    const deleteNews = () => {
        WebPageMethods.deleteWebPage(location.pathname.split('/').at(-1))
            .then(() => {
                openNotificationWithIcon('success', <T>Web page удалена</T>, null);
                setTimeout(() => {
                    navigate(-1)
                }, 1000);
            })
            .catch((err) => {
                openNotificationWithIcon('error', <T>Ошибка при удалении</T>, err.response?.data?.errors);
            })
    }


    const showModal = () => {
        setIsModalOpen(true);
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const handlePub = () => {
        setPub(() => !pub)
    }


    return (
        <>
            {contextHolder}
            {
                loadingForm
                    ?
                    <Flex justify="center" align="center" style={{height: '176px'}}>
                        <Spin size="large" />
                    </Flex>
                    :
                    <>
                        <Form
                            name="update_room"
                            style={{ width: '100%', padding: '1rem'}}
                            form={form}
                            multipart={"form-data"}
                            layout="vertical"
                            validateMessages={validateMessages}
                            onFinish={handleOk}
                        >
                            {
                                loading
                                    ?
                                    <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                                        <Spin size="large" />
                                    </Flex>
                                    :
                                    <Flex justify="space-between" gap={20}>
                                        <Flex vertical gap={20} style={{width: '100%'}}>
                                            <Form.Item
                                                hasFeedback
                                                style={{width: '100%', marginBottom: '1rem',}}
                                                label={<T>Ссылка</T>}
                                                name={`slug`}
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input size={"large"} />
                                            </Form.Item>
                                            <Tabs
                                                defaultActiveKey="1"
                                                size={'large'}
                                                type={"card"}
                                                items={
                                                    Object.values(languages).map(item => {
                                                        return {
                                                            label: item.isoCode.toUpperCase(),
                                                            key: item.id,
                                                            children:
                                                                <>
                                                                    <Form.Item
                                                                        hasFeedback
                                                                        style={{width: '100%', marginBottom: 0}}
                                                                        label={<T>Заголовок</T>}
                                                                        name={`name_${item.isoCode}`}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input size={"large"} />
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        hasFeedback
                                                                        style={{width: '100%', marginBottom: 0, marginTop: '1rem'}}
                                                                        label={<T>Описание</T>}
                                                                        name={`description_${item.isoCode}`}
                                                                    >
                                                                        <Input size={"large"} />
                                                                    </Form.Item>

                                                                    <Divider orientation="left"><T>SEO</T></Divider>

                                                                    <Form.Item
                                                                        hasFeedback
                                                                        style={{width: '100%', marginBottom: 0, marginTop: '1rem'}}
                                                                        label={<T>SEO заголовок</T>}
                                                                        name={`seoTitle_${item.isoCode}`}
                                                                    >
                                                                        <Input size={"large"} />
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        hasFeedback
                                                                        style={{width: '100%', marginBottom: 0, marginTop: '1rem'}}
                                                                        label={<T>SEO oписание</T>}
                                                                        name={`seoDescription_${item.isoCode}`}
                                                                    >
                                                                        <Input size={"large"} />
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        hasFeedback
                                                                        style={{width: '100%', marginBottom: 0, marginTop: '1rem'}}
                                                                        label={<T>SEO ключевые слова</T>}
                                                                        name={`seoKeywords_${item.isoCode}`}
                                                                    >
                                                                        <Input size={"large"} />
                                                                    </Form.Item>



                                                                    <Divider orientation="left"><T>Конец SEO</T></Divider>

                                                                    <Form.Item
                                                                        hasFeedback
                                                                        style={{width: '100%', marginBottom: 0, marginTop: '1rem'}}
                                                                        label={<T>Текст</T>}
                                                                        name={`text_${item.isoCode}`}
                                                                    >
                                                                        <ReactQuill
                                                                            style={{width: '100%', maxWidth: '100%', marginBottom: 0, marginTop: '1rem'}}
                                                                            theme="snow"
                                                                            modules={
                                                                                {
                                                                                    toolbar: toolbarOptions
                                                                                }
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </>

                                                        };
                                                    })
                                                }
                                            />
                                        </Flex>
                                        <Flex vertical style={{width: '20%'}}>
                                            <Flex vertical style={{width: '100%', position: 'sticky', top: '1rem'}}>
                                                <Form.Item
                                                    label={<T>Опубликовать</T>}
                                                    name={'isActive'}
                                                >
                                                    <Input size={"large"} type={"checkbox"} checked={pub} onChange={handlePub}/>
                                                </Form.Item>
                                                <Form.Item
                                                    label={<T>Дата публикации</T>}
                                                >
                                                    <Input type={"dateTime"} disabled value={
                                                        pubDate.replaceAll('-', '.')
                                                            .replace('T', ' ')
                                                    }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label={<T>Дата обновления</T>}
                                                >
                                                    <Input type={"dateTime"} disabled value={
                                                        updateDate.replaceAll('-', '.')
                                                            .replace('T', ' ')
                                                    }
                                                    />
                                                </Form.Item>
                                                <Form.Item shouldUpdate>
                                                    {
                                                        () => (
                                                            <Button
                                                                size={"large"}
                                                                style={{width: '100%', marginTop: '6px'}}
                                                                loading={false}
                                                                type="primary"
                                                                htmlType="submit"
                                                            >
                                                                <T>Сохранить</T>
                                                            </Button>
                                                        )
                                                    }
                                                </Form.Item>
                                                <Button
                                                    size={"large"}
                                                    style={{width: '100%', marginTop: '6px'}}
                                                    loading={false}
                                                    onClick={showModal}
                                                    danger={true}
                                                >
                                                    <T>Удалить web страницу</T>
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                            }
                        </Form>

                        <Modal title={<T>Удалить web страницу</T>} open={isModalOpen} onOk={deleteNews} onCancel={handleCancel}>
                            <p>{<T>Вы точно хотите удалить web страницу</T>} ?</p>
                        </Modal>
                    </>
            }
        </>
    );
};

export default UpdateWebPageFormComponent;