import React from 'react';
import MainComponent from "../components/common/main.component";
import T from "../components/common/t";
import AllComponent from "../components/common/all.component";
import {useLocation} from "react-router-dom";
import OneWebComponent from "../components/web/oneWeb.component";

const OneDataPage = () => {
    const location = useLocation();

    const [currentPage, setCurrentPage] = React.useState("");
    const [pageType, setPageType] = React.useState("")

    React.useEffect(() => {
        if (location.pathname.split('/').at(-2) === 'news') {
            setCurrentPage(() => <T>Новости</T>);
            setPageType(() => 'news');
        }
        if (location.pathname.split('/').at(-2) === 'unitTypes') {
            setCurrentPage(() => <T>Тип кладовок</T>);
            setPageType(() => 'unitTypes');
        }
        if (location.pathname.split('/').at(-2) === 'units') {
            setCurrentPage(() => <T>Кладовки</T>);
            setPageType(() => 'units');
        }
        if (location.pathname.split('/').at(-2) === 'sliders') {
            setCurrentPage(() => <T>Слайдер</T>);
            setPageType(() => 'sliders');
        }
        if (location.pathname.split('/').at(-2) === 'advantages') {
            setCurrentPage(() => <T>Преимущества</T>);
            setPageType(() => 'advantages');
        }
        if (location.pathname.split('/').at(-2) === 'pages') {
            setCurrentPage(() => <T>Страницы</T>);
            setPageType(() => 'pages');
        }
        if (location.pathname.split('/').at(-1) === 'config') {
            setCurrentPage(() => <T>Конфиг</T>);
            setPageType(() => 'config');
        }
        if (location.pathname.split('/').at(-2) === 'statics') {
            setCurrentPage(() => <T>Статика</T>);
            setPageType(() => 'statics');
        }
        if (location.pathname.split('/').at(-2) === 'languages') {
            setCurrentPage(() => <T>Языки</T>);
            setPageType(() => 'languages');
        }
        if (location.pathname.split('/').at(-2) === 'roles') {
            setCurrentPage(() => <T>Роли</T>);
            setPageType(() => 'roles');
        }
        if (location.pathname.split('/').at(-2) === 'locations') {
            setCurrentPage(() => <T>Локации</T>);
            setPageType(() => 'locations');
        }
        if (location.pathname.split('/').at(-2) === 'sectors') {
            setCurrentPage(() => <T>Сектора</T>);
            setPageType(() => 'sectors');
        }
        if (location.pathname.split('/').at(-2) === 'banners') {
            setCurrentPage(() => <T>Банер</T>);
            setPageType(() => 'banners');
        }
        if (location.pathname.split('/').at(-2) === 'stock') {
            setCurrentPage(() => <T>Адреса</T>);
            setPageType(() => 'stock');
        }
        if (location.pathname.split('/').at(-2) === 'web_pages') {
            setCurrentPage(() => <T>Web станицы</T>);
            setPageType(() => 'web_pages');
        }
        if (location.pathname.split('/').at(-2) === 'groups') {
            setCurrentPage(() => <T>Группировка Ajax устройств</T>);
            setPageType(() => 'groups');
        }
    }, [location.pathname]);

    return (
        <AllComponent>
            <MainComponent title={currentPage} showButton={false}>
               <OneWebComponent pageType={pageType}/>
            </MainComponent>
        </AllComponent>
    );
};

export default OneDataPage;