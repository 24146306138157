import {$host} from "../http/axios";

export default class SectorMethods {
    static addSector = async (formData) => {
        const {data} = await $host.post(`/sector/add`, formData);
        return data;
    }

    static getSectors = async () => {
        const {data} = await $host.post(`/sector/all?limit=1000`);
        return data;
    }

    static getSectorById = async (formData) => {
        const {data} = await $host.post(`/sector/get/${formData}`);
        return data;
    }

    static updateSectorById = async (id, formData) => {
        const {data} = await $host.post(`/sector/update/${id}`, formData);
        return data;
    }

    static switchSector = async (formData) => {
        const {data} = await $host.post(`/ajax/switchSector`, formData);
        return data;
    }

    static deleteSector = async (id) => {
        const {data} = await $host.post(`/sector/delete/${id}`);
        return data;
    }
}