import React from 'react';
import {
    Button,
    Card,
    Checkbox,
    Empty,
    Flex,
    Form,
    Input,
    Modal,
    notification,
    Radio,
    Space,
    Spin,
    Tabs,
    Tag
} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import T from "../common/t";
import LocationMethods from "../../methods/location.methods";
import SectorMethods from "../../methods/sector.methods";
import DeviceMethods from "../../methods/device.methods";

const UpdateSectorFormComponent = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [pubDate, setPubDate] = React.useState('');
    const [pub, setPub] = React.useState(false);
    const location = useLocation();
    const [updateDate, setUpdateDate] = React.useState('');
    const [onePage, setOnePage] = React.useState({});
    const [loadingForm, setLoadingForm] = React.useState(false);
    const navigate = useNavigate();
    const [locationId, setLocationId] = React.useState(0);
    const [locations, setLocations] = React.useState([]);
    const [devicesChecked, setDevicesChecked] = React.useState([]);
    const [devices, setDevices] = React.useState([]);
    const [tempDevices, setTempDevices] = React.useState([]);
    const [deviceTypes, setDeviceTypes] = React.useState([]);
    const [name, setName] = React.useState("");
    const [activeTab, setActiveTab] = React.useState("Relay");

    const [form] = Form.useForm();

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    const getDataOneNews = React.useCallback(() => {
        (async () => {
            const [
                sector,
                locations,
                devices,
            ] = await Promise.allSettled([
                SectorMethods.getSectorById(location.pathname.split('/').at(-1)),
                LocationMethods.getLocations(),
                DeviceMethods.getDevices('ajax'),
            ]);
            if (
                sector.status === 'fulfilled' && locations.status === 'fulfilled' && devices.status === 'fulfilled'
            ) {
                setOnePage(() => sector.value.data);
                setLocations(() => locations.value.data);
                setDevices(() => devices.value.data);
                setLoadingForm(false)
            }
        })()
    }, [location.pathname])


    React.useEffect(() => {
        getDataOneNews()
    }, []);

    React.useEffect(() => {
        form.setFieldsValue({
            name: name,
            locationId: locationId,
        });
    }, [form, name, locationId]);

    const switchLocation = (e) => {
        setLocationId(() => e.target.value);
    }

    const handleOk = () => {
        setLoadingForm(true);

        SectorMethods.updateSectorById(location.pathname.split('/').at(-1),
        {...form.getFieldValue(), locationId, devicesChecked}
        )
            .then(() => {
                form.resetFields();
                openNotificationWithIcon('success', <T>Сектор успешно обновлен</T>, []);
                setTimeout(() => {
                    setIsModalOpen(false);
                    setLoadingForm(false);
                    window.location.reload();
                }, 1000);
            })
            .catch(error => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    React.useEffect(() => {
        form.setFieldValue(`name`, onePage.name);
        form.setFieldValue(`address`, onePage.address);

        setName(onePage.name);
        setLocationId(onePage.locationId);

        const tempDevices = [];

        Object.values(devices).forEach(item => {
            tempDevices.push({label: item.name, value: item.id, deviceType: item.deviceType, status: item.status});
        });

        setTempDevices(() => tempDevices);

        const temp = [];
        if (onePage.devices) {
            onePage.devices.forEach(item => {temp.push(item.ajaxDeviceId)})
        }
        setDevicesChecked(temp);
        const deviceTypesTemp = [];
        Object.values(devices).forEach(item => {
            if (!deviceTypesTemp.includes(item.deviceType)) {
                deviceTypesTemp.push(item.deviceType);
            }
        })
        setDeviceTypes(() => deviceTypesTemp);
        setPub(() => onePage.isActive);
        setPubDate(() => onePage.createdAt);
        setUpdateDate(() => onePage.updatedAt);
    }, [form, onePage]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handlePub = () => {
        setPub(() => !pub)
    }

    const checkDevices = (checkedValues) => {
        setDevicesChecked(() => checkedValues)
    }

    const deleteSector = () => {
        setLoadingForm(true);
        SectorMethods.deleteSector(location.pathname.split('/').at(-1))
            .then(() => {
                openNotificationWithIcon('success', <T>Вы успешно удалили сектор</T>, []);
                handleCancel()
                setTimeout(() => {
                    navigate(-1);
                }, 3000)
            })
            .catch((error) => {
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors);
            })
            .finally(() => {
                setLoadingForm(false);
            });
    }

    return (
        <>
            {contextHolder}
            {
                loadingForm
                    ?
                    <Flex justify="center" align="center" style={{height: '176px'}}>
                        <Spin size="large" />
                    </Flex>
                    :
                    <>
                        <Form
                            name="update_room"
                            style={{ width: '100%', padding: '1rem'}}
                            form={form}
                            multipart={"form-data"}
                            layout="vertical"
                            onFinish={handleOk}
                        >
                            <Flex justify="space-between" gap={20}>
                                <Flex vertical gap={20} style={{width: '100%'}}>
                                    <Form.Item
                                        hasFeedback
                                        style={{width: '100%', marginBottom: '1rem',}}
                                        label={<T>Название</T>}
                                        name={`name`}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input size={"large"} />
                                    </Form.Item>
                                    <Form.Item
                                        label={<T>Локации</T>}
                                    >
                                        <Radio.Group value={locationId} onChange={switchLocation}>
                                            <Space size={'middle'} wrap={true}>
                                                {
                                                    Object.values(locations).map(loc =>
                                                        <Card key={loc.id}>
                                                            <Radio name={'locationId'} value={loc.id}>{loc.name}</Radio>
                                                        </Card>
                                                    )
                                                }
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item label={<T>Устройства</T>}>
                                        <Tabs
                                            defaultActiveKey={activeTab}
                                            size={'large'}
                                            type={"card"}
                                            onChange={(key) => setActiveTab(key)}
                                            items={
                                                deviceTypes.map(item => {
                                                    if (item  ===  "Relay")  {
                                                        return {
                                                            label: item,
                                                            key: item,
                                                            children:
                                                                Object.values(tempDevices).length > 0
                                                                    ?
                                                                    <Space size={"middle"} wrap={"wrap"}>
                                                                        {
                                                                            <Checkbox.Group
                                                                                onChange={checkDevices}
                                                                                defaultValue={devicesChecked}
                                                                            >
                                                                                <Space size={"middle"} wrap={"wrap"}>
                                                                                    {
                                                                                        tempDevices.filter(i => i.deviceType === item).map(item =>
                                                                                            <Card key={item.value}>
                                                                                                <Checkbox value={item.value}>{item.label}</Checkbox>
                                                                                                <Tag color={item.status === 'online'? 'green' :'red'}>{item.status.toUpperCase()}</Tag>
                                                                                            </Card>
                                                                                        )
                                                                                    }
                                                                                </Space>
                                                                            </Checkbox.Group>
                                                                        }
                                                                    </Space>
                                                                    :
                                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                                                        }
                                                    }
                                                })
                                            }
                                        />
                                    </Form.Item>
                                </Flex>
                                <Flex vertical style={{width: '20%'}}>
                                    <Flex vertical style={{width: '100%', position: 'sticky', top: '1rem'}}>
                                        <Form.Item
                                            label={<T>Опубликовать</T>}
                                            name={'isActive'}
                                        >
                                            <Input size={"large"} type={"checkbox"} checked={pub} onChange={handlePub}/>
                                        </Form.Item>
                                        <Form.Item
                                            label={<T>Дата публикации</T>}
                                        >
                                            <Input type={"dateTime"} disabled value={
                                                pubDate?.replaceAll('-', '.')
                                                    .replace('T', ' ')
                                            }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={<T>Дата обновления</T>}
                                        >
                                            <Input type={"dateTime"} disabled value={
                                                updateDate?.replaceAll('-', '.')
                                                    .replace('T', ' ')
                                            }
                                            />
                                        </Form.Item>
                                        <Form.Item shouldUpdate>
                                            {
                                                () => (
                                                    <Button
                                                        size={"large"}
                                                        style={{width: '100%', marginTop: '6px'}}
                                                        loading={false}
                                                        type="primary"
                                                        htmlType="submit"
                                                    >
                                                        <T>Сохранить</T>
                                                    </Button>
                                                )
                                            }
                                        </Form.Item>
                                        <Button
                                            size={"large"}
                                            style={{width: '100%', marginTop: '6px'}}
                                            loading={false}
                                            onClick={showModal}
                                            danger={true}
                                        >
                                            <T>Удалить сектор</T>
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Form>

                        <Modal title={<T>Удалить сектор</T>} open={isModalOpen} onOk={deleteSector} onCancel={handleCancel}>
                            <p>{<T>Вы точно хотите удалить сектор</T>} ?</p>
                        </Modal>
                    </>
            }
        </>
    );
};

export default UpdateSectorFormComponent;