import {$host} from "../http/axios";

export default class BannersMethods {
    static addBanner = async (formData) => {
        const {data} = await $host.post(`/banner/add`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
            }
        });
        return data;
    }
    static getOneBanner = async (id) => {
        const {data} = await $host.post(`/banner/get/${id}`);
        return data;
    }

    static getAll = async () => {
        const {data} = await $host.post(`/banner/all?limit=1000000000`);
        return data;
    }

    static updateBanner = async (id, formData) => {
        const {data} = await $host.post(`/banner/update/${id}`, formData);
        return data;
    }

    static getByLangBanner = async (id, isoCode) => {
        const {data} = await $host.post(`/banner/get/${id}/lang/${isoCode}`);
        return data;
    }

    static deleteBanner = async (id) => {
        const {data} = await $host.post(`/banner/delete/${id}`);
        return data;
    }
}