import React from 'react';
import {Link} from "react-router-dom";
import {Card, Flex, Input, notification, Space, Spin, Table, Tag, Typography} from "antd";
import {UserMethods} from "../methods/user.methods";
import {jwtDecode} from "jwt-decode";
import T from "./common/t";

const columns = [
    {
        title: <T>Имя</T>,
        dataIndex: 'name',
        key: 'name',
        width: '15%',
    },
    {
        title: <T>email</T>,
        dataIndex: 'email',
        width: '15%',
        key: 'email',
    },
    {
        title: <T>Телефон</T>,
        dataIndex: 'phone',
        width: '15%',
        key: 'phone',
    },
    {
        title: <T>Был</T>,
        dataIndex: 'lastIn',
        width: '20%',
        key: 'lastIn',
    },
    {
        title: <T>Статус</T>,
        dataIndex: 'status',
        width: '10%',
        key: 'status',
        render: (_, record) => (
            <Tag color={record.status === 'Активный' ? 'green' : 'volcano'} key={record.status}>
                <T>{record.status.toUpperCase()}</T>
            </Tag>
        )
    },
    {
        title: <T>Роль</T>,
        dataIndex: 'role',
        width: '10%',
        key: 'role',
        render: (_, record) => (
            // to={`/settings/roles/${record.role.roleId}`}
            <Typography.Text >{record.role.name}</Typography.Text>
        )
    },
    {
        title: '',
        key: 'action',
        width: '10%',
        render: (_, record) => (
            <Space size="middle">
                <Link to={`${record.id}`}><T>Перейти</T></Link>
            </Space>
        ),
    },
];

const UsersComponent = () => {
    const [loading, setLoading] = React.useState(true);

    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        const preData = []
        UserMethods.getUsers()
            .then(res => {
                for (const d of Object.values(res.data)) {
                    if (Number(jwtDecode(localStorage.getItem('token')).id) !== d.id) {
                        preData.push(d)
                    }
                }
                setData(() => preData.sort((a, b) => a.id < b.id ? 1 : -1 ));
            })
            .finally(() => setLoading(false));
    }, []);

    const [searchInput, setSearchInput] = React.useState('');
    const [filteredData, setFilteredData] = React.useState([]);
    const [emptyFilter, setEmptyFilter] = React.useState(false);
    const [searched, setSearched] = React.useState(false);

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors[0],
        });
    };

    const search = (e) => {
        setSearchInput(e.target.value);
    }

    React.useEffect(() => {
        const filtered = data.filter(d =>
            d.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            d.email.toLowerCase().includes(searchInput.toLowerCase()) ||
            d.phone.toLowerCase().includes(searchInput.toLowerCase()) ||
            d.lastIn.toLowerCase().includes(searchInput.toLowerCase()) ||
            !d.status.toLowerCase().indexOf(searchInput.toLowerCase()) ||
            d.role.name.toLowerCase().includes(searchInput.toLowerCase())
        )
        setTimeout(() => {
            setFilteredData(() => filtered.sort((a, b) => a.id < b.id ? 1 : -1 ))
            setSearched(() => true)
            if (filtered.length === 0) {
                setEmptyFilter(() => true)
            } else {
                setEmptyFilter(() => false)
            }
        }, 300);
    }, [searchInput, data]);

    const err = React.useCallback(() => {
        openNotificationWithIcon('error', <T>По вашему запросу ничего не найдено</T>, []);
    }, []);

    React.useMemo(() =>  {
        if (emptyFilter) {
            err();
        }
    }, [emptyFilter, err]);


    return (
        loading
            ?
            <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                <Spin size="large" />
            </Flex>
            :
            <>
                {searched && contextHolder}
                <Flex justify="space-between">
                    <Flex vertical gap={20} style={{width: '100%'}}>
                        <Table
                            style={{maxWidth: '100%', borderRight: '1px solid #f0f0f0'}}
                            columns={columns}
                            pagination={false}
                            scroll={{x:'max-content', y: 'calc(100vh - 13rem)'}}
                            dataSource={
                                filteredData.length > 0
                                    ?
                                    filteredData
                                    :
                                    data
                            }
                        />
                    </Flex>
                    <Flex vertical style={{width: '20%'}}>
                        <Flex vertical style={{width: '100%', position: 'sticky', top: 0}}>
                            <Card style={{boxShadow: 'none'}} bordered={false}>
                                <Input placeholder="Search..." value={searchInput} onChange={search}/>
                            </Card>
                        </Flex>
                    </Flex>
                </Flex>
            </>
    );
};

export default UsersComponent;