import {$host} from "../http/axios";

export default class AdvantageMethods {
    static addAdvantage = async (formData) => {
        const {data} = await $host.post(`/advantage/add`, formData);
        return data;
    }
    static getOneAdvantage = async (id) => {
        const {data} = await $host.post(`/advantage/get/${id}`);
        return data;
    }

    static getAll = async () => {
        const {data} = await $host.post(`/advantage/all?limit=1000000000`);
        return data;
    }

    static getAllByLang = async (isoCode) => {
        const {data} = await $host.post(`/advantage/get/lang/${isoCode}?limit=1000000000`);
        return data;
    }

    static updateAdvantage = async (id, formData) => {
        const {data} = await $host.post(`/advantage/update/${id}`, formData);
        return data;
    }

    static getByLangAdvantage = async (id, isoCode) => {
        const {data} = await $host.post(`/advantage/get/${id}/lang/${isoCode}`);
        return data;
    }

    static deleteAdvantage = async (id) => {
        const {data} = await $host.post(`/advantage/delete/${id}`);
        return data;
    }
}